import { NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  Input,
  computed,
  effect,
  forwardRef,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import {
  LabelLink,
  OnyxInputLabelComponent,
} from '../../labels/onyx-input-label/onyx-input-label.component';
import { OnyxTooltipContext } from '../../tooltip';

@Component({
  selector: 'onyx-textarea',
  imports: [
    OnyxFormControlErrorComponent,
    OnyxInputLabelComponent,
    OnyxFormControlErrorComponent,
    NgClass,
    NgStyle,
    TranslatePipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxTextareaComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-textarea.component.html',
  styleUrls: ['./onyx-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTextareaComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public resize = input(true);
  public minResizeHeight = input<`${string}px`>('120px');
  public maxResizeHeight = input<`${string}px`>('500px');
  public width = input('100%');
  public placeholder = input<string>();
  public link = input<LabelLink>();
  public maxLength = input<number>();
  public showErrors = input(true);
  public forceOptional = input<boolean>();
  public disabled = model(false);

  @Input() public set value(value: string | null) {
    if (value == null) {
      this.clear();
      return;
    }

    this.value_.set(value);
    setTimeout(() => (this.textareaElementRef().nativeElement.value = value));
  }
  public get value(): string | null {
    return this.value_() ?? null;
  }

  public valueChange = output<string | null>();

  protected onChange?: (value: string | null) => void;
  protected onTouched?: () => void;
  protected value_ = signal<string | undefined>(undefined);
  protected maxLengthHint = computed(() => {
    if (this.maxLength() == null) return undefined;
    return `${this.value_()?.trim()?.length ?? 0}/${this.maxLength()}`;
  });

  private textareaElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('textareaElement');

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef,
  ) {
    super(injector, destroyRef);
    effect(() => (this.elementRef.nativeElement.style.width = this.width()));
  }

  public registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: string | null): void {
    this.value = value;
  }

  public focus(): void {
    if (this.disabled()) return;
    this.textareaElementRef().nativeElement.focus();
  }

  protected clear(): void {
    this.value_.set(undefined);
    this.onChange?.(null);
    this.valueChange.emit(null);
    setTimeout(() => (this.textareaElementRef().nativeElement.value = ''));
  }

  protected handleValueChange(event: Event): void {
    let value = (event.target as HTMLInputElement).value;
    if (this.maxLength() != null) {
      value = value.slice(0, this.maxLength());
    }
    this.value_.set(value);

    this.onChange?.(value || null);
    this.valueChange.emit(value || null);

    this.textareaElementRef().nativeElement.value = value;
  }
}

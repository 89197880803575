import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxLanguagePipe,
  OnyxNumberRange,
  OnyxTemperaturePipe,
  OnyxTooltipDirective,
} from '@onyx/angular';
import {
  chain,
  intersection,
  isArray,
  isBoolean,
  isEqual,
  isNumber,
  isString,
} from 'lodash';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { JoinPipe } from '../../../../../common/components/pipes/join.pipe';
import { BASE_PARAMETERS_KEYS } from '../../../../../common/constants/base-parameters-keys';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { BaseParameters } from '../../../../../common/interfaces/common/base-parameters';
import { Cabotages } from '../../../../../common/interfaces/common/cabotages';
import { FleetDescriptionParametersComponent } from '../../../../fleet/common/components/fleet-description-parameters/fleet-desription-parameters.component';
import { FleetCategory } from '../../../../fleet/common/enums/fleet-category';
import { SemiTrailerSize } from '../../../../fleet/common/enums/semi-trailer-size';
import { FleetHelper } from '../../../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-vehicles',
  standalone: true,
  imports: [
    OnyxInformationHeadingComponent,
    TranslatePipe,
    FleetIdentifierPipe,
    OnyxIconComponent,
    OnyxDataWidgetComponent,
    OnyxTemperaturePipe,
    OnyxLanguagePipe,
    OnyxIconButtonComponent,
    FleetDescriptionParametersComponent,
    OnyxTooltipDirective,
    NgClass,
  ],
  providers: [JoinPipe],
  templateUrl: './order-modal-vehicles.component.html',
  styleUrl: './order-modal-vehicles.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalVehiclesComponent {
  protected readonly I18N = 'orders.orderModal';
  protected readonly TOOLTOP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly BASE_PARAMETERS_KEYS = BASE_PARAMETERS_KEYS;

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly SemiTrailerSize = SemiTrailerSize;
  protected readonly FleetCategory = FleetCategory;

  public order = input.required<Order>();
  public vehicle = input<SimplifiedFleet | null>(null);
  public trailer = input<SimplifiedFleet | null>(null);
  public cabotages = input<Cabotages>();
  public showHeading = input(true);

  public invalidChange = output<boolean>();

  protected searchingVehicleData = computed(() =>
    OrderHelper.getSearchingVehicleData(this.order(), this.translateService),
  );
  protected hasApprovedVehicle = computed(() =>
    OrderHelper.hasApprovedVehicle(this.order().status.value),
  );
  protected vehicleParameters = computed(() => {
    return chain(this.searchingVehicleData().parameters ?? [])
      .filter((parameter) =>
        BASE_PARAMETERS_KEYS.includes(parameter.key as keyof BaseParameters),
      )
      .map((parameter) => {
        const key = parameter.key as keyof BaseParameters;
        const value = parameter.value;
        const vehicleParameter = this.vehicle()?.additionalParameters[key];

        const ok = (): boolean => {
          if (vehicleParameter == null) return false;

          if (isString(value) || isBoolean(value)) {
            return isEqual(value, vehicleParameter);
          }

          if (isNumber(value)) return (vehicleParameter as number) >= value;

          if (isArray(value)) {
            return (
              intersection(vehicleParameter as any[], value).length ===
              value.length
            );
          }

          const vehicleTemperatureRange = vehicleParameter as OnyxNumberRange;
          if (
            value.from <= vehicleTemperatureRange.to &&
            vehicleTemperatureRange.from <= value.to
          ) {
            return true;
          }

          return false;
        };

        return {
          key: parameter.key,
          name: parameter.name,
          value: parameter.value,
          ok,
        };
      })
      .thru((parameters) => [
        ...parameters,
        ...(this.searchingVehicleData().isLowDeck
          ? [
              {
                key: 'lowDeck',
                name: this.translateService.instant('labels.lowDeck'),
                value: true,
                ok: () => !!this.vehicle()?.additionalParameters.isLowDeck,
              },
            ]
          : []),
        ...(this.searchingVehicleData()?.types?.map((type) => ({
          key: 'vehicleType',
          name: `${DictionaryCode.VEHICLE_TYPE}.${type}`,
          value: null,
          ok: () => this.trailer()?.generalInformation?.type === type,
        })) ?? []),
      ])
      .value();
  });
  protected isVehicleInvalid = computed(
    () =>
      this.vehicle() &&
      (this.vehicleParameters().some((parameter) => !parameter.ok()) ||
        (this.cabotages()?.number ?? 0) > (this.cabotages()?.limit ?? 0)),
  );
  protected searchingTrailerTypes = computed(() =>
    OrderHelper.getSearchingTrailerTypes(this.order()),
  );
  protected trailerParameters = computed(() => {
    return chain(this.searchingTrailerTypes() ?? [])
      .thru((parameters) => [
        {
          key: 'vehicleType',
          name: this.joinPipe.transform(
            parameters,
            ' / ',
            2,
            DictionaryCode.VEHICLE_TYPE,
          ),
          showTooltip: parameters.length > 2,
          ok: parameters.includes(
            this.trailer()?.generalInformation?.type ?? '',
          ),
        },
        ...(this.searchingVehicleData().isLowDeck
          ? [
              {
                key: 'semiTrailerSize',
                name: `${DictionaryCode.SEMI_TRAILER_SIZE}.${SemiTrailerSize.MEGA}`,
                ok:
                  this.trailer()?.additionalParameters.semiTrailerSize ===
                  SemiTrailerSize.MEGA,
                showTooltip: false,
              },
            ]
          : []),
      ])
      .value();
  });
  protected isTrailerInvalid = computed(
    () =>
      this.trailer() &&
      this.trailerParameters().some((parameter) => !parameter.ok),
  );

  constructor(
    private translateService: TranslateService,
    private joinPipe: JoinPipe,
    private fleetHelper: FleetHelper,
  ) {
    effect(() => {
      if (!this.vehicle()) return;

      this.invalidChange.emit(
        !!this.isVehicleInvalid() || !!this.isTrailerInvalid(),
      );
    });
  }

  protected openFleetModal(fleet: SimplifiedFleet): void {
    this.fleetHelper.openModal(fleet);
  }
}

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ImportProgress } from '../../../../common/interfaces/import-progress';
import { PointsOfInterestImportData } from '../constants/points-of-interest-import-schema';
import { PointsOfInterestService } from '../services/points-of-interest.service';

export const importPointsOfInterestResolver: ResolveFn<
  (data: PointsOfInterestImportData[]) => Observable<ImportProgress>
> = () => {
  const pointsOfInterestService = inject(PointsOfInterestService);
  return (data) => pointsOfInterestService.importPointsOfInterest(data);
};

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxErrorComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxPluralTranslatePipe,
  OnyxSpinnerComponent,
  OnyxToastService,
} from '@onyx/angular';
import { catchError, EMPTY, Subject, switchMap, tap, timer } from 'rxjs';
import { AddressComponent } from '../../../../../common/components/address/address.component';
import { JoinPipe } from '../../../../../common/components/pipes/join.pipe';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { ContractorContactsComponent } from '../../../../management-panel/contractors/common/components/contractor-contacts/contractor-contacts.component';
import { ContractorHelper } from '../../../../management-panel/contractors/common/helpers/contractor.helper';
import { Contractor } from '../../../../management-panel/contractors/common/interfaces/contractor';
import { ContractorsService } from '../../../../management-panel/contractors/common/services/contractors.service';
import { Order } from '../../../common/interfaces/order';
import { OrdersService } from '../../../common/services/orders.service';

@Component({
  selector: 'app-order-modal-client',
  standalone: true,
  imports: [
    OnyxInformationHeadingComponent,
    OnyxPluralTranslatePipe,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxSpinnerComponent,
    OnyxErrorComponent,
    OnyxIconButtonComponent,
    JoinPipe,
    OnyxIconComponent,
    AddressComponent,
    ContractorContactsComponent,
  ],
  templateUrl: './order-modal-client.component.html',
  styleUrl: './order-modal-client.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalClientComponent implements OnInit {
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly DictionaryCode = DictionaryCode;

  public order = input.required<Order>();

  protected sender = signal<Contractor | null>(null);
  protected loading = signal(false);
  protected showLoading = signal(false);
  protected error = signal(false);
  protected sender$ = new Subject<void>();

  protected contractorTypes = computed(
    () => this.sender()?.companyProfile.types.map((type) => type.value) ?? [],
  );

  constructor(
    private ordersService: OrdersService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private contractorsService: ContractorsService,
    private injector: Injector,
    private contractorHelper: ContractorHelper,
  ) {}

  public ngOnInit(): void {
    this.sender$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() => {
          return this.contractorsService
            .getContractor(this.order().basicInformation.sender.uuid)
            .pipe(
              catchError((response) => {
                this.loading.set(false);
                this.error.set(true);

                ValidationHelper.handleUnexpectedError(
                  response,
                  this.toastService,
                );
                return EMPTY;
              }),
            );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (sender) => {
          this.sender.set(sender);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    this.ordersService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.sender$.next());

    effect(
      () => {
        this.showLoading.set(false);
        if (this.loading()) {
          timer(1000)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.showLoading.set(true));
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        this.order();
        untracked(() => this.sender$.next());
      },
      { injector: this.injector },
    );
  }

  protected openContractorModal(): void {
    const contractor = this.sender();
    if (!contractor) return;

    this.contractorHelper.openModal(contractor);
  }
}

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { FleetRoute } from '../../fleet.routes';
import { Fleet } from '../interfaces/fleet';
import { FleetService } from '../services/fleet.service';

export const fleetResolver: ResolveFn<Fleet> = (
  route: ActivatedRouteSnapshot,
) => {
  const fleetService = inject(FleetService);
  const router = inject(Router);
  const toastService = inject(OnyxToastService);

  const uuid = route.paramMap.get('uuid')!;

  return fleetService.getFleet(uuid).pipe(
    catchError((response: HttpErrorResponse) => {
      if (response.status === HttpStatusCode.BadRequest) {
        toastService.showError('fleet.toasts.notFound');
      } else {
        ValidationHelper.handleUnexpectedError(response, toastService);
      }

      const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};

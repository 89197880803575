import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ImportProgress } from '../../../common/interfaces/import-progress';
import { FleetImportData } from '../constants/fleet-import-schema';
import { FleetService } from '../services/fleet.service';

export const importFleetResolver: ResolveFn<
  (data: FleetImportData[]) => Observable<ImportProgress>
> = () => {
  const fleetService = inject(FleetService);
  return (data) => fleetService.importFleet(data);
};

import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DictionaryCode } from '../../../enums/dictionary-code';

@Component({
  selector: 'app-fuel-tank-cell',
  imports: [TranslatePipe, DecimalPipe],
  templateUrl: './fuel-tank-cell.component.html',
  styleUrl: './fuel-tank-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FuelTankCellComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public fuelType = input.required<string | null>();
  public tankCapacity = input.required<number | null>();
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxAvatarComponent } from '@onyx/angular';
import { SimplifiedContractor } from '../../../../contractors/common/interfaces/contractor';

@Component({
  selector: 'app-points-of-interest-contractor-cell',
  imports: [OnyxAvatarComponent],
  templateUrl: './points-of-interest-contractor-cell.component.html',
  styleUrl: './points-of-interest-contractor-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsOfInterestContractorCellComponent {
  public contractor = input.required<SimplifiedContractor | null>();
}

<div class="wrapper">
  @if (label(); as label) {
    <onyx-input-label
      [disabled]="disabled()"
      [optional]="isOptional() || !!forceOptional()"
      [label]="label"
      [hint]="hint()"
      [link]="link()"
      (click)="focus()"
    >
      @if (!disabled() && maxLengthHint()) {
        <p content>{{ maxLengthHint() }}</p>
      }
    </onyx-input-label>
  }

  <div class="container">
    <!-- <onyx-icon class="resize-icon" name="arrow-down" [size]="12"></onyx-icon> -->

    <textarea
      class="textarea f-semibold-1"
      [ngClass]="{
        resize: resize(),
        disabled: disabled(),
        invalid: isInvalid(),
        active: value,
      }"
      [ngStyle]="{
        'min-height': minResizeHeight(),
        'max-height': maxResizeHeight(),
      }"
      [disabled]="disabled()"
      [attr.placeholder]="placeholder() ?? '' | translate"
      (input)="handleValueChange($event)"
      (focusout)="onTouched?.()"
      #textareaElement
    ></textarea>
  </div>
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [controls]="[formControl()!]"
  ></onyx-form-control-error>
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { OnyxWidgetBoxComponent } from '@onyx/angular';
import { AddressComponent } from '../../../../../common/components/address/address.component';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderPoint } from '../../../common/interfaces/order-point';

@Component({
  selector: 'app-order-modal-point-status',
  standalone: true,
  imports: [OnyxWidgetBoxComponent, AddressComponent],
  templateUrl: './order-modal-point-status.component.html',
  styleUrl: './order-modal-point-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPointStatusComponent {
  public point = input.required<OrderPoint>();

  protected pointAddress = computed(() => {
    const point = this.point();
    if (point.category === OrderPointCategory.TRANSIT) {
      return point.startAddress;
    }

    return point.address;
  });
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  OnyxColor,
  OnyxDatePipe,
  OnyxTime,
  OnyxTimePipe,
  OnyxWidgetBoxComponent,
} from '@onyx/angular';
import { DateTime } from 'luxon';
import { OrderStatus } from '../../../common/enums/order-status';

interface PointDeadline {
  dateTime: DateTime<boolean>;
  date: string;
  time: OnyxTime;
}

interface PointEstimationDifference {
  diff: string;
  color: OnyxColor;
}

@Component({
  selector: 'app-order-modal-eta',
  standalone: true,
  imports: [OnyxDatePipe, OnyxTimePipe, OnyxWidgetBoxComponent],
  providers: [],
  templateUrl: './order-modal-eta.component.html',
  styleUrl: './order-modal-eta.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalEtaComponent {
  protected readonly OrderStatus = OrderStatus;

  public pointDeadline = input.required<PointDeadline>();
  public pointEstimationDifference =
    input.required<PointEstimationDifference>();
  public status = input.required<OrderStatus>();
}

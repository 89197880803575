<section>
  <div class="hero-badges" [ngClass]="{ extended: isVehicle() }">
    @let generalInformation = vehicle().generalInformation;

    @let isActive = generalInformation.state === FleetState.ACTIVE;
    <onyx-widget-box [heading]="I18N + '.vehicleState'" [color]="statusColor()">
      <onyx-badge [color]="isActive ? 'green' : 'black'">
        {{
          DictionaryCode.VEHICLE_STATE + '.' + generalInformation.state
            | translate
        }}
      </onyx-badge>
    </onyx-widget-box>

    @let planningMode = generalInformation.planningMode;
    @let isPlanningAuto = planningMode === VehiclePlanningMode.AUTO;
    <onyx-widget-box heading="labels.planning" [color]="planningColor()">
      <div class="content">
        <onyx-icon
          class="{{ planningMode }}"
          [name]="isPlanningAuto ? 'refresh' : 'manual'"
          [size]="16"
        ></onyx-icon>

        <p class="f-paragraph-semibold-2">
          {{
            DictionaryCode.VEHICLE_PLANNING_MODE + '.' + planningMode
              | translate
          }}
        </p>
      </div>
    </onyx-widget-box>

    @if (isVehicle()) {
      <div class="information-item f-paragraph-medium-3">
        <onyx-icon class="ride" name="vehicle-ride" [size]="14"></onyx-icon>

        N/A
      </div>

      <div class="information-item f-paragraph-medium-3">
        @let isDoubleCrew =
          vehicle().drivers!.primaryDriver &&
          vehicle().drivers!.secondaryDriver;
        <onyx-icon
          class="user"
          [name]="isDoubleCrew ? 'two-users' : 'user'"
          [size]="14"
        ></onyx-icon>

        <div class="drivers">
          <app-string-cell [value]="drivers()"></app-string-cell>
        </div>
      </div>
    }
  </div>

  <div class="icon-boxes f-paragraph-medium-3">
    <onyx-icon-box [color]="OnyxIconBoxColor.VIOLET" label="labels.mileage">
      <onyx-icon name="road" [size]="16"></onyx-icon>

      N/A
    </onyx-icon-box>

    <onyx-icon-box [color]="OnyxIconBoxColor.BLACK" label="labels.telematics">
      <onyx-icon name="menu-integrations" [size]="16"></onyx-icon>

      N/A
    </onyx-icon-box>

    <onyx-icon-box
      [color]="OnyxIconBoxColor.GRAY"
      label="labels.manufactureYear"
    >
      <onyx-icon name="calendar" [size]="16"></onyx-icon>

      <span>{{ generalInformation.manufactureYear }}</span>
    </onyx-icon-box>

    @if (isVehicle()) {
      <onyx-icon-box [color]="OnyxIconBoxColor.ORANGE" label="labels.fuel">
        <onyx-icon name="car-wash" [size]="16"></onyx-icon>

        {{
          DictionaryCode.FUEL_TYPE + '.' + vehicle().fuelTankCapacity?.fuelType
            | translate
        }}
      </onyx-icon-box>
    } @else {
      <app-fleet-modal-parameters
        [vehicle]="vehicle()"
      ></app-fleet-modal-parameters>
    }
  </div>
</section>

<div
  class="message {{ size() }} {{ type() }}"
  [ngClass]="{ 'border-radius': borderRadius() }"
>
  <div class="container">
    <ng-content select="onyx-icon"></ng-content>
    <ng-content select="onyx-custom-icon"></ng-content>

    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="buttons">
    @if (action1Text(); as text) {
      <onyx-message-action-button (click)="action1Click.emit()">
        {{ text | translate }}
      </onyx-message-action-button>
    }

    @if (action2Text(); as text) {
      <onyx-message-action-button [bold]="true" (click)="action2Click.emit()">
        {{ text | translate }}
      </onyx-message-action-button>
    }

    @if (showClose()) {
      <onyx-message-close-button
        [color]="type() === 'warning' ? 'yellow' : 'red'"
        (click)="closeMessage.emit()"
      ></onyx-message-close-button>
    }
  </div>
</div>

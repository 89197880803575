@if (label(); as label) {
  <onyx-input-label
    [disabled]="disabled()"
    [optional]="isOptional() && !forceOptional()"
    [label]="label"
    [hint]="hint()"
    (click)="focus()"
  ></onyx-input-label>
}

<div
  class="tabs {{ size() }}"
  [ngClass]="{
    fill: fill(),
    invalid: isInvalid(),
    disabled: disabled(),
  }"
  #containerElement
>
  @for (tab of tabs(); track tab.value) {
    @let icon = $any(tab).icon;
    <button
      class="tab f-regular-1"
      [ngClass]="{
        active: $index === activeTabIndex(),
        disabled: tab.disabled ?? disabled(),
        icon,
      }"
      type="button"
      [tabindex]="tab.disabled ? -1 : 0"
      [onyxTooltip]="tab.tooltip"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
      (click)="changeTab($index)"
      (keydown.space)="changeTab($index)"
      #tabElement
    >
      <div class="content">
        @if (tab.icon; as icon) {
          <onyx-icon
            class="{{ tab.iconColor }}"
            [name]="icon.name"
            [size]="icon.size"
            [frame]="icon.frame"
          ></onyx-icon>
        }

        @let name = tab.name ?? '' | translate;
        @if (name) {
          <p [attr.content]="name">{{ name }}</p>
        }
      </div>
    </button>
  }

  <div class="indicator" #indicatorElement></div>
</div>

@if (formControl() && showErrors()) {
  <onyx-form-control-error
    [controls]="[formControl()!]"
  ></onyx-form-control-error>
}

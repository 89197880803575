import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxAddress } from '@onyx/angular';
import { AddressComponent } from '../../../../../../common/components/address/address.component';

@Component({
  selector: 'app-points-of-interest-location-cell',
  imports: [AddressComponent],
  templateUrl: './points-of-interest-location-cell.component.html',
  styleUrl: './points-of-interest-location-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsOfInterestLocationCellComponent {
  public address = input.required<OnyxAddress | null>();
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onyxFileSize',
})
export class OnyxFileSizePipe implements PipeTransform {
  public transform(fileSize: number): string {
    if (isNaN(fileSize)) return '';

    const fileSizeKB = fileSize / 1024;
    if (fileSizeKB >= 1024) {
      const filembMb = fileSizeKB / 1024;
      return filembMb % 1 === 0
        ? `${filembMb.toFixed(0)} MB`
        : `${filembMb.toFixed(2)} MB`;
    }

    return `${fileSizeKB.toFixed(2)} KB`;
  }
}

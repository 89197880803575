import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  FormHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxModalComponent,
  OnyxModalService,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { PreferencesService } from '../../../../../../common/services/preferences.service';
import { ContractorsRoute } from '../../../contractors.routes';
import { ContractorsRouterStateKey } from '../../enums/contractors-router-state-key';
import { ContractorHelper } from '../../helpers/contractor.helper';
import { Contractor } from '../../interfaces/contractor';
import { ContractorsService } from '../../services/contractors.service';

@Component({
  selector: 'app-add-contractor-modal',
  imports: [
    OnyxModalComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxDropdownComponent,
    OnyxTextFieldComponent,
    OnyxButtonComponent,
    AsyncPipe,
  ],
  templateUrl: './add-contractor-modal.component.html',
  styleUrl: './add-contractor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddContractorModalComponent {
  protected readonly I18N = 'contractors.addContractorModal';

  protected readonly vatIdCountries$ =
    this.dictionariesService.getVatIdCountries();

  private readonly conflictToastTemplate = viewChild.required<
    TemplateRef<unknown>
  >('conflictToastTemplate');

  protected form = FormHelper.buildVatIdForm(this.fb, this.preferencesService);
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  private contractor = signal<Contractor | null>(null);

  constructor(
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private preferencesService: PreferencesService,
    private dictionariesService: DictionariesService,
    private toastService: OnyxToastService,
    private router: Router,
    private contractorsService: ContractorsService,
    private modalService: OnyxModalService,
    private contractorHelper: ContractorHelper,
  ) {}

  protected next(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const { country, vatId } = this.form.getRawValue();

    this.loading.set(true);
    this.contractorsService
      .listContractors({
        country: country!,
        vatId: vatId!,
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      })
      .subscribe({
        next: (value) => {
          if (value.items.length === 0) {
            this.router.navigateByUrl(ContractorsRoute.ADD_CONTRACTOR, {
              state: {
                [ContractorsRouterStateKey.COUNTRY]: country,
                [ContractorsRouterStateKey.VAT_ID]: vatId,
              },
            });
            this.close$.next();
          } else {
            this.contractor.set(value.items[0]);
            this.toastService.showError(this.conflictToastTemplate());
          }
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected showExistingContractor(): void {
    const contractor = this.contractor();
    if (contractor) this.contractorHelper.openModal(contractor);
  }
}

@let isDefault = variant() === 'default';
<div class="drivers-times" [ngClass]="{ default: isDefault }">
  @if (drivers().length) {
    <div class="top-bar">
      @if (drivers().length === 2) {
        <onyx-tabs
          [tabs]="tabs()"
          size="m"
          [(value)]="selectedDriver"
        ></onyx-tabs>
      } @else if (drivers().length === 1) {
        <p class="{{ isDefault ? 'f-h6' : 'f-h3' }}">
          <!-- TEMP: Replace by real tacho icon -->
          <onyx-icon name="tacho-offline" [size]="16"></onyx-icon>

          {{ drivers()[0].driverData | onyxName }}
        </p>
      }

      @if (selectedDriver(); as driver) {
        <div class="buttons">
          <onyx-icon-button
            color="black"
            type="transparent"
            size="m"
            [circle]="true"
            onyxTooltip="buttons.call"
            [onyxTooltipDelay]="TOOLTIP_DELAY"
            (click)="ActionHelper.makeCall(driver.driverData.privatePhone)"
          >
            <onyx-icon
              class="telephone"
              name="telephone"
              [size]="16"
            ></onyx-icon>
          </onyx-icon-button>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="m"
            [circle]="true"
            onyxTooltip="labels.driverPreview"
            [onyxTooltipDelay]="TOOLTIP_DELAY"
            (click)="openDriverModal(driver)"
          >
            <onyx-icon name="side-modal" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      }
    </div>

    @if (!isDefault && selectedDriver()) {
      <onyx-information-heading>
        {{ I18N + '.drivingLeft' | translate }}
      </onyx-information-heading>
    }

    @if (selectedDriver(); as driver) {
      <div class="data">
        <onyx-data-widget supheading="labels.continuousDriving">
          <p heading>N/A</p>
        </onyx-data-widget>

        <onyx-data-widget supheading="labels.dailyDriving">
          <p heading>N/A</p>
        </onyx-data-widget>

        <onyx-data-widget supheading="labels.thisWeek">
          <p heading>N/A</p>
        </onyx-data-widget>

        <onyx-data-widget supheading="labels.nextWeek">
          <p heading>N/A</p>
        </onyx-data-widget>
      </div>
    }
  } @else {
    <div class="no-driver">
      <onyx-icon name="no-user-fill" [size]="16"></onyx-icon>

      <h6>{{ 'labels.driver' | translate }}</h6>
    </div>

    <onyx-message type="warning" [borderRadius]="true">
      <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

      {{
        I18N +
          (isDefault
            ? '.noAssignedDriverToVehicle'
            : '.noAssignedDriverToOrder') | translate
      }}
    </onyx-message>
  }
</div>

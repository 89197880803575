import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { DriversRoute } from '../../drivers.routes';
import { DriverHelper } from '../helpers/driver.helper';

export const driverCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const driverHelper = inject(DriverHelper);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  driverHelper.openModal(uuid);

  const urlTree = router.parseUrl(DriversRoute.DRIVERS_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};

import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  effect,
  forwardRef,
  input,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxTooltipContext } from '../../tooltip';
import { OnyxTime, OnyxTimeRange } from '../interfaces';
import {
  OnyxTimepickerInputComponent,
  TimepickerInputValue,
} from './onyx-timepicker-input/onyx-timepicker-input.component';

@Component({
  selector: 'onyx-timepicker',
  imports: [
    OnyxTimepickerInputComponent,
    OnyxFormControlErrorComponent,
    NgClass,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OnyxTimepickerComponent),
    },
  ],
  templateUrl: './onyx-timepicker.component.html',
  styleUrl: './onyx-timepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTimepickerComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  public label = input<string>();
  public hint = input<OnyxTooltipContext>();
  public labelTo = input<string>();
  public showTimeRange = input(false);
  public showClockIcon = input(true);
  public forceError = input(false);
  public showErrors = input(true);
  public width = input('100%');

  protected onChange?: (value: OnyxTime | OnyxTimeRange | null) => void;
  protected onTouched?: () => void;
  protected value = signal<{
    from?: TimepickerInputValue;
    to?: TimepickerInputValue;
  }>({});
  protected disabled = signal(false);

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    super(injector, destroyRef);
    effect(() => (this.elementRef.nativeElement.style.width = this.width()));

    effect(() => {
      const from = this.fromInput(this.value().from);
      const to = this.fromInput(this.value().to);

      const singleValue = from != null ? from : null;
      const rangeValue = from != null && to != null ? { from, to } : null;

      this.onChange?.(this.showTimeRange() ? rangeValue : singleValue);
    });
  }

  public writeValue(value: OnyxTime | OnyxTimeRange | null): void {
    if (!value) {
      this.value.set({});
    } else if (this.showTimeRange()) {
      value = value as OnyxTimeRange;
      this.value.set({
        from: this.fromString(value.from),
        to: this.fromString(value.to),
      });
    } else {
      this.value.set({ from: this.fromString(value as OnyxTime) });
    }
  }

  public registerOnChange(
    fn: (value: OnyxTime | OnyxTimeRange | null) => void,
  ): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  protected changeValue(
    type: keyof OnyxTimeRange,
    time: TimepickerInputValue,
  ): void {
    this.value.update((value) => ({
      ...value,
      [type]: time,
    }));
  }

  private fromString(value: OnyxTime): TimepickerInputValue {
    const [hour, minutes] = value.split(':').map(Number);
    return { hour, minutes };
  }

  private fromInput(value?: TimepickerInputValue): OnyxTime | null {
    if (value?.hour == null || value?.minutes == null) return null;
    return `${String(value.hour).padStart(2, '0')}:${String(
      value.minutes,
    ).padStart(2, '0')}:00`;
  }
}

import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxPaginated,
  OnyxPagination,
  OnyxToastService,
} from '@onyx/angular';
import { chain, isString, omit } from 'lodash';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  EMPTY,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { PickDeep } from 'type-fest';
import { FleetIdentifierPipe } from '../../../../common/components/pipes/fleet-identifier.pipe';
import { ConflictHelper } from '../../../../common/helpers/conflict.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { BatchFileUpload } from '../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../common/services/api.service';
import { StorageService } from '../../../../common/services/storage.service';
import { ImportProgress } from '../../../common/interfaces/import-progress';
import { FleetFormDto } from '../../fleet-form/fleet-form.component';
import { FleetAssignDriverModalForm } from '../components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import { FleetImportData } from '../constants/fleet-import-schema';
import { FleetCategory } from '../enums/fleet-category';
import { FleetState } from '../enums/fleet-state';
import { Fleet } from '../interfaces/fleet';

@Injectable({
  providedIn: 'root',
})
export class FleetService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private filterPipe: OnyxFilterPipe,
    private toastService: OnyxToastService,
    private conflictHelper: ConflictHelper,
  ) {
    super(http);
  }

  public listFleet(
    params: {
      state?: FleetState;
      category?: FleetCategory[];
      showSetsOnly?: boolean;
      showAssignedOnly?: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Fleet>> {
    return this.get('/fleet', {
      params: {
        ...(params.state && { state: params.state }),
        ...(params.category &&
          params.category.length > 0 && { 'category[]': params.category }),
        ...(params.showSetsOnly != null && {
          showSetsOnly: params.showSetsOnly,
        }),
        ...(params.showAssignedOnly != null && {
          showAssignedOnly: params.showAssignedOnly,
        }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchFleet(
    query: string,
    limit: number,
    params?: Omit<
      Parameters<FleetService['listFleet']>[0],
      keyof OnyxPagination
    >,
  ): Observable<OnyxDropdownOptionsSourceResult<Fleet>> {
    return this.listFleet({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) => ({
        options: chain(response.items)
          .map((fleet) => ({
            name: params?.showSetsOnly
              ? `${this.fleetIdentifierPipe.transform(fleet, 'bracket')} · ${this.fleetIdentifierPipe.transform(fleet.trailer!.generalInformation, 'bracket')}`
              : this.fleetIdentifierPipe.transform(fleet),
            value: fleet,
          }))
          .thru((options) =>
            this.filterPipe.transform(options, query, ['name']).slice(0, limit),
          )
          .value(),
        totalItems: response.totalItems,
      })),
    );
  }

  public getFleet(uuid: string): Observable<Fleet> {
    return this.get(`/fleet/${uuid}`);
  }

  public addFleet(dto: FleetFormDto): Observable<Fleet> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<Fleet>(`/${this.getPath(dto)}`, dto)),
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public importFleet(data: FleetImportData[]): Observable<ImportProgress> {
    const dto = data.map((item): FleetFormDto => omit(item, 'index', 'base'));

    return from(dto).pipe(
      concatMap((dto, index) =>
        this.addFleet(dto).pipe(
          map(() => ({ ok: true, index })),
          defaultIfEmpty({ ok: false, index }),
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return of({ ok: false, index });
          }),
        ),
      ),
      takeWhile(({ ok }) => ok, true),
    );
  }

  public editFleet(uuid: string, dto: FleetFormDto): Observable<Fleet> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<Fleet>(`/${this.getPath(dto)}/${uuid}`, dto)),
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public deleteFleet(dto: Fleet): Observable<void> {
    return this.delete<void>(`/${this.getPath(dto)}/${dto.uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public assignDrivers(
    form: FleetAssignDriverModalForm,
    uuid: string,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${uuid}/assign-drivers`, form).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unassignDrivers(
    uuid: string,
    driverUuid: string,
    reload = true,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${uuid}/unassign-driver`, {
      driverUuid,
    }).pipe(
      tap(() => {
        if (reload) this._reload$.next();
      }),
    );
  }

  public assignEmployees(
    uuid: string,
    employees: { employees: string[] },
  ): Observable<void> {
    return this.put<void>(
      `/vehicles/${uuid}/assigned-employees`,
      employees,
    ).pipe(tap(() => this._reload$.next()));
  }

  public batchFleet(
    vehiclesUuid: string[],
    setFields?: {
      isForSale?: boolean;
      state?: FleetState;
      assignedEmployees?: string[];
    },
    actions: string[] = [],
  ): Observable<void> {
    return this.put<void>('/fleet/batch', {
      vehiclesUuid,
      setFields,
      actions,
    }).pipe(tap(() => this._reload$.next()));
  }

  public assignTrailer(
    vehicleUuid: string,
    trailerUuid: string | null,
    reload = true,
  ): Observable<void> {
    return this.put<void>(`/vehicles/${vehicleUuid}/trailer`, {
      trailerUuid,
    }).pipe(
      tap(() => {
        if (reload) this._reload$.next();
      }),
    );
  }

  private getPath(
    dto: FleetCategory | PickDeep<Fleet, 'generalInformation.category'>,
  ): string {
    const category = isString(dto) ? dto : dto.generalInformation.category;
    return category.includes('trailer') ? 'trailers' : 'vehicles';
  }

  private uploadFiles(dto: FleetFormDto): Observable<FleetFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        {
          path: 'registrationCertificate.scan',
          files: dto.registrationCertificate.scan,
        },
        ...(dto.co2Emissions
          ? [
              {
                path: 'co2Emissions.emissionCertificate',
                files: dto.co2Emissions.emissionCertificate,
              },
            ]
          : []),
        {
          path: 'thirdPartyLiabilityInsurance.contractScan',
          files: dto.thirdPartyLiabilityInsurance.contractScan,
        },
        ...(dto.comprehensiveInsurance
          ? [
              {
                path: 'comprehensiveInsurance.contractScan',
                files: dto.comprehensiveInsurance.contractScan,
              },
            ]
          : []),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }

  private catchConflictErrors(
    dto: FleetFormDto,
    response: HttpErrorResponse,
  ): Observable<never> {
    if (response.status !== HttpStatusCode.BadRequest) throw response;

    const I18N = 'fleet.fleetForm';
    const conflictFields = [
      {
        key: 'generalInformation.registrationNumber',
        message: `${I18N}.uniqueErrors.registrationNumber`,
        params: {
          registrationNumber: dto.generalInformation?.registrationNumber,
        },
      },
      {
        key: 'generalInformation.sideNumber',
        message: `${I18N}.uniqueErrors.sideNumber`,
        params: {
          sideNumber: dto.generalInformation.sideNumber,
        },
      },
      {
        key: 'generalInformation.vinNumber',
        message: `${I18N}.uniqueErrors.vinNumber`,
        params: {
          vinNumber: dto.generalInformation.vinNumber,
        },
      },
      {
        key: 'licensesAndPermits.communityLicenseAbstractNumber',
        message: `${I18N}.uniqueErrors.communityLicenseAbstractNumber`,
        params: {
          communityLicenseAbstractNumber:
            dto.licensesAndPermits?.communityLicenseAbstractNumber,
        },
      },
      {
        key: 'licensesAndPermits.permitAbstractNumber',
        message: `${I18N}.uniqueErrors.permitAbstractNumber`,
        params: {
          permitAbstractNumber: dto.licensesAndPermits?.permitAbstractNumber,
        },
      },
      {
        key: 'thirdPartyLiabilityInsurance.insuranceNumber',
        message: `${I18N}.uniqueErrors.thirdPartyLiabilityInsuranceNumber`,
        params: {
          thirdPartyLiabilityInsuranceNumber:
            dto.thirdPartyLiabilityInsurance?.insuranceNumber,
        },
      },
      {
        key: 'comprehensiveInsurance.insuranceNumber',
        message: `${I18N}.uniqueErrors.comprehensiveInsuranceNumber`,
        params: {
          comprehensiveInsuranceNumber:
            dto.comprehensiveInsurance?.insuranceNumber,
        },
      },
    ];

    if (this.conflictHelper.handleConflictErrors(response, conflictFields)) {
      return EMPTY;
    }

    throw response;
  }
}

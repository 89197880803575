@if (ownership().vehicleValue; as vehicleValue) {
  <app-amount-cell [amount]="vehicleValue"></app-amount-cell>
} @else if (ownership().type === OwnershipType.LEASING) {
  {{ I18N + '.leasingProfile' | translate }}
} @else if (ownership().type === OwnershipType.LOAN) {
  {{ I18N + '.loanProfile' | translate }}
} @else if (ownership().type === OwnershipType.RENTAL) {
  {{ I18N + '.rentalProfile' | translate }}
} @else {
  -
}

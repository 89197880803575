import { LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxTagComponent } from '@onyx/angular';
import { ContractorHelper } from '../../../../contractors/common/helpers/contractor.helper';
import { SimplifiedContractor } from '../../../../contractors/common/interfaces/contractor';
import { PointOfInterest } from '../../interfaces/point-of-interest';

@Component({
  selector: 'app-points-of-interest-assigned-contractors-cell',
  imports: [LowerCasePipe, TranslatePipe, OnyxTagComponent],
  templateUrl: './points-of-interest-assigned-contractors-cell.component.html',
  styleUrl: './points-of-interest-assigned-contractors-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsOfInterestAssignedContractorsCellComponent {
  public contractors = input.required<
    PointOfInterest['contractors'][number][] | null
  >();

  constructor(private contractorHelper: ContractorHelper) {}

  protected openModal(contractor: SimplifiedContractor): void {
    this.contractorHelper.openModal(contractor);
  }
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxFormMode,
  OnyxModalComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
  OnyxUploadComponent,
} from '@onyx/angular';
import { map, of, Subject, switchMap } from 'rxjs';
import { ValidationHelper } from '../../../../helpers/validation.helper';
import { BatchFileUpload } from '../../../../interfaces/utilities/batch-file-upload';
import { StorageService } from '../../../../services/storage.service';

export type DocumentModalData =
  | { mode: OnyxFormMode.ADD }
  | { mode: OnyxFormMode.EDIT; document: DocumentForm };
export type DocumentModalResult = DocumentForm | null | undefined;

export type DocumentFormGroup = ReturnType<
  typeof DocumentsModalFormComponent.buildForm
>;
export type DocumentForm = ReturnType<DocumentFormGroup['getRawValue']>;

@Component({
  selector: 'app-documents-modal-form',
  imports: [
    OnyxModalComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxTextFieldComponent,
    OnyxUploadComponent,
    OnyxButtonComponent,
  ],
  templateUrl: './documents-modal-form.component.html',
  styleUrl: './documents-modal-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsModalFormComponent {
  protected readonly I18N = 'forms.documents';

  protected readonly OnyxFormMode = OnyxFormMode;

  protected form: DocumentFormGroup;
  protected loading = signal(false);
  protected close$ = new Subject<DocumentModalResult>();

  constructor(
    @Inject(DIALOG_DATA) protected data: DocumentModalData,
    protected dialogRef: DialogRef<DocumentModalResult>,
    private toastService: OnyxToastService,
    private storageService: StorageService,
    private fb: NonNullableFormBuilder,
  ) {
    this.form = DocumentsModalFormComponent.buildForm(this.fb);
    if (this.data.mode === OnyxFormMode.EDIT) {
      this.form.setValue(this.data.document);
    }
  }

  protected remove(): void {
    this.close$.next(null);
  }

  protected cancel(): void {
    this.close$.next(undefined);
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const dto = this.form.getRawValue();

    this.loading.set(true);
    of(dto)
      .pipe(
        map((dto): BatchFileUpload[] => [
          {
            path: 'attachments',
            files: dto.attachments as any,
          },
        ]),
        switchMap((data) => this.storageService.uploadBatch(data)),
        map((data) => this.storageService.mergeBatch(dto, data)),
      )
      .subscribe({
        next: (data) => this.close$.next(data),
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  public static buildForm(fb: NonNullableFormBuilder) {
    return fb.group({
      name: fb.control<string | null>(null, [Validators.required]),
      note: fb.control<string | null>(null),
      attachments: fb.control<string[]>([], [Validators.required]),
    });
  }
}

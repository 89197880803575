import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe } from '@onyx/angular';
import { DriverIdentityDocument } from '../../../../dashboard/drivers/common/interfaces/driver';
import { ColorHelper } from '../../../helpers/color.helper';
import { CountryCellComponent } from '../country-cell/country-cell.component';

@Component({
  selector: 'app-document-cell',
  imports: [
    CountryCellComponent,
    OnyxDatePipe,
    TranslatePipe,
    NgTemplateOutlet,
  ],
  templateUrl: './document-cell.component.html',
  styleUrl: './document-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCellComponent {
  public document = input.required<DriverIdentityDocument | null | undefined>();

  protected cellColor = computed(() =>
    ColorHelper.getCellColor(this.document()?.expirationDate),
  );
}

@if (base(); as base) {
  <div class="base-cell">
    <p>{{ base.name }}</p>

    <p class="address f-regular-3">
      <app-address
        [address]="base.address"
        format="short"
        [showFlag]="false"
      ></app-address>
    </p>
  </div>
} @else if (vehicle() || driver()) {
  <onyx-link
    color="blue"
    font="f-medium-2"
    [inactive]="isArchived()"
    [onyxTooltip]="
      vehicle()
        ? 'fleet.fleetList.cells.cannotAssignBase'
        : 'drivers.driversList.cannotAssignBase'
    "
    [onyxTooltipDelay]="TOOLTIP_DELAY"
    [onyxTooltipEnabled]="isArchived()"
    (click)="!isArchived() ? assignBase() : null; $event.stopPropagation()"
  >
    {{ 'buttons.assign' | translate }}
  </onyx-link>
} @else {
  -
}

<div class="vat-cell">
  @if (vatValue(); as vatValue) {
    <app-amount-cell
      [amount]="vatRate()"
      [percentage]="vatValue"
      [short]="false"
    ></app-amount-cell>
  } @else {
    <app-string-cell
      [value]="vatRate().vatRate"
      [dictionaryCode]="DictionaryCode.VAT_RATE"
    ></app-string-cell>
  }
</div>

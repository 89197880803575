import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ONYX_TOASTS_LIMIT, OnyxToastService } from '@onyx/angular';
import { get, reverse } from 'lodash';
import { CONFLICT_ERROR_CODE } from '../constants/conflict-error-code';
import { ConflictField } from '../interfaces/utilities/conflict-field';

@Injectable({
  providedIn: 'root',
})
export class ConflictHelper {
  constructor(
    private toastService: OnyxToastService,
    private translateService: TranslateService,
  ) {}

  public handleConflictErrors(
    response: HttpErrorResponse,
    fields: ConflictField[],
  ): boolean {
    const errors = fields
      .filter(({ key }) => get(response.error, key) === CONFLICT_ERROR_CODE)
      .slice(0, ONYX_TOASTS_LIMIT);
    if (!errors.length) return false;

    for (const { message, params } of reverse(errors)) {
      this.toastService.showError(
        this.translateService.instant(message, params),
      );
    }
    return true;
  }
}

@let generalInformation = vehicle().generalInformation;

<div class="vehicle-heading">
  @let categoryCode =
    isVehicle()
      ? DictionaryCode.VEHICLE_CATEGORY
      : DictionaryCode.TRAILER_CATEGORY;
  <p>{{ categoryCode + '.' + generalInformation.category | translate }}</p>

  @let lowDeck = vehicle().additionalParameters.isLowDeck;
  @if (lowDeck != null) {
    <span>·</span>

    <p>
      {{ (lowDeck ? 'labels.lowDeck' : 'labels.standardDeck') | translate }}
    </p>
  }

  @if (generalInformation.type; as type) {
    <span>·</span>

    <p>
      {{ DictionaryCode.VEHICLE_TYPE + '.' + type | translate }}
    </p>
  }

  <span>·</span>

  <p>{{ generalInformation.make }} {{ generalInformation.model }}</p>
</div>

<onyx-modal
  type="center"
  [heading]="I18N + '.editEmployee'"
  size="m"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <div class="group">
      <onyx-text-field
        [formControl]="controls.firstName"
        label="labels.firstName"
      ></onyx-text-field>

      <onyx-text-field
        [formControl]="controls.lastName"
        label="labels.lastName"
      ></onyx-text-field>
    </div>

    <div class="group">
      <onyx-phone-number-input
        [formControl]="controls.phone"
        label="labels.phone"
      ></onyx-phone-number-input>

      <onyx-text-field
        [formControl]="controls.email"
        label="labels.email"
        autocomplete="email"
      ></onyx-text-field>
    </div>

    <onyx-dropdown
      [formControl]="controls.roles"
      label="labels.roles"
      [options]="employeeRoles$ | async"
      [multiple]="true"
      [search]="true"
    ></onyx-dropdown>
  </form>

  <onyx-button
    bottomLeftOptions
    color="red"
    type="outlined"
    (click)="archiveEmployee()"
  >
    {{ 'buttons.archive' | translate }}
  </onyx-button>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxPhone, OnyxPhonePipe } from '@onyx/angular';

@Component({
  selector: 'app-phone-cell',
  imports: [OnyxPhonePipe],
  templateUrl: './phone-cell.component.html',
  styleUrl: './phone-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneCellComponent {
  public phone = input.required<OnyxPhone | null>();
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDatePipe,
  OnyxIconComponent,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { Delegation } from '../../../common/interfaces/delegation';
import { DelegationsRoute } from '../../../delegations.routes';

@Component({
  selector: 'app-delegation-modal-note',
  imports: [
    TranslatePipe,
    OnyxDatePipe,
    OnyxButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './delegation-modal-note.component.html',
  styleUrl: './delegation-modal-note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalNoteComponent {
  public note = input.required<Delegation['note']>();
  public uuid = input.required<string>();
  public close$ = input.required<Subject<void>>();

  constructor(private router: Router) {}

  protected edit(): void {
    this.router.navigateByUrl(
      `${DelegationsRoute.EDIT_DELEGATION.replace(':uuid', this.uuid())}`,
    );
    this.close$().next();
  }
}

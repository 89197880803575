<onyx-information-heading [count]="contractor().contactPersons.length">
  {{ 'labels.contactPersons' | translate }}
</onyx-information-heading>

@for (person of contractor().contactPersons; track person) {
  <onyx-information-row
    class="contact-persons"
    [label]="DictionaryCode.EMPLOYEE_ROLE + '.' + person.roles[0] | translate"
  >
    <div class="contact-person">
      <p class="name">{{ person | onyxName }}</p>

      <p>{{ person.email }}</p>

      <p>{{ person.phone | onyxPhone }}</p>
    </div>

    <div class="contact-person-buttons">
      <onyx-icon-button
        type="transparent"
        size="s"
        color="black"
        (click)="ActionHelper.makeCall(person.phone)"
      >
        <onyx-icon name="telephone" [size]="16"></onyx-icon>
      </onyx-icon-button>

      <onyx-icon-button
        type="transparent"
        size="s"
        color="black"
        (click)="ActionHelper.sendEmail(person.email)"
      >
        <onyx-icon name="e-mail" [size]="16"></onyx-icon>
      </onyx-icon-button>
    </div>
  </onyx-information-row>
}

<onyx-modal
  type="center"
  [heading]="I18N + '.otherReason'"
  size="s"
  [close$]="close$"
  (closeModal)="dialogRef.close($event)"
>
  <onyx-textarea
    [formControl]="reason"
    [placeholder]="I18N + '.rejectReason'"
  ></onyx-textarea>

  <ng-container bottomRightOptions>
    <onyx-button color="blue" type="outlined" (click)="close$.next(false)">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

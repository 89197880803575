import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { importGuard } from '../common/guards/import.guard';
import { FLEET_IMPORT_COLUMNS } from './common/constants/fleet-import-columns';
import { addFleetGuard } from './common/guards/add-fleet.guard';
import { addFleetResolver } from './common/resolvers/add-fleet.resolver';
import { editFleetResolver } from './common/resolvers/edit-fleet.resolver';
import { fleetCardResolver } from './common/resolvers/fleet-card.resolver';
import { fleetResolver } from './common/resolvers/fleet.resolver';
import { importFleetResolver } from './common/resolvers/import-fleet.resolver';

export enum FleetRoute {
  FLEET_LIST = '/fleet',
  ADD_FLEET = '/fleet/add',
  IMPORT_RESULT = '/fleet/import',
  FLEET_CARD = '/fleet/:uuid',
  EDIT_FLEET = '/fleet/:uuid/edit',
  FLEET_SERVICE = '/fleet/:uuid/service',
}

export const FLEET_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./fleet-list/fleet-list.component').then(
        (m) => m.FleetListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./fleet-form/fleet-form.component').then(
        (m) => m.FleetFormComponent,
      ),
    canActivate: [addFleetGuard],
    data: { mode: OnyxFormMode.ADD },
    resolve: { fleet: addFleetResolver },
  },
  {
    path: 'import',
    loadComponent: () =>
      import(
        '../common/components/import/import-result/import-result.component'
      ).then((m) => m.ImportResultComponent),
    canActivate: [importGuard],
    data: { columns: FLEET_IMPORT_COLUMNS },
    resolve: { import: importFleetResolver },
  },
  {
    path: ':uuid',
    children: [],
    resolve: { fleet: fleetCardResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./fleet-form/fleet-form.component').then(
        (m) => m.FleetFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { fleet: editFleetResolver },
  },
  {
    path: ':uuid/service',
    loadComponent: () =>
      import('./fleet-service/fleet-service.component').then(
        (m) => m.FleetServiceComponent,
      ),
    resolve: { fleet: fleetResolver },
  },
] as const;

import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { AddressCellComponent } from '../../../../../common/components/cells/address-cell/address-cell.component';
import { SimplifiedDriver } from '../../../../drivers/common/interfaces/driver';
import { BaseDriverCellComponent } from '../cells/base-driver-cell/base-driver-cell.component';

export const BASE_ASSIGNED_DRIVERS_LIST_COLUMNS: OnyxTableColumn<SimplifiedDriver>[] =
  [
    {
      id: 'driver',
      name: 'labels.driver',
      width: 'fill',
      component: {
        ref: BaseDriverCellComponent,
        inputs: (data): OnyxComponentInputs<BaseDriverCellComponent> => ({
          driver: data.item,
        }),
      },
    },
    {
      id: 'address',
      name: 'labels.address',
      width: '370px',
      component: {
        ref: AddressCellComponent,
        inputs: (data): OnyxComponentInputs<AddressCellComponent> => ({
          address: data.item.driverData.homeAddress,
          format: 'short',
        }),
      },
    },
  ];

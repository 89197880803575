<div class="order-heading">
  @let sender = order().basicInformation.sender;
  <onyx-link
    color="black"
    font="f-regular-2"
    (click)="openContractorModal(sender)"
  >
    {{ sender.companyProfile.displayName }}
  </onyx-link>

  <span>·</span>

  <div class="item">
    <p class="gray">{{ 'labels.orders' | translate }}</p>

    <p>N/A</p>
  </div>

  <span>·</span>

  <div class="item">
    <p class="gray">{{ 'labels.payer' | translate }}</p>

    <p>N/A</p>
  </div>
</div>

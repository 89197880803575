@if (fuelType() && tankCapacity()) {
  <div class="tank">
    <p>{{ DictionaryCode.FUEL_TYPE + '.' + fuelType() | translate }}</p>
    <p class="capacity f-regular-3">
      {{ tankCapacity()! / 100 | number: '1.0-2' }} L
    </p>
  </div>
} @else {
  -
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDatePipe,
  OnyxIconComponent,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { Note } from '../../../interfaces/common/note';

@Component({
  selector: 'app-modal-note',
  imports: [
    TranslatePipe,
    OnyxDatePipe,
    OnyxButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './modal-note.component.html',
  styleUrl: './modal-note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNoteComponent {
  public note = input.required<Note>();
  public route = input.required<string>();
  public close$ = input.required<Subject<void>>();
  public state = input<Record<string, string>>();

  constructor(private router: Router) {}

  protected edit(): void {
    const state = this.state() ? { state: this.state() } : undefined;

    this.router.navigateByUrl(this.route(), state);
    this.close$().next();
  }
}

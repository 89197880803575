import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  Injector,
  input,
  model,
  OnInit,
  output,
  signal,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { castArray, chain, get, isEqual, isObject, PropertyPath } from 'lodash';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  of,
  Subject,
  switchMap,
  throttleTime,
} from 'rxjs';
import {
  OnyxDropdownDirective,
  OnyxTextOverflowDirective,
} from '../../../directives';
import {
  OnyxDropdownConfig,
  OnyxDropdownOptions,
  OnyxOption,
  OnyxOptionsGroup,
} from '../../../directives/interfaces';
import { DropdownHelper, UtilityHelper } from '../../../helpers';
import { OnyxMaybeArray } from '../../../interfaces';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxFilterPipe } from '../../../pipes';
import { OnyxClearButtonComponent } from '../../buttons';
import { OnyxIconComponent } from '../../icons';
import { LabelLink, OnyxInputLabelComponent } from '../../labels';
import { OnyxPaths } from '../../table';
import { OnyxTooltipContext } from '../../tooltip';
import { OnyxDropdownOptionsSource } from '../interfaces';

type OptionsInput<T> =
  | OnyxDropdownOptions<T>
  | OnyxDropdownOptionsSource<T>
  | null;
type Options<T> = OnyxOptionsGroup<T>[] | OnyxDropdownOptionsSource<T> | null;
type Value = OnyxMaybeArray<any> | null;
type OnChange = (value: Value) => void;

@Component({
  selector: 'onyx-dropdown',
  imports: [
    OnyxInputLabelComponent,
    OnyxIconComponent,
    OnyxDropdownDirective,
    OnyxClearButtonComponent,
    OnyxFormControlErrorComponent,
    OnyxTextOverflowDirective,
    NgClass,
    TranslatePipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxDropdownComponent),
      multi: true,
    },
    {
      provide: OnyxFilterPipe,
      useClass: OnyxFilterPipe,
    },
  ],
  templateUrl: './onyx-dropdown.component.html',
  styleUrl: './onyx-dropdown.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDropdownComponent<T>
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor, OnInit
{
  protected readonly I18N = `${I18N_NAMESPACE}.dropdown`;

  private readonly dropdownElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('dropdownElement');

  public label = input<string>();
  public placeholder = input(`${this.I18N}.choose`);
  public hint = input<OnyxTooltipContext>();
  public link = input<LabelLink>();
  public options = input.required({
    transform: (options: OptionsInput<T>): Options<T> => {
      if (options == null) return null;
      if (this.isSource(options)) return options;
      return this.mapToGroups(options);
    },
  });
  public value = model<Value>();
  public compareKey = input<OnyxPaths<T> | PropertyPath>();
  public optional = input(false);
  public multiple = input(false);
  public search = input(false);
  public searchPlaceholder = input<string>();
  public searchKeys = input<(OnyxPaths<T> | PropertyPath)[]>();
  public sourceLimit = input(10);
  public notFoundMessage = input<string>();
  public showAddOption = input<boolean | 'value'>(false);
  public addOptionMessage = input<string>();
  public ribbon = input(false);
  public transparentRibbon = input(false);
  public subheading = input<string>();
  public subheadingTemplateRef = input<TemplateRef<any>>();
  public optionTemplateRef = input<TemplateRef<any>>();
  public showOptional = input(true);
  public showErrors = input(true);
  public size = input<'s' | 'm'>('m');
  public overlayWidth = input<OnyxDropdownConfig<T>['width']>('inherit');
  public optionSize = input<OnyxDropdownConfig<T>['optionSize']>('m');
  public width = input('100%');

  public optionsChange = output<OnyxDropdownOptions<T>>();
  public totalItemsChange = output<number>();
  public selectedOptionChange = output<OnyxOption<T> | null>();
  public selectedOptionsChange = output<OnyxOption<T>[]>();
  public selectedValueChange = output<T | null>();
  public selectedValuesChange = output<T[] | null>();
  public addOption = output<string | undefined>();

  protected disabled = signal(false);
  protected onChange?: OnChange;
  protected onTouched?: () => void;

  protected expanded = signal(false);
  protected query = signal('');

  protected selectedOptions = signal<OnyxOption<T>[]>([]);
  protected selectedNames = computed(() =>
    this.selectedOptions().map((option) =>
      this.translateService.instant(option.name),
    ),
  );

  private _filteredOptions$ = new BehaviorSubject<OnyxOptionsGroup<T>[] | null>(
    null,
  );
  protected get filteredOptions$() {
    return this._filteredOptions$.asObservable();
  }

  private selectedValues = computed(() =>
    this.selectedOptions().map((option) => option.value),
  );
  private loadingValues = signal<unknown[]>([]);

  private selectedOptionsUpdate$ = new Subject<{
    source: OnyxDropdownOptionsSource<T>;
    values: unknown[];
  }>();
  private filteredOptionsUpdate$ = new Subject<{
    source: OnyxDropdownOptionsSource<T>;
    query: string;
  }>();

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef,
    private filterPipe: OnyxFilterPipe,
    private translateService: TranslateService,
  ) {
    super(injector, destroyRef);

    effect(() => (this.elementRef.nativeElement.style.width = this.width()));

    effect(() => {
      const selectedOptions = this.selectedOptions();
      this.selectedOptionChange.emit(selectedOptions[0] ?? null);
      this.selectedOptionsChange.emit(selectedOptions);
    });

    effect(() => {
      const selectedValues = this.selectedValues();
      this.selectedValueChange.emit(selectedValues[0] ?? null);
      this.selectedValuesChange.emit(selectedValues);
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.selectedOptionsUpdate$
      .pipe(
        filter(
          ({ values }) =>
            !isEqual(
              chain(this.value()).castArray().compact().value()?.sort(),
              values?.sort(),
            ),
        ),
        switchMap(({ source, values }) =>
          values.length
            ? forkJoin(
                values.map((value) =>
                  UtilityHelper.maybeAsyncToObservable(source.get(value)),
                ),
              )
            : of([]),
        ),
        map((options) =>
          chain(options)
            .compact()
            .map(
              (option): OnyxOption<T> => ({
                ...option,
                _selected: true,
                _hidden: true,
              }),
            )
            .value(),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((selectedOptions) =>
        this.changeSelectedOptions(selectedOptions),
      );

    this.filteredOptionsUpdate$
      .pipe(
        distinctUntilChanged((previous, { query }) => previous.query === query),
        throttleTime(300, undefined, { trailing: true }),
        switchMap(({ source, query }) =>
          UtilityHelper.maybeAsyncToObservable(
            source.list(query, this.sourceLimit()),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        const { options, totalItems } = result;
        this._filteredOptions$.next(this.mapToGroups(options));
        this.optionsChange.emit(options);
        this.totalItemsChange.emit(totalItems);
      });

    toObservable(this.value, { injector: this.injector })
      .pipe(
        filter((value) => value !== undefined),
        distinctUntilChanged(isEqual),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.writeValue(value));

    effect(
      () => {
        const options = this.options();
        const query = this.query();

        if (this.isSource(options)) {
          this.filteredOptionsUpdate$.next({ source: options, query });
        } else {
          this._filteredOptions$.next(this.filterOptions(options, query));
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const options = this.options();
        if (this.isSource(options) || !options?.length) return;

        const loadingValues = this.loadingValues();
        if (!loadingValues.length) return;

        this.writeValue(loadingValues);
      },
      { injector: this.injector },
    );
  }

  public writeValue(value: Value): void {
    const options = this.options();
    if (value == null) {
      if (this.isSource(options)) {
        this.selectedOptionsUpdate$.next({
          source: options,
          values: [],
        });
      } else {
        this.selectedOptions.set([]);
        this.loadingValues.set([]);
      }
      return;
    }

    const values = castArray(value);
    if (this.isSource(options)) {
      this.selectedOptionsUpdate$.next({
        source: options,
        values,
      });
      return;
    }

    const flatOptions = options?.flatMap((group) => group.options);
    if (!flatOptions?.length) {
      this.selectedOptions.set([]);
      this.loadingValues.set(values);
      return;
    }

    const selectedOptions = chain(values)
      .map((value) =>
        flatOptions.find((option) =>
          isEqual(
            this.getCompareValue(option.value),
            this.getCompareValue(value),
          ),
        ),
      )
      .compact()
      .map(
        (option): OnyxOption<T> => ({
          ...option,
          _selected: true,
        }),
      )
      .value();
    this.changeSelectedOptions(selectedOptions);
  }

  public registerOnChange(fn: OnChange): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  public focus(): void {
    if (this.disabled()) return;

    this.dropdownElementRef().nativeElement.focus();
    this.expanded.set(true);
  }

  protected changeSelectedOptions(options: OnyxOption<T>[]): void {
    this.selectedOptions.set(options);
    this.loadingValues.set([]);

    const value = chain(this.selectedValues())
      .map((value) => {
        const options = this.options();
        if (!this.isSource(options)) return value;
        return get(value, options.idKey) ?? value;
      })
      .thru((values) => (this.multiple() ? values : (values[0] ?? null)))
      .value();

    this.value.set(value);
    this.onChange?.(value);
    this.onTouched?.();
  }

  protected clearValue(): void {
    this.query.set('');
    this.expanded.set(false);
    this.changeSelectedOptions([]);
  }

  protected isSource(
    options: OptionsInput<T> | Options<T>,
  ): options is OnyxDropdownOptionsSource<T> {
    if (!isObject(options)) return false;
    return ['list', 'get', 'idKey'].every((key) => key in options);
  }

  private getCompareValue(value: T): T | unknown {
    const options = this.options();
    const compareKey = this.isSource(options)
      ? options.idKey
      : this.compareKey();
    return compareKey ? (get(value, compareKey) ?? value) : value;
  }

  private filterOptions(
    groups: OnyxOptionsGroup<T>[] | null,
    query: string,
  ): OnyxOptionsGroup<T>[] | null {
    if (groups == null) return null;

    return groups.map((group) => ({
      ...group,
      options: this.filterPipe.transform(group.options, query, [
        'name',
        ...(this.searchKeys() ?? []).map(
          (key) => `value.${key.toString()}` as OnyxPaths<OnyxOption<T>>,
        ),
      ]),
    }));
  }

  private mapToGroups(
    options: OnyxDropdownOptions<T>,
  ): OnyxOptionsGroup<T>[] | null {
    return DropdownHelper.mapToGroups(
      options,
      this.subheading(),
      this.subheadingTemplateRef(),
    );
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ImportRouterStateKey } from '../enums/import-router-state-key';

export const importGuard: CanActivateFn = (
  _,
  snapshot: RouterStateSnapshot,
) => {
  const router = inject(Router);

  const state = router.getCurrentNavigation()?.extras?.state;
  for (const key of Object.values(ImportRouterStateKey)) {
    if (state?.[key] != null) continue;
    return router.parseUrl(snapshot.url.replace(/\/import$/, ''));
  }

  return true;
};

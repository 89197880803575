import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { Amount } from '../../../interfaces/common/amount';

@Component({
  selector: 'app-amount-cell',
  imports: [TranslatePipe, CurrencyPipe],
  templateUrl: './amount-cell.component.html',
  styleUrl: './amount-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountCellComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public amount = input.required<Pick<Amount, 'value' | 'currency'> | null>();
  public percentage = input<number | null>(null);
  public short = input(true);
}

import {
  OnyxDropdownConfig,
  OnyxDropdownOptions,
  OnyxOption,
  OnyxOptionsGroup,
} from '../directives';

export class DropdownHelper {
  public static mapToGroups<T>(
    options: OnyxDropdownOptions<T>,
    subheading?: OnyxDropdownConfig<any>['subheading'],
    subheadingTemplateRef?: OnyxDropdownConfig<any>['subheadingTemplateRef'],
  ): OnyxOptionsGroup<T>[] | null {
    if (options == null) return null;
    if (options.length === 0) return [];
    if ('options' in options[0]) {
      return options as OnyxOptionsGroup<T>[];
    }
    return [
      {
        subheading,
        subheadingTemplateRef,
        options: options as OnyxOption<T>[],
      },
    ];
  }

  public static mapToOptions<T>(
    options: OnyxDropdownOptions<T>,
  ): OnyxOption<T>[] | null {
    const groups = DropdownHelper.mapToGroups(options);
    return groups?.flatMap((group) => group.options) ?? null;
  }

  public static updateGroupOptions<T>(
    options: OnyxDropdownOptions<T>,
    map: (options: OnyxOption<T>[]) => OnyxOption<T>[],
  ): OnyxOptionsGroup<T>[] | null {
    const groups = DropdownHelper.mapToGroups(options);
    return (
      groups?.map((group) => ({
        ...group,
        options: map(group.options),
      })) ?? null
    );
  }
}

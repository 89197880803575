import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  signal,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
} from '@onyx/angular';
import { DriversTimesComponent } from '../../../../../common/components/drivers-times/drivers-times.component';
import { SimplifiedDriver } from '../../../../drivers/common/interfaces/driver';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-drivers',
  standalone: true,
  imports: [
    DriversTimesComponent,
    OnyxDataWidgetComponent,
    TranslatePipe,
    NgClass,
    OnyxIconComponent,
    OnyxInformationHeadingComponent,
  ],
  templateUrl: './order-modal-drivers.component.html',
  styleUrl: './order-modal-drivers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalDriversComponent {
  public order = input.required<Order>();

  protected driver = signal<SimplifiedDriver | null>(null);
}

export enum OnyxIconBoxColor {
  VIOLET = 'violet',
  ORANGE = 'orange',
  GREEN = 'green',
  GRAY = 'gray',
  DARK_GRAY = 'dark-gray',
  BLUE = 'blue',
  BLACK = 'black',
  RED = 'red',
  WHITE = 'white',
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { AuthService } from '../../../../../auth/common/services/auth.service';
import { ApiService } from '../../../../../common/services/api.service';
import { CompanyDataFormDto } from '../../company-data/company-data.component';
import { Business } from '../interfaces/business';

@Injectable({
  providedIn: 'root',
})
export class CompanyDataService extends ApiService {
  constructor(
    protected override http: HttpClient,
    private authService: AuthService,
  ) {
    super(http);
  }

  public getCompanyData(): Observable<Business> {
    return this.get('/business');
  }

  public updateCompanyData(dto: CompanyDataFormDto): Observable<void> {
    return this.put<Business>('/business', dto).pipe(
      tap((business) => this.authService.updateBusiness(business)),
      map(() => undefined),
    );
  }

  public updateBusinessLogo(logo: File): Observable<void> {
    const data = new FormData();
    data.set('logo', logo);

    return this.post<{ storageUuid: string }>('/business/logo', data).pipe(
      map(({ storageUuid }) => ({
        ...this.authService.business()!,
        logo: storageUuid,
      })),
      tap((business) => this.authService.updateBusiness(business)),
      map(() => undefined),
    );
  }

  public deleteBusinessLogo(): Observable<void> {
    return this.delete<void>('/business/logo').pipe(
      tap(() =>
        this.authService.updateBusiness({
          ...this.authService.business()!,
          logo: undefined,
        }),
      ),
    );
  }
}

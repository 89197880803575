@if (date(); as date) {
  <div class="date-cell {{ cellColor() }}">
    <ng-container
      *ngTemplateOutlet="dateTemplate; context: { date, prefix: prefix() }"
    ></ng-container>
  </div>

  <div class="hidden">
    <ng-container
      *ngTemplateOutlet="dateTemplate; context: { date, prefix: prefix() }"
    ></ng-container>
  </div>
} @else {
  -
}

<ng-template let-date="date" let-prefix="prefix" #dateTemplate>
  @if (prefix) {
    {{ prefix | translate }}
  }
  {{ date | onyxDate: format() }}
</ng-template>

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxBadge, OnyxBadgeComponent } from '@onyx/angular';
import { DictionaryCode } from '../../../enums/dictionary-code';

@Component({
  selector: 'app-roles-cell',
  imports: [OnyxBadgeComponent, TranslatePipe],
  templateUrl: './badges-cell.component.html',
  styleUrl: './badges-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgesCellComponent<T> {
  public badges = input.required<OnyxBadge<T>[] | null>();
  public dictionaryCode = input.required<DictionaryCode>();
}

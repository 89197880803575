import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { ContractorForm } from '../../contractor-form/contractor-form.component';
import { ContractorsRoute } from '../../contractors.routes';
import { ContractorHelper } from '../helpers/contractor.helper';
import { ContractorsService } from '../services/contractors.service';

export const editContractorResolver: ResolveFn<ContractorForm> = (
  route: ActivatedRouteSnapshot,
) => {
  const contractorsService = inject(ContractorsService);
  const toastService = inject(OnyxToastService);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  const urlTree = router.parseUrl(ContractorsRoute.CONTRACTORS_LIST);

  return contractorsService.getContractor(uuid).pipe(
    map((contractor) => {
      if (ContractorHelper.isArchived(contractor)) {
        toastService.showError('toasts.archived', { keepOnNavigation: true });
        return new RedirectCommand(urlTree, { replaceUrl: true });
      }

      return ContractorHelper.fromDto(contractor);
    }),
    catchError((response: HttpErrorResponse) => {
      if (response.status === HttpStatusCode.BadRequest) {
        toastService.showError('contractors.toasts.contractorNotFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(response, toastService, {
          keepOnNavigation: true,
        });
      }

      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};

@if (address(); as address) {
  @switch (format()) {
    @case ('full') {
      <div class="address">
        <ng-container
          *ngTemplateOutlet="
            flagTemplate;
            context: { countryCode: address.countryCode }
          "
        ></ng-container>

        <p>
          @if (address.street) {
            {{ address.street }} {{ address.houseNumber
            }}{{
              address.apartmentNumber ? '/' + address.apartmentNumber : ''
            }},
          }
          {{ address.zipCode }} {{ address.city }},
          {{ address.countryCode | uppercase }}
        </p>
      </div>
    }
    @case ('extended') {
      <div class="extended-address">
        <div class="location">
          <ng-container
            *ngTemplateOutlet="
              flagTemplate;
              context: { countryCode: address.countryCode }
            "
          ></ng-container>

          <p class="location-container">
            {{ address.zipCode }} {{ address.city }}
          </p>
        </div>

        @if (address.street) {
          <div class="street">
            <p class="street-data">
              <span>
                {{ 'labels.streetShortcut' | translate }}
                {{ address.street }}
                {{ address.houseNumber }}
              </span>

              <span>{{ address.apartmentNumber ? ',' : '' }}</span>
            </p>

            @if (address.apartmentNumber) {
              <span>
                {{ 'labels.apartmentNumberShortcut' | translate }}
                {{ address.apartmentNumber }}
              </span>
            }
          </div>
        }

        <div class="coordinates">
          {{ address.coordinates.latitude }},
          {{ address.coordinates.longitude }}
        </div>
      </div>
    }
    @case ('short') {
      <div class="short-address">
        <ng-container
          *ngTemplateOutlet="
            flagTemplate;
            context: { countryCode: address.countryCode }
          "
        ></ng-container>

        <div class="address-container">
          @if (address.street) {
            <p class="street">
              <span>{{ address.street }} {{ address.houseNumber }}</span>
              <span>
                {{
                  address.apartmentNumber ? '/' + address.apartmentNumber : ''
                }},
              </span>
            </p>
          }

          <span>{{ address.zipCode }} {{ address.city }}</span>
        </div>
      </div>
    }
    @case ('double-line') {
      <div class="double-line-address">
        <ng-container
          *ngTemplateOutlet="
            flagTemplate;
            context: { countryCode: address.countryCode }
          "
        ></ng-container>

        <div class="container">
          @if (address.street) {
            <span>{{ address.street }} {{ address.houseNumber }}</span>
          }
          <span>{{ address.zipCode }} {{ address.city }}</span>
        </div>
      </div>
    }
    @case ('city') {
      <div class="container">
        <span>{{ address.zipCode }} {{ address.city }}, </span>
        <span>{{ address.countryCode | uppercase }}</span>
      </div>
    }
    @case ('coordinates') {
      {{ coordinatesLabel() }}
    }
  }
} @else {
  -
}

<ng-template let-countryCode="countryCode" #flagTemplate>
  @if (showFlag()) {
    <onyx-flag
      [countryCode]="countryCode"
      [onyxTooltip]="DictionaryCode.COUNTRY + '.' + countryCode"
    ></onyx-flag>
  }
</ng-template>

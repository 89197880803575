<section>
  <onyx-information-heading>
    {{ 'labels.details' | translate }}
  </onyx-information-heading>

  @let author = order().author;
  <onyx-information-row label="labels.orderAuthor">
    <span>{{ author | onyxName }}</span>
  </onyx-information-row>

  <onyx-information-row label="labels.additionDate">N/A</onyx-information-row>

  @let outsourcingData = order().outsourcingData;
  @if (outsourcingData) {
    <onyx-information-heading>
      <div class="outsourcing-heading">
        <onyx-icon name="cursor" [size]="16"></onyx-icon>

        {{ 'labels.outsourcing' | translate }}
      </div>
    </onyx-information-heading>

    <div class="outsourcing">
      <div class="information-row">
        <div class="item f-paragraph-semibold-2">
          <p class="heading f-medium-3">{{ 'labels.sellRate' | translate }}</p>

          <app-amount-cell
            [amount]="{
              value: outsourcingData.rate.defaultCurrencyValue,
              currency: outsourcingData.rate.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>

        <div class="item f-paragraph-semibold-2">
          <p class="heading f-medium-3">{{ 'labels.margin' | translate }}</p>

          <app-amount-cell
            [amount]="{
              value: outsourcingData.marginRate,
              currency: outsourcingData.rate.defaultCurrency,
            }"
            [percentage]="outsourcingData.marginPercentage"
            [short]="false"
          ></app-amount-cell>
        </div>

        <div class="item f-paragraph-semibold-2">
          <p class="heading f-medium-3">
            {{ 'labels.daysPaymentTerms' | translate }}
          </p>

          {{ outsourcingData.paymentTerm | onyxDate: 'date' }}
        </div>
      </div>

      @if (outsourcingData.executionTerms; as executionTerms) {
        <onyx-data-widget
          supheading="orders.orderModal.outsourcingForm.executionTerms"
        >
          {{ executionTerms }}
        </onyx-data-widget>
      }

      @if (outsourcingData.note; as note) {
        <onyx-data-widget supheading="labels.officeNote">
          {{ note }}
        </onyx-data-widget>
      }
    </div>
  }

  @let vehicleRequirements = parameters().vehicleRequirements;
  @if (vehicleRequirements.length) {
    <onyx-information-heading>
      {{ 'labels.vehicleRequirements' | translate }}
    </onyx-information-heading>

    @for (requirement of vehicleRequirements; track requirement) {
      <onyx-information-row [label]="requirement.name | translate">
        @let value = $any(requirement.value);
        @if (value === true) {
          {{ 'buttons.yes' | translate }}
        } @else {
          @switch (requirement.key) {
            @case ('adrClasses') {
              @for (class of value; track class) {
                {{ DictionaryCode.ADR_CLASS + '.' + class | translate }}
              }
            }
            @case ('temperatureRange') {
              {{ value | onyxTemperature }}
            }
            @case ('minSemiTrailerSize') {
              <div class="trailer-requirements">
                <onyx-icon
                  [name]="semiTrailerSizeIcon()"
                  [size]="16"
                ></onyx-icon>

                {{
                  DictionaryCode.ORDER_SEMI_TRAILER_SIZE + '.' + value
                    | translate
                }}
              </div>
            }
            @case ('dischargeTypes') {
              {{ value | join: ', ' : 5 : DictionaryCode.DISCHARGE_TYPE }}
            }
            @case ('cargoSpace') {
              {{ value | join: ', ' : 5 : DictionaryCode.VEHICLE_TYPE }}
            }
            @default {
              {{ value }}
            }
          }
        }
      </onyx-information-row>
    }
  }

  @let driverRequirements = parameters().driverRequirements;
  @if (driverRequirements.length) {
    <onyx-information-heading>
      {{ 'labels.driverRequirements' | translate }}
    </onyx-information-heading>

    @for (requirement of driverRequirements; track requirement) {
      <onyx-information-row [label]="requirement.name | translate">
        @let value = $any(requirement.value);
        @if (value === true) {
          {{ 'buttons.yes' | translate }}
        } @else {
          {{ value | join: ', ' : 4 : DictionaryCode.LANGUAGE }}
        }
      </onyx-information-row>
    }
  }

  @let otherRequirements = parameters().otherRequirements;
  @if (otherRequirements.length) {
    <onyx-information-heading>
      {{ 'labels.otherRequirements' | translate }}
    </onyx-information-heading>

    @for (requirement of otherRequirements; track requirement) {
      <onyx-information-row [label]="requirement.name | translate">
        @let value = $any(requirement.value);
        @if (value === true) {
          {{ 'buttons.yes' | translate }}
        } @else {
          {{ value }}
        }
      </onyx-information-row>
    }
  }
</section>

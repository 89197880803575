import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DictionaryCode } from '../../enums/dictionary-code';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(
    items: string[],
    separator: string,
    maxLength: number | null,
    dictionaryCode: DictionaryCode | null,
  ): string {
    if (!items || !items.length) return '';

    const translatedItems = items.map((item) =>
      this.translateService.instant(
        dictionaryCode ? `${dictionaryCode}.${item}` : item,
      ),
    );

    if (!maxLength || items.length <= maxLength) {
      return translatedItems.join(separator);
    }

    const visibleItems = translatedItems.slice(0, maxLength).join(separator);
    const remainingCount = items.length - maxLength!;

    return `${visibleItems}, +${remainingCount}`;
  }
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { DashboardRoute } from '../../../dashboard/dashboard.routes';
import { AuthRoute } from '../../auth.routes';
import { AuthGuardAccess } from '../enums/auth-guard-access';
import { BusinessDocumentsStatus } from '../enums/business/business-documents-status';
import { AuthService } from '../services/auth.service';

export const authGuard = (access: AuthGuardAccess): CanActivateFn => {
  return () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    const user = authService.user();
    if (!user) {
      return [
        AuthGuardAccess.PUBLIC,
        AuthGuardAccess.PUBLIC_AND_AUTHORIZED,
      ].includes(access)
        ? true
        : router.createUrlTree([AuthRoute.LOGIN]);
    } else if (access === AuthGuardAccess.PUBLIC_AND_AUTHORIZED) {
      authService.logout(false);
      return true;
    }

    const business = authService.business();
    if (!business) {
      return access === AuthGuardAccess.REGISTER_COMPANY
        ? true
        : router.createUrlTree([AuthRoute.REGISTER_COMPANY]);
    }

    const documents = authService.documents();
    if (documents?.status !== BusinessDocumentsStatus.VERIFIED) {
      return access === AuthGuardAccess.REGISTER_DOCUMENTS
        ? true
        : router.createUrlTree([AuthRoute.REGISTER_DOCUMENTS]);
    }

    return router.createUrlTree([DashboardRoute.DASHBOARD]);
  };
};

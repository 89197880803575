import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { OnyxColor, OnyxTime } from '@onyx/angular';
import { DateTime } from 'luxon';
import { OrderPointStatus } from '../../common/enums/order-point-status';
import { OrderStatus } from '../../common/enums/order-status';
import { OrderHelper } from '../../common/helpers/order.helper';
import { Order } from '../../common/interfaces/order';
import { OrderPoint } from '../../common/interfaces/order-point';
import { OrderModalEtaComponent } from './order-modal-eta/order-modal-eta.component';
import { OrderModalLoadingDeadlineComponent } from './order-modal-loading-deadline/order-modal-loading-deadline.component';
import { OrderModalPointStatusComponent } from './order-modal-point-status/order-modal-point-status.component';
import { OrderModalPointTimeWindowsComponent } from './order-modal-point-time-windows/order-modal-point-time-windows.component';
import { OrderModalStatusComponent } from './order-modal-status/order-modal-status.component';
@Component({
  selector: 'app-order-modal-statuses',
  standalone: true,
  imports: [
    OrderModalStatusComponent,
    OrderModalLoadingDeadlineComponent,
    OrderModalEtaComponent,
    OrderModalPointStatusComponent,
    OrderModalPointTimeWindowsComponent,
    NgClass,
  ],
  templateUrl: './order-modal-statuses.component.html',
  styleUrl: './order-modal-statuses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalStatusesComponent {
  protected readonly OrderStatus = OrderStatus;
  protected readonly OrderHelper = OrderHelper;

  public order = input.required<Order>();

  protected hasApprovedVehicle = computed(() =>
    OrderHelper.hasApprovedVehicle(this.order().status.value),
  );

  protected point = computed(() => {
    const { points } = this.order();
    const getValidPoint = (point: OrderPoint) =>
      ![OrderPointStatus.COMPLETED, OrderPointStatus.CANCELED].includes(
        point.status,
      );

    const point = points.find(getValidPoint) ?? points.at(-1)!;
    const includeInOrder =
      'includeInOrder' in point ? point.includeInOrder : true;

    return includeInOrder
      ? point
      : (OrderHelper.getNextPoint(point, this.order()) ?? points.at(-1))!;
  });

  protected pointDeadline = computed(() => {
    const point = this.point();

    const deadline = OrderHelper.getDeadline(point, this.order())!;
    const date = deadline.toISODate()!;
    const time = deadline.toFormat('HH:mm:ss')!;

    return {
      dateTime: deadline,
      date,
      time: time as OnyxTime,
    };
  });

  protected pointEstimationDifference = computed(() => {
    const point = this.point();
    const pointEta = DateTime.fromISO(point.estimations!.estimatedArrivalTime);
    const pointDeadline = this.pointDeadline()!.dateTime;
    const diff = pointDeadline.diff(pointEta, ['days', 'hours', 'minutes']);
    const { days, hours, minutes } = diff;
    const totalMinutes = diff.as('minutes');
    const color = (
      totalMinutes < 0 ? 'red' : totalMinutes <= 30 ? 'yellow' : 'green'
    ) as OnyxColor;

    let time: string;

    if (days !== 0) {
      time = `${Math.abs(days)}d ${Math.abs(hours)}h`;
    } else if (hours !== 0) {
      time = `${Math.abs(hours)}h ${Math.abs(minutes)}min`;
    } else if (minutes !== 0) {
      time = `${Math.abs(minutes)}min`;
    } else {
      time = '';
    }

    return {
      diff: `${totalMinutes < 0 ? '+' : '-'}${time!}`,
      color,
    };
  });
}

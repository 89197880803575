import { BaseParameters } from '../interfaces/common/base-parameters';

export const BASE_PARAMETERS_KEYS: (keyof BaseParameters)[] = [
  'adrClasses',
  'hasHaccp',
  'hasHdsCrane',
  'hooksNumber',
  'hasCustomsSecuringRope',
  'matsNumber',
  'coilWellLength',
  'hasLoadingsRamps',
  'beltsNumber',
  'hasSanitaryInspection',
  'temperatureRange',
  'hasTailLift',
  'hasDumpContainer',
  'hasForklift',
  'other',
] as const;

@if (employementConditions(); as employementConditions) {
  <div class="contract-cell {{ cellColor() }}">
    <ng-container
      *ngTemplateOutlet="contractTemplate; context: { employementConditions }"
    ></ng-container>
  </div>

  <div class="hidden">
    <ng-container
      *ngTemplateOutlet="contractTemplate; context: { employementConditions }"
    ></ng-container>
  </div>
} @else {
  -
}

<ng-template
  let-employementConditions="employementConditions"
  #contractTemplate
>
  <div class="contract-container">
    @if (employementConditions.contractTerm?.indefinite) {
      <p>{{ 'labels.indefiniteTime' | translate }}</p>
    } @else {
      <app-date-range-cell
        [range]="employementConditions.contractTerm"
      ></app-date-range-cell>
    }

    <div class="information f-regular-3">
      <p>
        {{
          DictionaryCode.CONTRACT_TYPE +
            '.' +
            employementConditions.contractType | translate
        }}
      </p>

      <p>·</p>

      @switch (employementConditions.settlementMethod) {
        @case (DriverSettlementMethod.DAILY_RATE) {
          <p class="daily-rate">
            <app-amount-cell
              [amount]="employementConditions.dailyRate"
            ></app-amount-cell>

            <span>/{{ 'labels.daily' | translate | lowercase }}</span>
          </p>
        }
        @case (DriverSettlementMethod.MILEAGE_RATE) {
          <p class="milage-rate">
            <app-amount-cell
              [amount]="employementConditions.netMileagePatePerKm"
            ></app-amount-cell>

            <span>/km</span>
          </p>
        }
        @case (DriverSettlementMethod.BASE_PLUS_PERCENTAGE) {
          <p>
            <app-amount-cell
              [amount]="employementConditions.baseRate"
            ></app-amount-cell>

            <span>
              + {{ employementConditions.freightPercentage
              }}{{ 'labels.freightPercentage' | translate }}
            </span>
          </p>
        }
        @case (DriverSettlementMethod.ROUND_TRIP_RATE) {
          <p class="round-trip">
            <app-amount-cell
              [amount]="employementConditions.roundTripRate"
            ></app-amount-cell>

            <span>/{{ 'labels.circle' | translate }}</span>
          </p>
        }
      }
    </div>
  </div>
</ng-template>

import { Paths } from 'type-fest';
import { Fleet } from '../interfaces/fleet';

export const FLEET_DUPLICATE_OMIT_KEYS: Paths<Fleet>[] = [
  'generalInformation.registrationNumber',
  'generalInformation.sideNumber',
  'generalInformation.vinNumber',
  'licensesAndPermits.communityLicenseAbstractNumber',
  'licensesAndPermits.permitAbstractNumber',
  'registrationCertificate.scan',
  'co2Emissions.emissionCertificate',
  'thirdPartyLiabilityInsurance.insuranceNumber',
  'comprehensiveInsurance.insuranceNumber',
] as const;

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OnyxModalService, OnyxOptionsGroup } from '@onyx/angular';
import { chain, isObject, isString } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { Amount } from '../../../../common/interfaces/common/amount';
import { HelperOptions } from '../../../../common/interfaces/utilities/helper-options';
import { FleetCategory } from '../../../fleet/common/enums/fleet-category';
import { SemiTrailerSize } from '../../../fleet/common/enums/semi-trailer-size';
import { OrderForm } from '../../order-form/order-form.component';
import {
  OrderModalComponent,
  OrderModalData,
} from '../../order-modal/order-modal.component';
import { OrdersRoute } from '../../orders.routes';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderRateType } from '../enums/order-rate-type';
import { OrderSemiTrailerSize } from '../enums/order-semi-trailer-size';
import { OrderStatus } from '../enums/order-status';
import { OrderTimeWindowType } from '../enums/order-time-window-type';
import { OrderTransitPointTimeWindowType } from '../enums/order-transit-point-time-window-type';
import { Order, SimplifiedOrder } from '../interfaces/order';
import { OrderLoadingPoint, OrderPoint } from '../interfaces/order-point';

@Injectable({
  providedIn: 'root',
})
export class OrderHelper {
  constructor(
    private router: Router,
    private modalService: OnyxModalService,
  ) {}

  public getOptions(
    order: Order,
    options: HelperOptions,
  ): OnyxOptionsGroup<() => void>[] {
    const hasEdit = OrderHelper.hasEdit(order);
    return [
      {
        options: [
          ...(hasEdit
            ? [
                {
                  name: 'buttons.edit',
                  value: () => this.edit(order.uuid, options),
                },
              ]
            : []),
        ],
      },
    ].filter((group) => group.options.length !== 0);
  }

  public openModal(order: OrderModalData): void {
    this.modalService.open<OrderModalData>(OrderModalComponent, order);
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(
      `${OrdersRoute.EDIT_ORDER.replace(':uuid', uuid)}`,
    );
    CommonHelper.handleOptions(uuid, options);
  }

  public static getFirstLoadingPoint(points: OrderPoint[]): OrderLoadingPoint {
    return points.find(
      (point) => point.category === OrderPointCategory.LOADING,
    )!;
  }

  public static getLastUnloadingPoint(points: OrderPoint[]) {
    return points
      .reverse()
      .find((point) => point.category === OrderPointCategory.UNLOADING)!;
  }

  public static getLoadingPointDeadline(
    loadingPoint: OrderLoadingPoint,
  ): string {
    const { type, windows } = loadingPoint.timeWindows;
    if (type === OrderTimeWindowType.FIX) {
      return `${windows[0].date}T${windows[0].time}`;
    }

    const lastTimeWindow = windows[windows.length - 1];
    return `${lastTimeWindow.date}T${lastTimeWindow.timeRange.to}`;
  }

  public static hasApprovedVehicle(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return ![OrderStatus.VEHICLE_SEARCH, OrderStatus.TO_ACCEPT].includes(
      status,
    );
  }

  public static isOrderPending(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return [
      OrderStatus.VEHICLE_SEARCH,
      OrderStatus.TO_DO,
      OrderStatus.TO_ACCEPT,
    ].includes(status);
  }

  public static getEmptyDistance(order: Order): number | null {
    return (
      order.realizationData?.emptyDistance ??
      order.assignedData?.emptyDistance ??
      order.manualProposalData?.emptyDistance ??
      order.engineProposalData?.emptyDistance ??
      null
    );
  }

  public static getEmptyDistanceTime(order: Order): number | null {
    return (
      order.realizationData?.emptyDistanceTime ??
      order.assignedData?.emptyDistanceTime ??
      order.manualProposalData?.emptyDistanceTime ??
      order.engineProposalData?.emptyDistanceTime ??
      null
    );
  }

  public static getDistance(order: Order): number {
    return order.realizationData?.distance ?? order.route.distance;
  }

  public static getDistanceTime(order: Order): number {
    return order.realizationData?.distanceTime ?? order.route.distanceTime;
  }

  public static getTotalPrice(order: Order): Amount {
    const rateType = order.basicInformation.rateType;
    const price = order.price;
    const realizationPrice = order?.realizationData?.price;

    return {
      [OrderRateType.FREIGHT]: price,
      [OrderRateType.TONNE]: price,
      [OrderRateType.EVERY_KILOMETER]: realizationPrice ?? price,
      [OrderRateType.LOADED_KILOMETER]: realizationPrice ?? price,
      [OrderRateType.CUBIC_METER]: price,
    }[rateType];
  }

  public static getTotalDistance(order: Order): number {
    const emptyDistance = OrderHelper.getEmptyDistance(order);
    const distance = OrderHelper.getDistance(order);

    return (distance + (emptyDistance ?? 0)) / 1000;
  }

  public static getRatePerKm(order: Order): number {
    return (
      OrderHelper.getTotalPrice(order).defaultCurrencyValue /
      OrderHelper.getTotalDistance(order)
    );
  }

  public static getPricePerDay(order: Order): number {
    const orderPrice = OrderHelper.getTotalPrice(order);
    const distanceTime = OrderHelper.getDistanceTime(order);
    const emptyDistanceTime = OrderHelper.getEmptyDistanceTime(order);

    const days = Duration.fromObject({
      seconds: distanceTime + (emptyDistanceTime ?? 0),
    }).as('days');

    return orderPrice.defaultCurrencyValue / days;
  }

  public static getSearchingTrailerTypes(order: Order): string[] | null {
    const basicInformation = order.basicInformation;
    if (
      basicInformation.vehicleTypes.every((type) => type.startsWith('van-'))
    ) {
      return null;
    }

    return basicInformation.vehicleTypes;
  }

  public static getSearchingVehicleData(
    order: Order,
    translateService: TranslateService,
  ) {
    const basicInformation = order.basicInformation;

    const areAllVans = basicInformation.vehicleTypes.every((type) =>
      type.startsWith('van-'),
    );
    const parameters = CommonHelper.getSortedParameters(
      order.parameters,
      translateService,
    );

    if (areAllVans) {
      return {
        iconName: 'van' as const,
        category: FleetCategory.VAN,
        isLowDeck: null,
        types: basicInformation.vehicleTypes,
        parameters,
      };
    }

    const {
      loadedSemiTrailer,
      loadedSemiTrailerRegistrationNumber,
      minSemiTrailerSize,
    } = basicInformation;
    if (
      loadedSemiTrailer ||
      loadedSemiTrailerRegistrationNumber ||
      minSemiTrailerSize !== OrderSemiTrailerSize.AUTO
    ) {
      const isLowDeck =
        loadedSemiTrailer?.additionalParameters?.semiTrailerSize ===
          SemiTrailerSize.MEGA ||
        minSemiTrailerSize === OrderSemiTrailerSize.MEGA;

      return {
        iconName: 'semi-truck' as const,
        category: FleetCategory.SEMI_TRUCK,
        isLowDeck,
        types: null,
        parameters,
      };
    }

    return {
      iconName: 'straight-truck' as const,
      category: FleetCategory.STRAIGHT_TRUCK,
      isLowDeck: null,
      types: null,
      parameters: null,
    };
  }

  public static fromDto(dto: Order): OrderForm {
    return {
      basicInformation: {
        ...dto.basicInformation,
        sender: dto.basicInformation.sender.uuid,
        ourBranch: dto.basicInformation.ourBranch.uuid,
        senderBranch: dto.basicInformation.senderBranch.uuid,
        loadedSemiTrailer:
          dto.basicInformation.loadedSemiTrailer?.uuid ??
          dto.basicInformation.loadedSemiTrailerRegistrationNumber ??
          null,
      },
      parameters: {
        ...dto.parameters,
        temperatureRange: {
          from: dto.parameters.temperatureRange?.from ?? null,
          to: dto.parameters.temperatureRange?.to ?? null,
        },
        other: dto.parameters.other?.map((parameter) => parameter.uuid) ?? null,
      },
      documents: dto.documents,
      note: { content: dto.note?.content ?? null },
      points: dto.points.map((point) => {
        if (point.category !== OrderPointCategory.LOADING) return point;
        return {
          ...point,
          goods: point.goods.map((good) => ({
            ...good,
            type: isObject(good.type) ? good.type.uuid : good.type,
            unit: good.unit.uuid,
          })),
        };
      }),
    };
  }

  public static hasEdit(order: Order | OrderStatus): boolean {
    const status = isString(order) ? order : order.status.value;
    return ![OrderStatus.CANCELED, OrderStatus.ARCHIVED].includes(status);
  }

  public static isOrderType(order: SimplifiedOrder | Order): order is Order {
    return 'status' in order;
  }

  public static getNextPoint(
    point: OrderPoint,
    order: Order,
  ): OrderPoint | null {
    const orderPoints = order.points;
    const pointIndex = orderPoints.findIndex(
      (orderPoint) => orderPoint.uuid === point.uuid,
    );
    if (pointIndex === -1) return null;

    return orderPoints.at(pointIndex + 1) ?? null;
  }

  public static getDeadline(point: OrderPoint, order: Order): DateTime | null {
    const estimatedDeadline = point.estimations?.estimatedDeadlineTime;
    if (estimatedDeadline) return DateTime.fromISO(estimatedDeadline);

    const getNext = (): [OrderPoint, DateTime] | [null, null] => {
      const nextPoint = OrderHelper.getNextPoint(point, order);
      if (!nextPoint) return [null, null];

      const nextDeadline = OrderHelper.getDeadline(nextPoint, order);
      if (!nextDeadline) return [null, null];

      return [nextPoint, nextDeadline];
    };

    switch (point.category) {
      case OrderPointCategory.CHECKPOINT: {
        const [_, nextDeadline] = getNext();
        if (!nextDeadline) return null;

        return chain(nextDeadline)
          .thru((nextDeadline) =>
            Array.from({ length: 4 }).map((_, index) =>
              nextDeadline.minus({ days: index }),
            ),
          )
          .map((date) => {
            let openingHours;
            if (date.weekday === 7) {
              openingHours = point.businessHours.sunday;
            } else if (date.weekday === 6) {
              openingHours = point.businessHours.saturday;
            } else {
              openingHours = point.businessHours.weekday;
            }
            if (!openingHours) return null;

            const timeFrom = DateTime.fromISO(openingHours.from);
            const timeTo = DateTime.fromISO(openingHours.to);

            const from = date.set({
              hour: timeFrom.hour,
              minute: timeFrom.minute,
            });
            const to = date.set({
              hour: timeTo.hour,
              minute: timeTo.minute,
            });

            return { from, to };
          })
          .filter((interval) => interval != null)
          .thru(
            (intervals) =>
              intervals.find(
                (interval) =>
                  interval.to >=
                  DateTime.now()
                    .plus({ seconds: point.route?.distanceTime ?? 0 })
                    .plus({
                      seconds: CommonHelper.parseTimeToSeconds(
                        point.serviceTime,
                      ),
                    }),
              ) ?? intervals.at(-1),
          )
          .thru((interval) =>
            interval.to.minus({
              seconds: CommonHelper.parseTimeToSeconds(point.serviceTime),
            }),
          )
          .value();
      }

      case OrderPointCategory.TRANSIT: {
        const timeWindow = point.timeWindow;

        if (timeWindow.type === OrderTransitPointTimeWindowType.ALL_TIME) {
          const [nextPoint, nextDeadline] = getNext();
          if (!nextDeadline) return null;

          return nextDeadline
            .minus({ seconds: nextPoint.route?.distanceTime ?? 0 })
            .minus({
              seconds: CommonHelper.parseTimeToSeconds(point.serviceTime),
            });
        }

        return DateTime.fromISO(`${timeWindow.date}T${timeWindow.time}`);
      }

      default: {
        const timeWindows = point.timeWindows;

        if (
          timeWindows.type === OrderTimeWindowType.INTERVAL ||
          timeWindows.type === OrderTimeWindowType.OPENING_HOURS
        ) {
          return chain(timeWindows.windows)
            .map((interval) =>
              DateTime.fromISO(`${interval.date}T${interval.timeRange.to}`),
            )
            .thru(
              (intervals) =>
                intervals.find(
                  (interval) =>
                    interval >=
                    DateTime.now()
                      .plus({ seconds: point.route?.distanceTime ?? 0 })
                      .plus({
                        seconds: CommonHelper.parseTimeToSeconds(
                          point.serviceTime,
                        ),
                      }),
                ) ?? intervals.at(-1),
            )
            .thru((interval) =>
              interval.minus({
                seconds: CommonHelper.parseTimeToSeconds(point.serviceTime),
              }),
            )
            .value();
        }

        return DateTime.fromISO(
          `${timeWindows.windows[0].date}T${timeWindows.windows[0].time}`,
        );
      }
    }
  }
}

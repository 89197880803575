import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxDatePipe,
  OnyxTime,
  OnyxTimePipe,
  OnyxTimeRange,
  OnyxTooltipDirective,
  OnyxWidgetBoxComponent,
} from '@onyx/angular';
import { BusinessHoursPipe } from '../../../../../common/components/pipes/business-hours.pipe';
import { TIME_RANGE_ALL_DAY } from '../../../../../common/constants/time-range-all-day';
import { BusinessHours } from '../../../../../common/interfaces/common/business-hours';
import { OrderPointCategory } from '../../../common/enums/order-point-category';
import { OrderTimeWindowType } from '../../../common/enums/order-time-window-type';
import { OrderTransitPointTimeWindowType } from '../../../common/enums/order-transit-point-time-window-type';
import { OrderPoint } from '../../../common/interfaces/order-point';

@Component({
  selector: 'app-order-modal-point-time-windows',
  standalone: true,
  imports: [OnyxWidgetBoxComponent, OnyxTooltipDirective],
  providers: [OnyxTimePipe, OnyxDatePipe, BusinessHoursPipe],
  templateUrl: './order-modal-point-time-windows.component.html',
  styleUrl: './order-modal-point-time-windows.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPointTimeWindowsComponent {
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  public point = input.required<OrderPoint>();

  protected pointTimeWindows = computed(() => {
    const point = this.point();

    const formatTimeWindow = (date: string, time: OnyxTime | OnyxTimeRange) =>
      `${this.datePipe.transform(date, 'short-date')} · ${this.timePipe.transform(time)}`;

    const formatBusinessHours = (businessHours: BusinessHours) => [
      this.businessHoursPipe.transform(businessHours, 'weekday'),
      this.businessHoursPipe.transform(businessHours, 'saturday'),
      this.businessHoursPipe.transform(businessHours, 'sunday'),
    ];

    switch (point.category) {
      case OrderPointCategory.CHECKPOINT: {
        const businessHours = point.businessHours;
        const isAllTime = Object.values(businessHours).every(
          (range) => range === TIME_RANGE_ALL_DAY,
        );

        return {
          label: 'labels.openingHours',
          value: isAllTime ? ['24/7'] : formatBusinessHours(businessHours),
        };
      }

      case OrderPointCategory.TRANSIT: {
        return {
          label: 'labels.fix',
          value:
            point.timeWindow.type === OrderTransitPointTimeWindowType.ALL_TIME
              ? ['24/7']
              : [
                  formatTimeWindow(
                    point.timeWindow.date,
                    point.timeWindow.time,
                  ),
                ],
        };
      }

      default: {
        if (point.timeWindows.type === OrderTimeWindowType.FIX) {
          return {
            label: 'labels.fix',
            value: [
              formatTimeWindow(
                point.timeWindows.windows[0].date,
                point.timeWindows.windows[0].time,
              ),
            ],
          };
        }

        const isInterval =
          point.timeWindows.type === OrderTimeWindowType.INTERVAL;
        return {
          label: isInterval ? 'labels.interval' : 'labels.openingHours',
          value: point.timeWindows.windows.map((timeWindow) =>
            formatTimeWindow(timeWindow.date, timeWindow.timeRange),
          ),
        };
      }
    }
  });

  constructor(
    private timePipe: OnyxTimePipe,
    private datePipe: OnyxDatePipe,
    private businessHoursPipe: BusinessHoursPipe,
  ) {}
}

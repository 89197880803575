@if (loading()) {
  <section class="loading-section">
    @if (showLoading()) {
      <onyx-spinner color="blue" [size]="48"></onyx-spinner>
    }
  </section>
} @else if (error()) {
  <section class="loading-section">
    <onyx-error [(error)]="error" (errorChange)="sender$.next()"></onyx-error>
  </section>
} @else {
  @if (sender(); as sender) {
    <section>
      <div class="heading">
        <h3>{{ sender.companyProfile.displayName }}</h3>

        <onyx-icon-button
          type="transparent"
          color="black"
          size="l"
          (click)="openContractorModal()"
        >
          <onyx-icon name="side-modal" [size]="16"></onyx-icon>
        </onyx-icon-button>
      </div>

      <onyx-information-row label="labels.contractorType">
        {{
          contractorTypes() | join: ', ' : null : DictionaryCode.CONTRACTOR_TYPE
        }}
      </onyx-information-row>

      @let senderBranch = order().basicInformation.senderBranch;
      <onyx-information-row label="labels.branch">
        {{ senderBranch.name }}
      </onyx-information-row>

      <onyx-information-row label="labels.branchAddress">
        <app-address
          [address]="senderBranch.correspondenceAddress"
          format="full"
        ></app-address>
      </onyx-information-row>

      <onyx-information-row label="labels.vatId">
        {{ sender.companyProfile.vatId }}
      </onyx-information-row>

      <app-contractor-contacts [contractor]="sender"></app-contractor-contacts>

      <onyx-information-heading>
        {{ 'labels.payments' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.usedTradeCredit">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.daysPaymentTerms">
        {{
          'daysNumber'
            | onyxPluralTranslate: sender.payments.paymentTermDays
            | translate: { days: sender.payments.paymentTermDays }
        }}
      </onyx-information-row>

      <onyx-information-row label="labels.timeliness">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.discount"> N/A </onyx-information-row>

      <onyx-information-row label="labels.factoringConsent">
        N/A
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.documents' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.mailShipping">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.internalPortalShipping">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.snailMailShipping">
        N/A
      </onyx-information-row>

      <onyx-information-heading>
        {{ 'labels.invoices' | translate }}
      </onyx-information-heading>

      <onyx-information-row label="labels.invoicesType">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.mailShipping">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.internalPortalShipping">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.snailMailShipping">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.mainCurrency">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.secondaryCurrency">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.mainLanguage">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.secondaryLanguage">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.customSalesDocumentFormat">
        N/A
      </onyx-information-row>

      <onyx-information-row label="labels.customNumbering">
        N/A
      </onyx-information-row>
    </section>
  }
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDatePipe,
  OnyxDotComponent,
  OnyxLinkComponent,
  OnyxToastService,
} from '@onyx/angular';
import { DictionaryCode } from '../../../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { EmployeeStatus } from '../../enums/employee-status';
import { Employee } from '../../interfaces/employee';
import { EmployeesService } from '../../services/employees.service';

@Component({
  selector: 'app-employee-status',
  imports: [OnyxDotComponent, OnyxLinkComponent, OnyxDatePipe, TranslatePipe],
  templateUrl: './employee-status.component.html',
  styleUrl: './employee-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeStatusComponent {
  protected readonly EmployeeStatus = EmployeeStatus;
  protected readonly DictionaryCode = DictionaryCode;

  public employee = input.required<Employee>();
  public displayStatus = input(false);

  constructor(
    private employeesService: EmployeesService,
    private toastService: OnyxToastService,
  ) {}

  protected resendInvite(): void {
    this.employeesService.reinviteEmployee(this.employee().uuid).subscribe({
      next: () => {
        this.toastService.showSuccess('employees.toasts.invitationSentAgain');
      },
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxAvatarComponent,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { FleetHelper } from '../../../../../fleet/common/helpers/fleet.helper';
import { Fleet } from '../../../../../fleet/common/interfaces/fleet';
import { Employee } from '../../interfaces/employee';
import { EmployeesService } from '../../services/employees.service';

export type EmployeeAssignVehiclesModalData = Employee;

@Component({
  selector: 'app-employee-assign-vehicles-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxAvatarComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './employee-assign-vehicles-modal.component.html',
  styleUrl: './employee-assign-vehicles-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeAssignVehiclesModalComponent {
  protected readonly I18N = 'employees.assignVehiclesModal';
  protected readonly VEHICLES_SOURCE = this.fleetHelper.getVehiclesSource();

  protected form = this.buildForm();
  protected loading = signal(false);
  protected vehicle = signal<Fleet | null>(null);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected employee: EmployeeAssignVehiclesModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private employeesService: EmployeesService,
    private fleetHelper: FleetHelper,
  ) {}

  protected openFleetModal(fleet: Fleet | null): void {
    if (fleet) this.fleetHelper.openModal(fleet);
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    this.loading.set(true);
    this.employeesService
      .assignVehicles(this.employee.uuid, form.vehicles)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.assignedVehicles`);
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      vehicles: this.fb.control<string[]>(this.employee.assignedVehicles),
    });
  }
}

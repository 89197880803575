@let isTimeEstimated =
  firstPointTimestamp().isEstimated || lastPointTimestamp().isEstimated;
<onyx-data-widget
  [supheading]="
    I18N + (isTimeEstimated ? '.estimatedDuration' : '.maxDuration')
  "
  [headingIcon]="{ name: 'clock', size: 16, color: 'green' }"
>
  <p heading>
    {{
      this.firstPointTimestamp().full
        | duration: 'from' : this.lastPointTimestamp().full : 'm'
    }}
  </p>

  <div class="list">
    <div class="item f-medium-3">
      <p>
        {{
          'labels' + (firstPointTimestamp().isEstimated ? '.etaStart' : '.from')
            | translate
        }}
      </p>

      <div class="date">
        {{ firstPointTimestamp().date | onyxDate: 'short-date-dot' }}

        <span class="gray">·</span>

        <span class="gray">
          {{ firstPointTimestamp().time | onyxTime }}
        </span>
      </div>
    </div>

    <div class="item f-medium-3">
      <p>
        {{ 'labels' + (isTimeEstimated ? '.etaFinish' : '.to') | translate }}
      </p>

      <div class="date">
        {{ lastPointTimestamp().date | onyxDate: 'short-date-dot' }}

        <span class="gray">·</span>

        <span class="gray">
          {{ lastPointTimestamp().time | onyxTime }}
        </span>
      </div>
    </div>
  </div>
</onyx-data-widget>

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDate,
  OnyxDatePipe,
  OnyxDatepickerComponent,
  OnyxDropdownComponent,
  OnyxFormMode,
  OnyxModalComponent,
  OnyxToastService,
  OnyxUploadComponent,
  onyxDateValidator,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { Driver } from '../../../../dashboard/drivers/common/interfaces/driver';
import { DriversService } from '../../../../dashboard/drivers/common/services/drivers.service';
import { Employee } from '../../../../dashboard/management-panel/employees/common/interfaces/employee';
import { EmployeesService } from '../../../../dashboard/management-panel/employees/common/services/employees.service';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { ValidationHelper } from '../../../helpers/validation.helper';
import { Unavailability } from '../../../interfaces/common/unavailability';
import { DictionariesService } from '../../../services/dictionaries.service';
import { UnavailabilityType } from '../unavailabilities.component';

export type UnavailabilityModalData = (
  | { type: UnavailabilityType.EMPLOYEE; item: Employee }
  | { type: UnavailabilityType.DRIVER; item: Driver }
) &
  (
    | { mode: OnyxFormMode.ADD }
    | {
        mode: OnyxFormMode.EDIT;
        unavailability: Unavailability;
      }
  );

export type UnavailabilityForm = ReturnType<
  ReturnType<UnavailabilityModalComponent['buildForm']>['getRawValue']
>;

@Component({
  selector: 'app-unavailability-modal',
  imports: [
    OnyxModalComponent,
    OnyxDatepickerComponent,
    OnyxButtonComponent,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxDropdownComponent,
    OnyxUploadComponent,
    AsyncPipe,
  ],
  templateUrl: './unavailability-modal.component.html',
  styleUrl: './unavailability-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailabilityModalComponent {
  protected readonly I18N = 'unavailabilities';

  protected readonly OnyxFormMode = OnyxFormMode;
  protected readonly UnavailabilityType = UnavailabilityType;

  protected readonly unavailabilityReasons$ =
    this.dictionariesService.getDictionary(
      this.data.type === UnavailabilityType.EMPLOYEE
        ? DictionaryCode.EMPLOYEE_UNAVAILABILITY_REASON
        : DictionaryCode.DRIVER_UNAVAILABILITY_REASON,
    );

  protected form = this.buildForm();
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected data: UnavailabilityModalData,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private employeesService: EmployeesService,
    private driversService: DriversService,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private onyxDatePipe: OnyxDatePipe,
  ) {
    if (this.data.mode === OnyxFormMode.EDIT) {
      const { dateRange, reason, file } = this.data.unavailability;
      this.form.setValue({
        dateRange: dateRange,
        reason,
        file,
      });
    }
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const action$ =
      this.data.mode === OnyxFormMode.ADD
        ? this.data.type === UnavailabilityType.EMPLOYEE
          ? this.employeesService.addEmployeeUnavailability(
              this.data.item.uuid,
              form,
            )
          : this.driversService.addDriverUnavailability(
              this.data.item.uuid,
              form,
            )
        : this.data.type === UnavailabilityType.EMPLOYEE
          ? this.employeesService.editEmployeeUnavailability(
              this.data.item.uuid,
              this.data.unavailability.uuid,
              form,
            )
          : this.driversService.editDriverUnavailability(
              this.data.item.uuid,
              this.data.unavailability.uuid,
              form,
            );

    this.loading.set(true);
    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess('labels.updatedData');
          this.close$.next();
        },
        error: (response: HttpErrorResponse) => {
          if (
            response.status === HttpStatusCode.BadRequest &&
            (response.error.from || response.error.to) === 'Conflicting overlap'
          ) {
            const { from, to } = this.form.controls.dateRange.value!;
            const dateRange = this.onyxDatePipe.transform(
              from!.toString(),
              'date',
              to!.toString(),
            );

            this.toastService.showError(
              this.translateService.instant(`${this.I18N}.conflictingOverlap`, {
                dateRange,
              }),
            );
          } else {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
          }
        },
      })
      .add(() => this.loading.set(false));
  }

  protected delete(): void {
    if (this.data.mode !== OnyxFormMode.EDIT) return;

    const action$ = {
      [UnavailabilityType.EMPLOYEE]:
        this.employeesService.deleteEmployeeUnavailability(
          this.data.item.uuid,
          this.data.unavailability.uuid,
        ),
      [UnavailabilityType.DRIVER]:
        this.driversService.deleteDriverUnavailability(
          this.data.item.uuid,
          this.data.unavailability.uuid,
        ),
    }[this.data.type];

    this.loading.set(true);
    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess('labels.updatedData');
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      dateRange: this.fb.control<OnyxDate | null>(null, [
        Validators.required,
        onyxDateValidator,
      ]),
      reason: this.fb.control<string | null>(null, [Validators.required]),
      file: this.fb.control<File | null>(null),
    });
  }
}

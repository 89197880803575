import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxDatePipe,
  OnyxTime,
  OnyxTimePipe,
} from '@onyx/angular';
import { DurationPipe } from '../../../../../common/components/pipes/duration.pipe';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-duration',
  standalone: true,
  imports: [
    OnyxDataWidgetComponent,
    TranslatePipe,
    DurationPipe,
    OnyxDatePipe,
    OnyxTimePipe,
  ],
  templateUrl: './order-modal-plan-duration.component.html',
  styleUrl: './order-modal-plan-duration.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanDurationComponent {
  protected readonly I18N = 'orders.orderModal.plan';

  public order = input.required<Order>();

  protected firstPointTimestamp = computed(() => {
    const points = this.order().points;
    const firstPoint = points[0];

    if (firstPoint.estimations?.estimatedArrivalTime) {
      const [date, time] =
        firstPoint.estimations.estimatedArrivalTime.split('T');
      return {
        date,
        time: time as OnyxTime,
        full: firstPoint.estimations.estimatedArrivalTime,
        isEstimated: true,
      };
    }

    const firstPointStartedAt = firstPoint.timestamps.startedAt;
    if (firstPointStartedAt) {
      const [date, time] = firstPointStartedAt.split('T');
      return {
        date,
        time: time as OnyxTime,
        full: firstPointStartedAt,
        isEstimated: false,
      };
    }

    const firstPointDeadline = OrderHelper.getDeadline(
      firstPoint,
      this.order(),
    )!;
    const date = firstPointDeadline.toISODate()!;
    const time = firstPointDeadline.toFormat('HH:mm:ss')! as OnyxTime;

    return {
      date,
      time,
      full: `${date}T${time}`,
      isEstimated: false,
    };
  });

  protected lastPointTimestamp = computed(() => {
    const points = this.order().points;
    const lastPoint = points.at(-1)!;
    if (lastPoint.estimations?.estimatedArrivalTime) {
      const [date, time] =
        lastPoint.estimations.estimatedArrivalTime.split('T');
      return {
        date,
        time: time as OnyxTime,
        full: lastPoint.estimations.estimatedArrivalTime,
        isEstimated: true,
      };
    }

    const lastPointDedaline = OrderHelper.getDeadline(lastPoint, this.order())!;
    const date = lastPointDedaline.toISODate()!;
    const time = lastPointDedaline.toFormat('HH:mm:ss') as OnyxTime;

    return {
      date,
      time,
      full: `${date}T${time}`,
      isEstimated: false,
    };
  });
}

@if (order().outsourcingData; as outsourcing) {
  <onyx-information-heading>
    <div class="outsourcing-heading">
      <onyx-icon name="cursor" [size]="16"></onyx-icon>

      {{ 'labels.outsourcing' | translate }}
    </div>
  </onyx-information-heading>

  @let loadedSemiTrailer = order().basicInformation.loadedSemiTrailer;
  @let trailerRegistrationNumber = outsourcing.trailerRegistrationNumber;
  <div
    class="outsourcing-content"
    [ngClass]="{
      single: !trailerRegistrationNumber,
      extended: loadedSemiTrailer,
    }"
  >
    <onyx-data-widget
      [supheading]="
        trailerRegistrationNumber
          ? DictionaryCode.VEHICLE_CATEGORY + '.semi-truck'
          : 'labels.vehicle'
      "
    >
      <h4 heading>{{ outsourcing.vehicleRegistrationNumber }}</h4>
    </onyx-data-widget>

    @if (trailerRegistrationNumber) {
      <onyx-data-widget
        [supheading]="DictionaryCode.TRAILER_CATEGORY + '.semi-trailer'"
      >
        <h4 heading>{{ trailerRegistrationNumber }}</h4>
      </onyx-data-widget>
    } @else if (loadedSemiTrailer) {
      @if (trailerLoading()) {
        <section class="loading-section">
          @if (showLoading()) {
            <onyx-spinner color="blue" [size]="48"></onyx-spinner>
          }
        </section>
      } @else if (trailerError()) {
        <section class="loading-section">
          <onyx-error
            [(error)]="trailerError"
            (errorChange)="trailer$.next()"
          ></onyx-error>
        </section>
      } @else {
        <app-fleet-modal-assigned-vehicle
          [vehicle]="trailer()"
          [isVehicle]="true"
        ></app-fleet-modal-assigned-vehicle>
      }
    }
  </div>
} @else if (order().assignedData) {
  <section>
    @if (order().assignedData?.vehicle) {
      @if (vehicleLoading()) {
        <section class="loading-section">
          @if (showLoading()) {
            <onyx-spinner color="blue" [size]="48"></onyx-spinner>
          }
        </section>
      } @else if (vehicleError()) {
        <section class="loading-section">
          <onyx-error
            [(error)]="vehicleError"
            (errorChange)="vehicle$.next()"
          ></onyx-error>
        </section>
      } @else {
        <app-fleet-modal-assigned-vehicle
          [vehicle]="vehicle()"
          [isVehicle]="false"
        ></app-fleet-modal-assigned-vehicle>
      }
    } @else {
      <div class="vehicle-disconnected">
        <p class="f-medium-1">
          {{ DictionaryCode.VEHICLE_CATEGORY + '.semi-truck' | translate }}
        </p>

        <div class="content">
          <onyx-icon name="vehicle-disconnected-full" [size]="16"></onyx-icon>

          <h3>{{ 'labels.vehicleDisconnected' | translate }}</h3>
        </div>
      </div>
    }

    @if (
      this.order().assignedData?.trailer ||
      this.order().basicInformation.loadedSemiTrailer
    ) {
      @if (trailerLoading()) {
        <section class="loading-section">
          @if (showLoading()) {
            <onyx-spinner color="blue" [size]="48"></onyx-spinner>
          }
        </section>
      } @else if (trailerError()) {
        <section class="loading-section">
          <onyx-error
            [(error)]="trailerError"
            (errorChange)="trailer$.next()"
          ></onyx-error>
        </section>
      } @else {
        <app-fleet-modal-assigned-vehicle
          [vehicle]="trailer()"
          [isVehicle]="true"
        ></app-fleet-modal-assigned-vehicle>
      }
    } @else if (order().basicInformation.loadedSemiTrailerRegistrationNumber) {
      <div class="loaded-semi-trailer">
        <onyx-information-heading>
          {{ 'orders.ordersList.cells.loadedForeignTrailer' | translate }}
        </onyx-information-heading>

        <onyx-data-widget
          [supheading]="
            DictionaryCode.TRAILER_CATEGORY + '.' + FleetCategory.SEMI_TRAILER
          "
        >
          <h4 heading>
            {{ order().basicInformation.loadedSemiTrailerRegistrationNumber }}
          </h4>
        </onyx-data-widget>
      </div>
    }
  </section>
} @else {
  <onyx-information-heading>
    {{ 'bases.assignedVehicles.noVehicles' | translate }}
  </onyx-information-heading>

  <app-order-modal-vehicles
    [order]="order()"
    [showHeading]="false"
  ></app-order-modal-vehicles>
}

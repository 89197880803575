@if (badges(); as badges) {
  <div class="roles-cell">
    @for (badge of badges; track badge.value) {
      <onyx-badge [color]="badge.color" size="m">
        {{ dictionaryCode() + '.' + badge.value | translate }}
      </onyx-badge>
    }
  </div>
} @else {
  -
}

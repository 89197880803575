import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxLinkComponent } from '@onyx/angular';
import { ContractorHelper } from '../../../management-panel/contractors/common/helpers/contractor.helper';
import { SimplifiedContractor } from '../../../management-panel/contractors/common/interfaces/contractor';
import { Order } from '../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-heading',
  standalone: true,
  imports: [OnyxLinkComponent, TranslatePipe],
  templateUrl: './order-modal-heading.component.html',
  styleUrl: './order-modal-heading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalHeadingComponent {
  public order = input.required<Order>();

  constructor(private contractorHelper: ContractorHelper) {}

  protected openContractorModal(contractor: SimplifiedContractor): void {
    this.contractorHelper.openModal(contractor);
  }
}

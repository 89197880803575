import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  PositionStrategy,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ElementRef, Injectable } from '@angular/core';
import { OnyxBaseOverlayService } from './onyx-base-overlay.service';

@Injectable()
export class OnyxOverlayService extends OnyxBaseOverlayService {
  private _sourceRef?: ElementRef;

  public get sourceNativeElement() {
    return this._sourceRef?.nativeElement;
  }

  public get overlayElementRect() {
    return this.overlayRef()?.overlayElement.getBoundingClientRect();
  }

  public get outsidePointerEvents$() {
    return this.overlayRef()?.outsidePointerEvents();
  }

  public get scrollStrategies() {
    return this.overlay.scrollStrategies;
  }

  public static get positionStrategyTop(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: ['top'],
    };
  }

  public static get positionStrategyRight(): ConnectedPosition {
    return {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      panelClass: ['right'],
    };
  }

  public static get positionStrategyBottom(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      panelClass: ['bottom'],
    };
  }

  public static get positionStrategyLeft(): ConnectedPosition {
    return {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      panelClass: ['left'],
    };
  }

  constructor(protected override overlay: Overlay) {
    super(overlay);
  }

  public createOrUpdateOverlay(
    sourceRef: ElementRef,
    overlayConfig: OverlayConfig,
  ): void {
    if (!this.overlayRef()) {
      this.createOverlay(sourceRef, overlayConfig);
    } else if (this._sourceRef !== sourceRef) {
      this.updateOverlay(sourceRef, overlayConfig);
    }
  }

  public createOverlay(
    sourceRef: ElementRef,
    overlayConfig: OverlayConfig,
  ): void {
    this._sourceRef = sourceRef;
    const overlayRef = this.createOverlayRef(overlayConfig);
    this.setOverlayRef(overlayRef);
  }

  public updateOverlay(
    sourceRef: ElementRef,
    overlayConfig: OverlayConfig,
  ): void {
    this._sourceRef = sourceRef;
    this.overlayRef()?.updatePositionStrategy(overlayConfig.positionStrategy!);
    this.overlayRef()?.updateScrollStrategy(overlayConfig.scrollStrategy!);
  }

  public createOrUpdateOverlayWithPositions(
    sourceRef: ElementRef,
    positionStrategy: ConnectedPosition[],
    scrollStrategy: ScrollStrategy,
    viewportMargin: number,
  ): void {
    if (!this.overlayRef()) {
      this.createOverlayWithPositions(
        sourceRef,
        positionStrategy,
        scrollStrategy,
        viewportMargin,
      );
    } else {
      this.updateOverlayWithPositions(
        sourceRef,
        positionStrategy,
        scrollStrategy,
        viewportMargin,
      );
    }
  }

  public createOverlayWithPositions(
    sourceRef: ElementRef,
    positions: ConnectedPosition[],
    scrollStrategy: ScrollStrategy,
    viewportMargin: number,
  ): void {
    const positionStrategy = this.getDefaultPositionStrategy(
      sourceRef.nativeElement,
      positions,
      viewportMargin,
    );
    this.createOverlay(sourceRef, { positionStrategy, scrollStrategy });
  }

  public updateOverlayWithPositions(
    sourceRef: ElementRef,
    positions: ConnectedPosition[],
    scrollStrategy: ScrollStrategy,
    viewportMargin: number,
  ): void {
    const positionStrategy = this.getDefaultPositionStrategy(
      sourceRef.nativeElement,
      positions,
      viewportMargin,
    );
    this.updateOverlay(sourceRef, { positionStrategy, scrollStrategy });
  }

  public getDefaultPositionStrategy(
    elementRef: HTMLElement,
    positions: ConnectedPosition[],
    viewportMargin: number,
  ): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withFlexibleDimensions(true)
      .withViewportMargin(viewportMargin)
      .withPositions(positions);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { VatRate } from '../../../enums/vat-rate';
import { CommonHelper } from '../../../helpers/common.helper';
import { Amount } from '../../../interfaces/common/amount';
import { AmountCellComponent } from '../amount-cell/amount-cell.component';
import { StringCellComponent } from '../string-cell/string-cell.component';

@Component({
  selector: 'app-order-vat-cell',
  standalone: true,
  imports: [StringCellComponent, AmountCellComponent],
  templateUrl: './order-vat-cell.component.html',
  styleUrl: './order-vat-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderVatCellComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public vatRate = input.required<
    Pick<Amount, 'value' | 'currency'> & { vatRate: VatRate }
  >();

  protected vatValue = computed(() => {
    const vatRate = this.vatRate().vatRate;
    return CommonHelper.isNumeric(vatRate) ? +vatRate : null;
  });
}

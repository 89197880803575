import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxBadgeComponent, OnyxWidgetBoxComponent } from '@onyx/angular';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { Delegation } from '../../../common/interfaces/delegation';

@Component({
  selector: 'app-delegation-modal-status',
  imports: [TranslatePipe, OnyxBadgeComponent, OnyxWidgetBoxComponent],
  templateUrl: './delegation-modal-status.component.html',
  styleUrl: './delegation-modal-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalStatusComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public status = input.required<Delegation['status']>();
}

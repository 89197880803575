import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { AmountCellComponent } from '../../../../common/components/cells/amount-cell/amount-cell.component';
import { OrderVatCellComponent } from '../../../../common/components/cells/order-vat-cell/order-vat-cell.component';
import { SettlementData } from '../../order-modal/order-modal-plan/order-modal-plan-finances/order-modal-plan-finances.component';

export const ORDER_MODAL_PLAN_FINANCES_COLUMNS: OnyxTableColumn<SettlementData>[] =
  [
    {
      id: 'netto',
      name: 'labels.net',
      width: '100px',
      component: {
        ref: AmountCellComponent,
        inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
          amount: data.item.net,
          short: false,
        }),
      },
    },
    {
      id: 'vat',
      name: 'labels.vat',
      width: '100px',
      component: {
        ref: OrderVatCellComponent,
        inputs: (data): OnyxComponentInputs<OrderVatCellComponent> => ({
          vatRate: data.item.vatRate,
        }),
      },
    },
    {
      id: 'brutto',
      name: 'labels.gross',
      width: '100px',
      component: {
        ref: AmountCellComponent,
        inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
          amount: data.item.gross,
          short: false,
        }),
      },
    },
  ] as const;

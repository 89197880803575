import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxPaginated,
  OnyxPagination,
  OnyxPhonePipe,
  OnyxToastService,
} from '@onyx/angular';
import { chain, omit } from 'lodash';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  EMPTY,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { UnavailabilityForm } from '../../../../common/components/unavailabilities/unavailability-modal/unavailability-modal.component';
import { ConflictHelper } from '../../../../common/helpers/conflict.helper';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { Unavailability } from '../../../../common/interfaces/common/unavailability';
import { BatchFileUpload } from '../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../common/services/api.service';
import { StorageService } from '../../../../common/services/storage.service';
import { ImportProgress } from '../../../common/interfaces/import-progress';
import { DriverFormDto } from '../../driver-form/driver-form.component';
import { DriversImportData } from '../constants/drivers-import-schema';
import { Driver } from '../interfaces/driver';
import { DriverCategory } from '../interfaces/driver-category';
import { DriverStatus } from '../interfaces/driver-status';

@Injectable({
  providedIn: 'root',
})
export class DriversService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private filterPipe: OnyxFilterPipe,
    private toastService: OnyxToastService,
    private phonePipe: OnyxPhonePipe,
    private conflictHelper: ConflictHelper,
  ) {
    super(http);
  }

  public listDrivers(
    params: {
      category?: DriverCategory;
      status?: DriverStatus[];
      freeOver24h?: boolean;
      phoneAreaCode?: string;
      phoneNumber?: string;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Driver>> {
    return this.get('/drivers', {
      params: {
        ...(params.category && { category: params.category }),
        ...(params.status &&
          params.status.length > 0 && { 'status[]': params.status }),
        ...(params.freeOver24h != null && {
          freeOver24h: params.freeOver24h,
        }),
        ...(params.phoneAreaCode && { phoneAreaCode: params.phoneAreaCode }),
        ...(params.phoneNumber && { phoneNumber: params.phoneNumber }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchDrivers(
    query: string,
    limit: number,
    params?: Omit<
      Parameters<DriversService['listDrivers']>[0],
      keyof OnyxPagination
    >,
  ): Observable<OnyxDropdownOptionsSourceResult<Driver>> {
    return this.listDrivers({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) => ({
        options: chain(response.items)
          .map((driver) => ({
            name: `${driver.driverData.firstName} ${driver.driverData.lastName}`,
            value: driver,
          }))
          .thru((options) =>
            this.filterPipe.transform(options, query, ['name']).slice(0, limit),
          )
          .value(),
        totalItems: response.totalItems,
      })),
    );
  }

  public getDriver(uuid: string): Observable<Driver> {
    return this.get(`/drivers/${uuid}`);
  }

  public addDriver(dto: DriverFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<void>('/drivers', dto)),
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public importDrivers(data: DriversImportData[]): Observable<ImportProgress> {
    const dto = data.map((item): DriverFormDto => omit(item, 'index', 'base'));

    return from(dto).pipe(
      concatMap((dto, index) =>
        this.addDriver(dto).pipe(
          map(() => ({ ok: true, index })),
          defaultIfEmpty({ ok: false, index }),
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return of({ ok: false, index });
          }),
        ),
      ),
      takeWhile(({ ok }) => ok, true),
    );
  }

  public editDriver(uuid: string, dto: DriverFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<void>(`/drivers/${uuid}`, dto)),
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public archiveDriver(uuid: string): Observable<void> {
    return this.post<void>(`/drivers/${uuid}/archive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unarchiveDriver(uuid: string): Observable<void> {
    return this.post<void>(`/drivers/${uuid}/unarchive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public batchDrivers(
    driversUuid: string[],
    setFields: {
      archive?: boolean;
      unarchive?: boolean;
    },
  ): Observable<void> {
    return this.put<void>('/drivers/batch', { driversUuid, setFields }).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public getDriverUnavailabilities(
    driverUuid: string,
    params: {
      past: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Unavailability>> {
    return this.get(`/drivers/${driverUuid}/unavailabilities`, {
      params: {
        past: params.past,
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public addDriverUnavailability(
    driverUuid: string,
    form: UnavailabilityForm,
  ): Observable<void> {
    return this.uploadUnavailabilityFile(form).pipe(
      switchMap((form) =>
        this.post<void>(`/drivers/${driverUuid}/unavailabilities`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public editDriverUnavailability(
    driverUuid: string,
    uuid: string,
    form: UnavailabilityForm,
  ): Observable<void> {
    return this.uploadUnavailabilityFile(form).pipe(
      switchMap((form) =>
        this.put<void>(`/drivers/${driverUuid}/unavailabilities/${uuid}`, form),
      ),
      tap(() => this._reload$.next()),
    );
  }

  public reload(): void {
    this._reload$.next();
  }

  public deleteDriverUnavailability(
    driverUuid: string,
    uuid: string,
  ): Observable<void> {
    return this.delete<void>(
      `/drivers/${driverUuid}/unavailabilities/${uuid}`,
    ).pipe(tap(() => this._reload$.next()));
  }

  private uploadFiles(dto: DriverFormDto): Observable<DriverFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        {
          path: 'employmentConditions.scans',
          files: dto.employmentConditions.scans,
        },
        ...(dto.driverCard
          ? [
              {
                path: 'driverCard.scans',
                files: dto.driverCard.scans,
              },
            ]
          : []),
        {
          path: 'driversLicenseAndProfessionalQualifications.scans',
          files: dto.driversLicenseAndProfessionalQualifications.scans,
        },
        ...(dto.identityDocuments.passport
          ? [
              {
                path: 'identityDocuments.passport.scans',
                files: dto.identityDocuments.passport.scans,
              },
            ]
          : []),
        ...(dto.identityDocuments.identityDocument
          ? [
              {
                path: 'identityDocuments.identityDocument.scans',
                files: dto.identityDocuments.identityDocument.scans,
              },
            ]
          : []),
        ...(dto.identityDocuments.residenceCard
          ? [
              {
                path: 'identityDocuments.residenceCard.scans',
                files: dto.identityDocuments.residenceCard.scans,
              },
            ]
          : []),
        ...(dto.identityDocuments.polishCard
          ? [
              {
                path: 'identityDocuments.polishCard.scans',
                files: dto.identityDocuments.polishCard.scans,
              },
            ]
          : []),
        {
          path: 'clearCriminalRecordCertificate.scans',
          files: dto.clearCriminalRecordCertificate.scans,
        },
        ...dto.visas.map((visa, index) => ({
          path: `visas[${index}].scans`,
          files: visa.scans,
        })),
        ...dto.permissions.map((permission, index) => ({
          path: `permissions[${index}].scans`,
          files: permission.scans,
        })),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }

  private uploadUnavailabilityFile(
    form: UnavailabilityForm,
  ): Observable<UnavailabilityForm> {
    return of(form).pipe(
      map((form): BatchFileUpload[] => [
        {
          path: 'file',
          files: form.file,
        },
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(form, data)),
    );
  }

  private catchConflictErrors(
    dto: DriverFormDto,
    response: HttpErrorResponse,
  ): Observable<never> {
    if (response.status !== HttpStatusCode.BadRequest) throw response;

    const I18N = 'drivers.driverForm';
    const conflictFields = [
      {
        key: 'driverData.peselNumber',
        message: `${I18N}.uniqueErrors.peselNumber`,
        params: { peselNumber: dto.driverData?.peselNumber },
      },
      {
        key: 'driverData.privatePhone',
        message: `${I18N}.uniqueErrors.privatePhone`,
        params: {
          privatePhone: this.phonePipe.transform(dto.driverData.privatePhone!),
        },
      },
      {
        key: 'driverData.workPhone',
        message: `${I18N}.uniqueErrors.workPhone`,
        params: {
          workPhone: this.phonePipe.transform(dto.driverData.workPhone!),
        },
      },
      {
        key: 'driversLicense.driversLicenseNumber',
        message: `${I18N}.uniqueErrors.driversLicense`,
        params: {
          driversLicense:
            dto.driversLicenseAndProfessionalQualifications
              ?.driversLicenseNumber,
        },
      },
      {
        key: 'driverCard.number',
        message: `${I18N}.uniqueErrors.driverCard`,
        params: { driverCard: dto.driverCard?.number },
      },
      {
        key: 'identityDocuments.passport.number',
        message: `${I18N}.uniqueErrors.passport`,
        params: { passport: dto.identityDocuments?.passport?.number },
      },
      {
        key: 'identityDocuments.identityDocument.number',
        message: `${I18N}.uniqueErrors.identityDocument`,
        params: {
          identityDocument: dto.identityDocuments?.identityDocument?.number,
        },
      },
    ];

    if (this.conflictHelper.handleConflictErrors(response, conflictFields)) {
      return EMPTY;
    }

    throw response;
  }
}

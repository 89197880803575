<onyx-data-widget
  supheading="labels.tolls"
  [headingIcon]="{ name: 'finance', size: 16, color: 'violet' }"
>
  <span
    heading
    [innerHTML]="
      tollsTotalPrice()
        | number: '1.2-2'
        | numberDecoration: (tolls().defaultCurrency | uppercase)
    "
  ></span>

  <div class="container">
    <div class="list">
      <div class="item f-medium-3">
        <p>{{ 'labels.roads' | translate }}</p>

        <app-amount-cell
          [amount]="{
            value: tolls().defaultCurrencyValue,
            currency: tolls().defaultCurrency,
          }"
          [short]="false"
        ></app-amount-cell>
      </div>

      @if (otherCosts().defaultCurrencyValue; as defaultCurrencyValue) {
        <div class="item f-medium-3">
          <p>{{ 'labels.other' | translate }}</p>

          <app-amount-cell
            [amount]="{
              value: defaultCurrencyValue,
              currency: otherCosts().defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>
      }
    </div>

    @if (plannedData(); as plannedData) {
      <div
        class="plan"
        [onyxTooltip]="planTooltip"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        onyxTooltipColor="white"
      >
        <span>{{ 'labels.plan' | translate }}</span>

        <div class="plan-list f-medium-3">
          @let total = plannedData.total;
          <app-amount-cell
            [amount]="{
              value: total.value,
              currency: total.currency,
            }"
            [short]="false"
          ></app-amount-cell>

          <span>·</span>

          @let tolls = plannedData.tolls;
          <app-amount-cell
            [amount]="{
              value: tolls.defaultCurrencyValue,
              currency: tolls.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>

          <span>·</span>

          @let otherCosts = plannedData.otherCosts;
          <app-amount-cell
            [amount]="{
              value: otherCosts.defaultCurrencyValue,
              currency: otherCosts.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>
      </div>
    }
  </div>
</onyx-data-widget>

<ng-template #planTooltip>
  @if (plannedData(); as plannedData) {
    <div class="tooltip f-medium-3">
      <p>{{ 'labels.plan' | translate }}</p>

      <div class="tooltip-data">
        <div class="item">
          <p>{{ I18N + '.totalFees' | translate }}</p>

          @let total = plannedData.total;
          <app-amount-cell
            [amount]="{
              value: total.value,
              currency: total.currency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>

        <div class="item">
          <p>{{ 'labels.roads' | translate }}</p>

          @let tolls = plannedData.tolls;
          <app-amount-cell
            [amount]="{
              value: tolls.defaultCurrencyValue,
              currency: tolls.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>

        <div class="item">
          <p>{{ 'labels.others' | translate }}</p>

          @let otherCosts = plannedData.otherCosts;
          <app-amount-cell
            [amount]="{
              value: otherCosts.defaultCurrencyValue,
              currency: otherCosts.defaultCurrency,
            }"
            [short]="false"
          ></app-amount-cell>
        </div>
      </div>
    </div>
  }
</ng-template>

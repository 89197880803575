import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OwnershipType } from '../../../../dashboard/fleet/common/enums/ownership-type';
import { Fleet } from '../../../../dashboard/fleet/common/interfaces/fleet';
import { AmountCellComponent } from '../amount-cell/amount-cell.component';

@Component({
  selector: 'app-vehicle-value-cell',
  imports: [AmountCellComponent, TranslatePipe],
  templateUrl: './vehicle-value-cell.component.html',
  styleUrl: './vehicle-value-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleValueCellComponent {
  protected readonly I18N = 'fleet.fleetList.cells';

  protected readonly OwnershipType = OwnershipType;

  public ownership = input.required<Fleet['ownership']>();
}

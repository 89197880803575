import { OnyxChip } from '@onyx/angular';
import { BaseChip } from '../enums/base-chip';

export const getBaseChips = (
  vehiclesCount?: number,
  driversCount?: number,
): OnyxChip<BaseChip>[] => [
  {
    name: 'labels.assignedVehicles',
    value: BaseChip.ASSIGNED_VEHICLES,
    count: vehiclesCount,
  },
  {
    name: 'labels.assignedDrivers',
    value: BaseChip.ASSIGEND_DRIVERS,
    count: driversCount,
  },
];

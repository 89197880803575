<div class="heading">
  <div class="content">
    <div class="title">
      <ng-content select="[icon]"></ng-content>

      <h6>
        <ng-content></ng-content>
      </h6>
    </div>

    <span class="count f-label-1">{{ count() }}</span>
  </div>

  <ng-content select="[action]"></ng-content>
</div>

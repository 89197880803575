import { OnyxAddressType, OnyxPaginated } from '@onyx/angular';
import { uniqueId } from 'lodash';
import { GoodsSizeType } from '../../../../common/enums/goods-size-type';
import { VatRate } from '../../../../common/enums/vat-rate';
import { Amount } from '../../../../common/interfaces/common/amount';
import { SimplifiedFleet } from '../../../fleet/common/interfaces/fleet';
import { OrderPointCategory } from '../enums/order-point-category';
import { OrderPointStatus } from '../enums/order-point-status';
import { OrderRateType } from '../enums/order-rate-type';
import { OrderSemiTrailerSize } from '../enums/order-semi-trailer-size';
import { OrderStatus } from '../enums/order-status';
import { OrderTimeWindowType } from '../enums/order-time-window-type';
import { Order } from '../interfaces/order';

export const ORDER_MOCKS: OnyxPaginated<Order> = {
  items: [
    {
      uuid: uniqueId('order'),
      identifier: 'OR-0021-11-24-SAC',
      status: {
        value: OrderStatus.VEHICLE_SEARCH,
        color: 'blue',
      },
      timestamps: {
        createdAt: '2025-01-01',
        vehicleSearchAt: '2025-01-01',
        toAcceptAt: null,
        toDoAt: null,
        inProgressAt: null,
        completingDocumentsAt: null,
        invoiceToIssueAt: null,
        waitingForPaymentAt: null,
        partiallyPaidAt: null,
        paidAt: null,
        canceledAt: null,
        archivedAt: null,
      },
      route: {
        countries: ['pl', 'fi'],
        distance: 4000,
        distanceTime: 1000000,
        otherCosts: {
          date: '2024-11-27',
          currency: 'pln',
          value: 150,
          defaultCurrency: 'pln',
          defaultCurrencyValue: 150,
        } as Amount,
        tolls: {
          date: '2024-11-27',
          currency: 'pln',
          value: 530,
          defaultCurrency: 'pln',
          defaultCurrencyValue: 530,
        } as Amount,
      },

      engineProposalData: null,
      // outsourcingData: null,

      // engineProposalData: {
      //   emptyDistance: 50,
      //   cabotage: 3,
      //   cabotageLimit: 3,

      //   emptyDistanceTime: 1000,
      //   vehicle: {
      //     uuid: '87a3b13f-2701-45af-bc1d-731d47e73c50',
      //     generalInformation: {
      //       category: FleetCategory.SEMI_TRUCK,
      //       type: null,
      //       registrationNumber: 'GSZ 3AU7',
      //       vinNumber: '2LMDU68C27BJ83210',
      //       manufactureYear: 2015,
      //       firstRegistrationDate: '2024-11-12',
      //       make: 'Hyundai',
      //       model: 'Forentern',
      //       sideNumber: '099',
      //       maxSpeed: 15,
      //       initialMileage: null,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //       assignedBase: null,
      //     },
      //     fuelTankCapacity: {
      //       fuelType: 'diesel',
      //       mainTank: 100000,
      //       adBlueTank: null,
      //       additionalTank: 5000,
      //       generatorFuelTank: null,
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: true,
      //       beltsNumber: 24,
      //       hooksNumber: 14,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: null,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   trailer: {
      //     uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
      //     generalInformation: {
      //       assignedBase: null,
      //       category: FleetCategory.SEMI_TRAILER,
      //       type: 'insulated',
      //       registrationNumber: 'K1 WIZZY',
      //       vinNumber: 'WAUAF48H99K021450',
      //       manufactureYear: 1999,
      //       firstRegistrationDate: '2024-11-01',
      //       make: 'Gniotpol',
      //       model: 'Jool',
      //       sideNumber: '015',
      //       maxSpeed: null,
      //       initialMileage: 15555,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: null,
      //       beltsNumber: null,
      //       hooksNumber: null,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: SemiTrailerSize.MEGA,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   drivers: {
      //     primaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak ',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //     secondaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak II',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //   },
      // },
      outsourcingData: null,
      // outsourcingData: {
      //   contactPerson: {
      //     timocomId: '123',
      //     transId: '123',
      //     email: '123',
      //     firstName: '123',
      //     lastName: '123',
      //     note: '123',
      //     phone: {
      //       areaCode: '123',
      //       number: '123',
      //     },
      //     roles: [EmployeeRole.BUSINESS_ADMIN],
      //   },
      //   contractor: {
      //     note: null,
      //     uuid: '123',
      //     companyProfile: {
      //       companyName: '123',
      //       country: 'pl',
      //       displayName: 'Omega',
      //       phone: {
      //         areaCode: '+48',
      //         number: '1234214124',
      //       },
      //       types: [],
      //       vatId: '123',
      //     },
      //   },
      //   paymentTerm: '2024-11-27',
      //   primaryDriver: {
      //     name: 'Adam Róża',
      //     phone: {
      //       areaCode: '+48',
      //       number: '1234214124',
      //     },
      //   },
      //   secondaryDriver: {
      //     name: 'Tomasz Róża',
      //     phone: {
      //       areaCode: '+48',
      //       number: '1234214124',
      //     },
      //   },

      //   executionTerms: '',
      //   marginPercentage: 2,
      //   marginRate: 20000,
      //   note: '',
      //   rate: {
      //     value: 100,
      //     currency: 'pln',
      //     defaultCurrencyValue: 100,
      //     defaultCurrency: 'pln',
      //     date: null,
      //   } as Amount,
      //   rateType: OrderRateType.FREIGHT,
      //   trailerRegistrationNumber: null,
      //   vehicleRegistrationNumber: 'GKW RS6GT',
      //   vatRate: '12',
      // },
      // realizationData: {
      //   distance: 400000,
      //   emptyDistance: 50000,
      //   distanceTime: 1000000,
      //   emptyDistanceTime: 10000,
      //   otherCosts: {
      //     date: '2024-11-27',
      //     currency: 'usd',
      //     value: 500,
      //     defaultCurrency: 'pln',
      //     defaultCurrencyValue: 2000,
      //   } as Amount,
      //   price: {
      //     date: '2024-11-27',
      //     currency: 'usd',
      //     value: 3500,
      //     defaultCurrency: 'pln',
      //     defaultCurrencyValue: 15500,
      //   } as Amount,
      //   remainingDistance: 150000,
      //   tolls: {
      //     date: '2024-11-27',
      //     currency: 'usd',
      //     value: 129,
      //     defaultCurrency: 'pln',
      //     defaultCurrencyValue: 500,
      //   } as Amount,
      //   remainingDistanceTime: 120000,
      // },
      realizationData: null,
      // assignedData: {
      //   cabotage: 3,
      //   cabotageLimit: 4,
      //   assignedEmployees: [
      //     {
      //       uuid: '15',
      //       firstName: 'Patryk',
      //       lastName: 'Kawiak',
      //       avatar: undefined,
      //     },
      //     {
      //       uuid: '001',
      //       firstName: 'Adam',
      //       lastName: 'Rogacz',
      //       avatar: undefined,
      //     },
      //   ],
      //   emptyDistance: 50,
      //   isConfirmed: true,
      //   isManual: false,
      //   emptyDistanceTime: 1000,
      //   vehicle: {
      //     uuid: '87a3b13f-2701-45af-bc1d-731d47e73c50',
      //     generalInformation: {
      //       category: FleetCategory.SEMI_TRUCK,
      //       type: null,
      //       registrationNumber: 'GSZ 3AU7',
      //       vinNumber: '2LMDU68C27BJ83210',
      //       manufactureYear: 2015,
      //       firstRegistrationDate: '2024-11-12',
      //       make: 'Hyundai',
      //       model: 'Forentern',
      //       sideNumber: '099',
      //       maxSpeed: 15,
      //       initialMileage: null,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //       assignedBase: null,
      //     },
      //     fuelTankCapacity: {
      //       fuelType: 'diesel',
      //       mainTank: 100000,
      //       adBlueTank: null,
      //       additionalTank: 5000,
      //       generatorFuelTank: null,
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: true,
      //       beltsNumber: 24,
      //       hooksNumber: 14,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: null,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   trailer: {
      //     uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
      //     generalInformation: {
      //       assignedBase: null,
      //       category: FleetCategory.SEMI_TRAILER,
      //       type: 'insulated',
      //       registrationNumber: 'K1 WIZZY',
      //       vinNumber: 'WAUAF48H99K021450',
      //       manufactureYear: 1999,
      //       firstRegistrationDate: '2024-11-01',
      //       make: 'Gniotpol',
      //       model: 'Jool',
      //       sideNumber: '015',
      //       maxSpeed: null,
      //       initialMileage: 15555,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: null,
      //       beltsNumber: null,
      //       hooksNumber: null,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: SemiTrailerSize.MEGA,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   drivers: {
      //     primaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Jan',
      //         lastName: 'Paweł ',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //     secondaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Jan',
      //         lastName: 'Paweł II',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //   },
      // },

      // manualProposalData: {
      //   isLoading: true,
      //   deleyedRoute: null,
      //   isOk: null,
      //   emptyDistance: 0,
      //   emptyDistanceTime: 0,
      //   cabotage: 3,
      //   cabotageLimit: 4,

      //   vehicle: {
      //     uuid: '87a3b13f-2701-45af-bc1d-731d47e73c50',
      //     generalInformation: {
      //       category: FleetCategory.SEMI_TRUCK,
      //       type: null,
      //       registrationNumber: 'GSZ 3AU7',
      //       vinNumber: '2LMDU68C27BJ83210',
      //       manufactureYear: 2015,
      //       firstRegistrationDate: '2024-11-12',
      //       make: 'Hyundai',
      //       model: 'Forentern',
      //       sideNumber: '099',
      //       maxSpeed: 15,
      //       initialMileage: null,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //       assignedBase: null,
      //     },
      //     fuelTankCapacity: {
      //       fuelType: 'diesel',
      //       mainTank: 100000,
      //       adBlueTank: null,
      //       additionalTank: 5000,
      //       generatorFuelTank: null,
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: true,
      //       beltsNumber: 24,
      //       hooksNumber: 14,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: null,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   trailer: {
      //     uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
      //     generalInformation: {
      //       assignedBase: null,
      //       category: FleetCategory.SEMI_TRAILER,
      //       type: 'insulated',
      //       registrationNumber: 'K1 WIZZY',
      //       vinNumber: 'WAUAF48H99K021450',
      //       manufactureYear: 1999,
      //       firstRegistrationDate: '2024-11-01',
      //       make: 'Gniotpol',
      //       model: 'Jool',
      //       sideNumber: '015',
      //       maxSpeed: null,
      //       initialMileage: 15555,
      //       state: FleetState.ACTIVE,
      //       planningMode: VehiclePlanningMode.AUTO,
      //       registrationCountry: 'pl',
      //     },
      //     additionalParameters: {
      //       adrClasses: null,
      //       isLowDeck: null,
      //       beltsNumber: null,
      //       hooksNumber: null,
      //       hasSanitaryInspection: null,
      //       hasHaccp: null,
      //       hasHdsCrane: null,
      //       matsNumber: null,
      //       coilWellLength: null,
      //       trailerSize: null,
      //       semiTrailerSize: SemiTrailerSize.MEGA,
      //       hasLoadingsRamps: null,
      //       temperatureRange: null,
      //       hasTailLift: null,
      //       hasDumpContainer: null,
      //       hasForklift: null,
      //       other: [],
      //       hasCustomsSecuringRope: null,
      //     },
      //   },
      //   drivers: {
      //     primaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak ',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //     secondaryDriver: {
      //       uuid: 'a30d4021-f88e-424d-a05d-e1b9efa1a9bc',
      //       driverData: {
      //         assignedBase: null,
      //         firstName: 'Adrian',
      //         lastName: 'Nowak II',
      //         birthDate: '1985-12-04',
      //         nationality: 'pl',
      //         privatePhone: {
      //           areaCode: '+48',
      //           number: '559099422',
      //         },
      //         workPhone: null,
      //         privateEmail: 'a.nowak@onyxtms.com',
      //         homeAddress: {
      //           label: 'Jeziorna 11B, 82-520 Krzykosy, PL',
      //           street: 'Jeziorna',
      //           houseNumber: '11B',
      //           apartmentNumber: null,
      //           zipCode: '82-520',
      //           city: 'Krzykosy',
      //           countryCode: 'pl',
      //           coordinates: {
      //             latitude: 53.68513,
      //             longitude: 19.01345,
      //           },
      //           type: OnyxAddressType.CUSTOM,
      //         },
      //         peselNumber: '85120441461',
      //       },
      //       driverCard: {
      //         number: '123123123123123',
      //         issuingCountry: 'pl',
      //         expirationDate: '2024-11-30',
      //         scans: ['40c793a3-20ff-4404-9d00-5eea86c52aeb'],
      //       },
      //     },
      //   },
      // },

      manualProposalData: null,

      assignedData: null,

      price: {
        date: '2024-11-27',
        currency: 'usd',
        value: 3500,
        defaultCurrency: 'pln',
        defaultCurrencyValue: 15500,
      } as Amount,
      author: {
        uuid: '111',
        firstName: 'Michał',
        lastName: 'Nowakowski',
        avatar: undefined,
      },

      manualRoute: {
        author: {
          uuid: '124',
          firstName: 'Andrzej',
          lastName: 'Kowalski',
          avatar: undefined,
        },
        profitabilityPercentage: 105,
        isOnTime: true,
        createdAt: '2024-05-05T18:06:00',
      },

      note: {
        content: '123',
        updatedAt: '2024-04-04T15:00:00',
      },

      basicInformation: {
        sender: {
          note: null,
          uuid: 'e0e67f43-fd17-41b9-ada5-86a54e4b8243',
          companyProfile: {
            country: 'pl',
            displayName: 'Wiert MirBud ',
            companyName: 'Mango',
            phone: {
              areaCode: '+48',
              number: '152512512',
            },
            types: [],
            vatId: '000',
          },
        },
        ourBranch: {
          uuid: '123',
          name: 'Oddział I',
          correspondenceAddress: {
            label: 'Jeziorna 32/12, 82-520 Krzykosy, PL',
            street: 'Jeziorna',
            houseNumber: '32',
            apartmentNumber: '12',
            zipCode: '82-520',
            city: 'Krzykosy',
            countryCode: 'pl',
            coordinates: {
              latitude: 53.6825313,
              longitude: 19.0129617,
            },
            type: OnyxAddressType.CUSTOM,
          },
          vatIdentification: {
            country: 'pl',
            vatId: '8992447196',
          },
        },

        senderBranch: {
          uuid: '123',
          name: 'Oddział I',
          correspondenceAddress: {
            label: 'Jeziorna 32/12, 82-520 Krzykosy, PL',
            street: 'Jeziorna',
            houseNumber: '32',
            apartmentNumber: '12',
            zipCode: '82-520',
            city: 'Krzykosy',
            countryCode: 'pl',
            coordinates: {
              latitude: 53.6825313,
              longitude: 19.0129617,
            },
            type: OnyxAddressType.CUSTOM,
          },
          vatIdentification: {
            country: 'pl',
            vatId: '8992447196',
          },
        },
        dischargeTypes: ['side', 'bottom'],
        rate: { value: 3000, currency: 'pln', date: null } as Amount,
        rateType: OrderRateType.FREIGHT,
        vehicleTypes: ['insulated', 'curtainside'],
        loadedSemiTrailer: {
          uuid: '94397e19-439b-4cdc-ad74-231c38ceec63',
          generalInformation: {
            registrationNumber: 'GTR 86XX',
            sideNumber: '015',
          },
        } as SimplifiedFleet,
        loadedSemiTrailerRegistrationNumber: null,
        minSemiTrailerSize: OrderSemiTrailerSize.MEGA,
        vatRate: VatRate.RATE_23,
      },

      parameters: {
        adrClasses: null,
        beltsNumber: null,
        coilWellLength: null,
        driverLanguages: ['pl', 'de'],
        hasCustomsSecuringRope: null,
        hasDoubleCrew: true,
        hasDumpContainer: null,
        hasEcmr: false,
        hasForklift: false,
        hasHaccp: false,
        hasHdsCrane: false,
        hasLoadingsRamps: false,
        hasPalletExchange: false,
        hasSanitaryInspection: false,
        hasTailLift: false,
        hooksNumber: null,
        isDedicated: true,
        isExclusive: true,
        matsNumber: null,
        hasDoubleDeck: true,
        hasSafeParking: true,
        sentNotificationNumber: '04624',
        temperatureRange: null,
        other: [],
      },

      documents: [
        {
          name: 'CMR',
          note: '',
          attachments: ['144b9127-2035-4f5c-9f16-473a5fcc5171'],
        },
        {
          name: 'Faktura za parking',
          note: 'Do opłacenia',
          attachments: ['144b9127-2035-4f5c-9f16-473a5fcc5171'],
        },
        {
          name: 'Beförderungsdokument',
          note: '',
          attachments: ['144b9127-2035-4f5c-9f16-473a5fcc5171'],
        },
      ],

      points: [
        {
          estimations: {
            estimatedArrivalTime: '2025-01-13T13:20:00',
            estimatedDepartueTime: '2025-01-16T09:00:00',
            estimatedDeadlineTime: '2024-11-27T08:00:00',
          },
          timestamps: {
            arrivedAt: null,
            canceledAt: null,
            completedAt: null,
            startedAt: '2025-01-14T02:06:00',
          },
          route: {
            distance: 1000,
            distanceFuel: 50,
            distanceTime: 6000,
            polyline: 'xyz',
            remainingDistance: 9000,
            remainingDistanceTime: 5000,
          },
          status: OrderPointStatus.TO_DO,

          uuid: '123124214',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.CHECKPOINT,
          driverNote: 'uważaj na dziki',
          serviceTime: '01:00:00',
          phone: {
            areaCode: '+48',
            number: '155215521',
          },
          businessHours: {
            saturday: null,
            weekday: { from: '10:00:00', to: '15:00:00' },
            sunday: null,
          },
          type: 'gas-station',
          includeInOrder: true,
          name: '123',
        },
        {
          estimations: {
            estimatedArrivalTime: '2025-01-16T10:30:00',
            estimatedDepartueTime: '2025-01-16T11:00:00',
            estimatedDeadlineTime: '2024-11-27T08:00:00',
          },
          timestamps: {
            arrivedAt: '2024-11-27T08:06:00',
            canceledAt: '2024-11-27T08:06:00',
            completedAt: '2024-11-27T08:06:00',
            startedAt: '2024-11-27T08:06:00',
          },
          route: {
            distance: 1000,
            distanceFuel: 50,
            distanceTime: 60000,
            polyline: 'xyz',
            remainingDistance: 900,
            remainingDistanceTime: 5000,
          },
          status: OrderPointStatus.WAITING,

          uuid: '123',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '01:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2025-01-16', time: '12:00:00' }],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 15500,
              type: null,
              typeName: 'Nasiona',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 13500,
              type: null,
              typeName: 'Zboże',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          estimations: {
            estimatedArrivalTime: '2024-11-27T08:06:00',
            estimatedDepartueTime: '2024-11-27T08:06:00',
            estimatedDeadlineTime: '2024-11-27T08:00:00',
          },
          timestamps: {
            arrivedAt: '2024-11-27T08:06:00',
            canceledAt: '2024-11-27T08:06:00',
            completedAt: '2024-11-27T08:06:00',
            startedAt: '2024-11-27T08:06:00',
          },
          route: {
            distance: 1000,
            distanceFuel: 50,
            distanceTime: 6000,
            polyline: 'xyz',
            remainingDistance: 900,
            remainingDistanceTime: 5000,
          },
          status: 4 as never,

          uuid: '456',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.LOADING,
          type: OrderPointCategory.LOADING,
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
          referenceNumber: '123',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2024-05-16', time: '15:00:00' }],
          },
          goods: [
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 13500,
              type: null,
              typeName: 'Nasiona',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
            {
              uuid: '00012',
              quantity: 15,
              referenceNumber: '154',
              size: {
                type: GoodsSizeType.DIMENSIONS,
                height: 15,
                length: 15,
                width: 15,
                loadingMeters: null,
                volume: null,
              },
              statisticalNumber: '15',
              totalWeight: 1200,
              type: null,
              typeName: 'Amunicja',
              unit: {
                uuid: '67ecb815-e96a-41b9-9bff-05b2e90e47d0',
                names: {
                  de: 'Europalette',
                  en: 'Euro pallet',
                  es: 'Palet europeo',
                  fr: 'Europalette',
                  it: 'Pallet europeo',
                  pl: 'Europaleta',
                },
                size: {
                  type: GoodsSizeType.DIMENSIONS,
                  loadingMeters: null,
                  length: 120,
                  width: 80,
                  height: 200,
                  volume: null,
                },
                isDefault: true,
              },
              unitedNationsNumber: '125',
            },
          ],
        },
        {
          estimations: {
            estimatedArrivalTime: '2025-01-20T08:06:00',
            estimatedDepartueTime: '2025-01-20T08:06:00',
            estimatedDeadlineTime: '2024-11-27T08:00:00',
          },
          timestamps: {
            arrivedAt: '2024-11-27T12:06:00',
            canceledAt: '2024-11-27T012:06:00',
            completedAt: '2024-11-27T08:06:00',
            startedAt: '2024-11-27T08:06:00',
          },
          route: {
            distance: 1000,
            distanceFuel: 50,
            distanceTime: 6000,
            polyline: 'xyz',
            remainingDistance: 900,
            remainingDistanceTime: 5000,
          },
          status: 4 as never,

          uuid: '1245156666',
          address: {
            apartmentNumber: '14',
            city: 'Kwidzyn',
            coordinates: {
              latitude: 14,
              longitude: 14,
            },
            countryCode: 'pl',
            houseNumber: '15',
            label: '-',
            street: 'Grudziądzka',
            type: OnyxAddressType.CUSTOM,
            zipCode: '82-500',
          },
          category: OrderPointCategory.UNLOADING,
          type: OrderPointCategory.UNLOADING,
          goods: [
            {
              uuid: '00012',
              quantity: 15,
            },
          ],
          referenceNumber: '15',
          timeWindows: {
            type: OrderTimeWindowType.FIX,
            windows: [{ date: '2025-01-22', time: '15:00:00' }],
          },
          driverNote: 'uważaj na dziki',
          serviceTime: '15:00:00',
        },
      ],
    },
  ],
  limit: 10,
  page: 1,
  totalItems: 1,
};

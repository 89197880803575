import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TABLE_TOOLTIP_DELAY,
  OnyxLinkComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { DriverHelper } from '../../../../dashboard/drivers/common/helpers/driver.helper';
import { Driver } from '../../../../dashboard/drivers/common/interfaces/driver';
import { FleetHelper } from '../../../../dashboard/fleet/common/helpers/fleet.helper';
import { Fleet } from '../../../../dashboard/fleet/common/interfaces/fleet';
import { SimplifiedBase } from '../../../../dashboard/management-panel/bases/common/interfaces/base';
import { AddressComponent } from '../../address/address.component';

@Component({
  selector: 'app-base-cell',
  imports: [
    AddressComponent,
    OnyxLinkComponent,
    TranslatePipe,
    OnyxTooltipDirective,
  ],
  templateUrl: './base-cell.component.html',
  styleUrl: './base-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCellComponent {
  protected readonly I18N = 'fleet.fleetList.cells';
  protected readonly TOOLTIP_DELAY = ONYX_TABLE_TOOLTIP_DELAY;

  public base = input.required<SimplifiedBase | null>();
  public vehicle = input(null, {
    transform: (vehicle: Fleet | null) => {
      if (!vehicle) return null;
      if (!FleetHelper.isVehicle(vehicle)) return null;
      return vehicle;
    },
  });
  public driver = input<Driver | null>(null);

  protected isArchived = computed(() => {
    const [vehicle, driver] = [this.vehicle(), this.driver()];
    if (vehicle) {
      return FleetHelper.isArchived(vehicle);
    } else if (driver) {
      return DriverHelper.isArchived(driver);
    }
    return false;
  });

  constructor(
    private fleetHelper: FleetHelper,
    private driverHelper: DriverHelper,
  ) {}

  protected assignBase(): void {
    const [vehicle, driver] = [this.vehicle(), this.driver()];
    if (vehicle) {
      this.fleetHelper.edit(vehicle.uuid, {});
    } else if (driver) {
      this.driverHelper.edit(driver.uuid, {});
    }
  }
}

import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Injector,
  input,
  linkedSignal,
  OnInit,
  output,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxMessageComponent,
  OnyxNamePipe,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { chain } from 'lodash';
import { DriverHelper } from '../../../dashboard/drivers/common/helpers/driver.helper';
import { SimplifiedDriver } from '../../../dashboard/drivers/common/interfaces/driver';

@Component({
  selector: 'app-drivers-times',
  standalone: true,
  imports: [
    OnyxTabsComponent,
    OnyxIconComponent,
    OnyxDataWidgetComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxTooltipDirective,
    NgClass,
    OnyxInformationHeadingComponent,
    OnyxMessageComponent,
    OnyxNamePipe,
  ],
  templateUrl: './drivers-times.component.html',
  styleUrl: './drivers-times.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversTimesComponent implements OnInit {
  protected readonly I18N = 'orders.orderModal';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly ActionHelper = ActionHelper;

  public primaryDriver = input.required<SimplifiedDriver | null | undefined>();
  public secondaryDriver = input.required<
    SimplifiedDriver | null | undefined
  >();
  public variant = input<'default' | 'extended'>('default');

  public driverChange = output<SimplifiedDriver>();

  protected selectedDriver = linkedSignal(() => this.drivers()[0] ?? null);

  protected drivers = computed(() =>
    chain([this.primaryDriver(), this.secondaryDriver()]).compact().value(),
  );
  protected tabs = computed<OnyxTab<SimplifiedDriver>[] | null>(() => {
    if (this.drivers().length !== 2) return null;

    return this.drivers().map((driver) => ({
      name: `${driver.driverData.firstName} ${driver.driverData.lastName}`,
      value: driver,
      //TEMP: Replace by real tacho icon
      icon: { name: 'tacho-offline', size: 16 },
    }));
  });

  constructor(
    private driverHelper: DriverHelper,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    effect(() => this.driverChange.emit(this.selectedDriver()), {
      injector: this.injector,
    });
  }

  protected openDriverModal(driver: SimplifiedDriver): void {
    this.driverHelper.openModal(driver);
  }
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Inject,
  Injector,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  AddressHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxAddress,
  OnyxCallbackOption,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxMapComponent,
  OnyxMapEvent,
  OnyxMapMarkerType,
  OnyxModalComponent,
  OnyxPhone,
  OnyxPhonePipe,
  OnyxSpinnerComponent,
  OnyxTableComponent,
  OnyxTableData,
  OnyxTimePipe,
  OnyxToastService,
  OnyxTooltipDirective,
  PhoneHelper,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { AddressComponent } from '../../../../common/components/address/address.component';
import { BusinessHoursPipe } from '../../../../common/components/pipes/business-hours.pipe';
import { SHORT_PAGINATION } from '../../../../common/constants/short-pagination';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { ContractorHelper } from '../../contractors/common/helpers/contractor.helper';
import { ASSIGNED_CONTRACTORS_NOT_FOUND } from '../common/constants/assigned-contractors-not-found';
import { MODAL_ASSIGNED_CONTRACTORS_COLUMNS } from '../common/constants/modal-assigned-contractors-columns';
import { PointOfInterestHelper } from '../common/helpers/point-of-interest.helper';
import {
  PointOfInterest,
  SimplifiedPointOfInterest,
} from '../common/interfaces/point-of-interest';
import { PointsOfInterestService } from '../common/services/points-of-interest.service';

export type PointOfInterestModalData =
  | PointOfInterest
  | SimplifiedPointOfInterest
  | string;

enum PointOfInterestModalSection {
  REALIZATIONS = 'realizations',
  ASSIGNED_CONTRACTORS = 'assignedContractors',
}

@Component({
  selector: 'app-points-of-interest-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxMapComponent,
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxTimePipe,
    OnyxPhonePipe,
    OnyxChipsComponent,
    OnyxTableComponent,
    OnyxSpinnerComponent,
    OnyxDropdownDirective,
    AddressComponent,
    OnyxTooltipDirective,
    BusinessHoursPipe,
  ],
  templateUrl: './points-of-interest-modal.component.html',
  styleUrl: './points-of-interest-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsOfInterestModalComponent {
  protected readonly I18N = 'pointsOfInterest';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly COLUMNS = MODAL_ASSIGNED_CONTRACTORS_COLUMNS;
  protected readonly NOT_FOUND = ASSIGNED_CONTRACTORS_NOT_FOUND;

  protected readonly ActionHelper = ActionHelper;
  protected readonly AddressHelper = AddressHelper;
  protected readonly PhoneHelper = PhoneHelper;
  protected readonly PointOfInterestModalSection = PointOfInterestModalSection;

  private readonly map = viewChild.required(OnyxMapComponent);
  private readonly uuid: string;

  protected pointOfInterest = signal<PointOfInterest | null>(null);
  protected loading = signal(false);
  protected error = signal(false);
  protected pagination = signal(SHORT_PAGINATION);
  protected close$ = new Subject<void>();
  protected pointOfInterest$ = new Subject<void>();
  protected chips = computed(() => [
    {
      name: 'labels.realizations',
      value: PointOfInterestModalSection.REALIZATIONS,
    },
    {
      name: `labels.assignedContractors`,
      value: PointOfInterestModalSection.ASSIGNED_CONTRACTORS,
      count: this.pointOfInterest()
        ? this.pointOfInterest()?.contractors.length
        : undefined,
    },
  ]);
  protected group = signal([this.chips()[0].value]);
  protected options = computed(() => {
    const pointOfInterest = this.pointOfInterest();
    return pointOfInterest
      ? this.pointOfInterestHelper.getOptions(pointOfInterest, {
          close$: this.close$,
        })
      : null;
  });

  constructor(
    @Inject(DIALOG_DATA)
    protected pointOfInterestData: PointOfInterestModalData,
    protected dialogRef: DialogRef<void>,
    private pointsOfInterestService: PointsOfInterestService,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private injector: Injector,
    private pointOfInterestHelper: PointOfInterestHelper,
    private contractorHelper: ContractorHelper,
  ) {
    this.uuid = isString(this.pointOfInterestData)
      ? this.pointOfInterestData
      : this.pointOfInterestData.uuid;

    this.pointOfInterest$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.pointsOfInterestService.getPointOfInterest(this.uuid).pipe(
            catchError((response) => {
              this.pointOfInterest.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((pointOfInterest) => {
        this.pointOfInterest.set(pointOfInterest);
        this.loading.set(false);
        this.error.set(false);
      });

    if (
      isString(this.pointOfInterestData) ||
      !PointOfInterestHelper.isPointOfInterestType(this.pointOfInterestData)
    ) {
      this.pointOfInterest$.next();
    } else {
      this.pointOfInterest.set(this.pointOfInterestData);
    }

    this.pointsOfInterestService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.pointOfInterest$.next());

    effect(() => {
      const pointOfInterest = this.pointOfInterest();
      if (!pointOfInterest) return;

      afterNextRender(
        () => {
          this.map().dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: this.uuid,
              type: OnyxMapMarkerType.POINT_OF_INTEREST,
              coordinates: pointOfInterest.address.coordinates,
            }),
          );
          this.map().dispatch(new OnyxMapEvent.FitContent());
        },
        { injector: this.injector },
      );
    });
  }

  protected edit(): void {
    this.pointOfInterestHelper.edit(this.uuid, { close$: this.close$ });
  }

  protected getContractorOptions(
    data: OnyxTableData<PointOfInterest['contractors'][number]>,
  ): OnyxCallbackOption[] {
    return [
      {
        name: 'buttons.show',
        value: () => this.contractorHelper.openModal(data.item.contractor),
      },
    ];
  }

  protected copyAddress(address: OnyxAddress) {
    ActionHelper.copy(AddressHelper.composeLabel(address), this.toastService);
  }

  protected copyCoordinates(address: OnyxAddress) {
    ActionHelper.copy(
      AddressHelper.composeCoordinatesLabel(address),
      this.toastService,
    );
  }

  protected copyPhone(phone: OnyxPhone) {
    ActionHelper.copy(PhoneHelper.composeLabel(phone), this.toastService);
  }
}

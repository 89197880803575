<onyx-modal
  type="center"
  size="s"
  [heading]="I18N + '.heading'"
  [close$]="close$"
  (keydown.enter)="next()"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <onyx-dropdown
      [formControl]="controls.country"
      label="labels.country"
      [options]="vatIdCountries$ | async"
      [search]="true"
      width="80px"
    ></onyx-dropdown>

    <onyx-text-field
      [formControl]="controls.vatId"
      label="labels.vatId"
    ></onyx-text-field>
  </form>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? next() : null"
    >
      {{ 'buttons.continue' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

<ng-template #conflictToastTemplate>
  <p>{{ I18N + '.contractorExists' | translate }}</p>

  <onyx-button size="s" (click)="showExistingContractor()">
    {{ 'buttons.show' | translate }}
  </onyx-button>
</ng-template>

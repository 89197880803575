import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { DelegationsRoute } from '../../delegations.routes';
import { DelegationHelper } from '../helpers/delegation.helper';

export const delegationCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const delegationHelper = inject(DelegationHelper);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  delegationHelper.openModal(uuid);

  const urlTree = router.parseUrl(DelegationsRoute.DELEGATIONS_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};

import { Pipe, PipeTransform } from '@angular/core';
import { isNumber, isString, round } from 'lodash';
import { DateTime } from 'luxon';

type DurationDate = string | number | DateTime;

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  public transform(
    start: DurationDate,
    format: 'from' | 'to' = 'from',
    end: DurationDate = DateTime.now(),
    size: 'm' | 's' = 's',
  ): string | null {
    const now = DateTime.now();

    let startDate: DateTime;
    if (isNumber(start)) {
      startDate = now.plus({ seconds: start });
    } else if (isString(start)) {
      startDate = DateTime.fromISO(start);
    } else {
      startDate = start;
    }

    let endDate: DateTime;
    if (isNumber(end)) {
      endDate = now.plus({ seconds: end });
    } else if (isString(end)) {
      endDate = DateTime.fromISO(end);
    } else {
      endDate = end;
    }

    const diff =
      format === 'from'
        ? endDate.diff(startDate, ['days', 'hours', 'minutes'])
        : startDate.diff(endDate, ['days', 'hours', 'minutes']);

    const { minutes, hours, days } = {
      minutes: round(diff.minutes),
      hours: round(diff.hours),
      days: round(diff.days),
    };

    if (!diff.isValid || minutes < 0) return '-';

    const isSmall = size === 's';
    const daysLabel = isSmall ? 'd' : ' d';
    const hoursLabel = isSmall ? 'h' : ' h';
    const minutesLabel = isSmall ? 'm' : ' m';

    if (days > 0) return `${days}${daysLabel} ${hours}${hoursLabel}`;
    if (hours > 0) return `${hours}${hoursLabel} ${minutes}${minutesLabel}`;
    if (minutes > 0) return `${minutes}${minutesLabel}`;

    return '-';
  }
}

import { TranslateService } from '@ngx-translate/core';
import { OnyxMaybeArray, OnyxTime, OnyxTimeRange } from '@onyx/angular';
import { castArray, chain, isArray, isFinite, isNaN, isString } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { Fleet } from '../../dashboard/fleet/common/interfaces/fleet';
import { Order } from '../../dashboard/orders/common/interfaces/order';
import { BusinessHours } from '../interfaces/common/business-hours';
import { HelperOptions } from '../interfaces/utilities/helper-options';

export class CommonHelper {
  public static getOpeningHours(
    date: string | DateTime,
    businessHours: BusinessHours,
  ): OnyxTimeRange | null {
    return chain(date)
      .thru((date) => (isString(date) ? DateTime.fromISO(date) : date))
      .thru((dateTime) => dateTime.weekday)
      .thru((weekday): OnyxTimeRange | null => {
        if (weekday === 7) return businessHours.sunday;
        if (weekday === 6) return businessHours.saturday;
        return businessHours.weekday;
      })
      .value();
  }

  public static getSortedParameters(
    parameters: Fleet['additionalParameters'] | Order['parameters'],
    translateService: TranslateService,
  ) {
    return chain(parameters)
      .entries()
      .map(([key, value]) => ({ key, value }))
      .filter(
        ({ value }) =>
          value != null &&
          value !== false &&
          (isArray(value) ? value.length !== 0 : true),
      )
      .map(({ key, value }) => ({
        key,
        name: `labels.${key}`,
        value: value as NonNullable<typeof value>,
      }))
      .orderBy((parameter) =>
        translateService.instant(parameter.name).toLowerCase(),
      )
      .value();
  }

  public static handleOptions(
    items: OnyxMaybeArray<{ uuid: string } | string>,
    options: HelperOptions,
  ): void {
    const itemsUuid = new Set(
      chain(items)
        .thru((items) => castArray(items))
        .map((item) => (isString(item) ? item : item.uuid))
        .value(),
    );

    options?.selectedItems?.update((items) =>
      items.filter(({ uuid }) => !itemsUuid.has(uuid)),
    );
    options?.close$?.next();
  }

  public static isNumeric(value: string): boolean {
    return !isNaN(parseFloat(value)) && !isFinite(value);
  }

  public static parseTimeToSeconds(time: OnyxTime | null): number {
    if (!time) return 0;
    return Duration.fromISOTime(time).as('seconds');
  }
}

import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { pick } from 'lodash';
import { Dimensions } from '../../../interfaces/common/goods-size';

@Component({
  selector: 'app-dimensions-cell',
  imports: [DecimalPipe],
  templateUrl: './dimensions-cell.component.html',
  styleUrl: './dimensions-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionsCellComponent {
  public dimensions = input.required<Dimensions | null>();

  protected convertedDimensions = computed(() => {
    let dimensions = this.dimensions();
    if (!dimensions) return null;

    dimensions = pick(dimensions, ['length', 'width', 'height']);
    if (Object.values(dimensions).some((value) => value == null)) return null;

    return {
      length: dimensions.length / 100,
      width: dimensions.width / 100,
      height: dimensions.height / 100,
    };
  });
}

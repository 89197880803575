import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxIconBoxColor } from '../../icon-box';
import { OnyxIcon } from '../../icons';
import { OnyxIconComponent } from '../../icons/onyx-icon/onyx-icon.component';

@Component({
  selector: 'onyx-data-widget',
  standalone: true,
  imports: [OnyxIconComponent, TranslatePipe],
  templateUrl: './onyx-data-widget.component.html',
  styleUrl: './onyx-data-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDataWidgetComponent {
  public supheading = input<string | null>(null);
  public headingIcon = input<
    (OnyxIcon & { color: `${OnyxIconBoxColor}` }) | null
  >(null);
  public color = input<'gray' | 'green' | 'red' | 'yellow' | 'orange' | 'blue'>(
    'gray',
  );
  public showDivider = input(false);
}

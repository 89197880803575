import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { AddressCellComponent } from '../../../../../common/components/cells/address-cell/address-cell.component';
import { AmountCellComponent } from '../../../../../common/components/cells/amount-cell/amount-cell.component';
import { CountryCellComponent } from '../../../../../common/components/cells/country-cell/country-cell.component';
import { NumberCellComponent } from '../../../../../common/components/cells/number-cell/number-cell.component';
import { PhoneCellComponent } from '../../../../../common/components/cells/phone-cell/phone-cell.component';
import { StringCellComponent } from '../../../../../common/components/cells/string-cell/string-cell.component';
import { UnitCellComponent } from '../../../../../common/components/cells/unit-cell/unit-cell.component';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ContractorsImportData } from './contractors-import-schema';

export const CONTRACTORS_IMPORT_COLUMNS: OnyxTableColumn<
  ContractorsImportData & { index: number }
>[] = [
  {
    required: true,
    id: 'index',
    name: 'labels.row',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.index,
      }),
    },
  },
  {
    id: 'country',
    name: 'labels.country',
    width: 'fill',
    component: {
      ref: CountryCellComponent,
      inputs: (data): OnyxComponentInputs<CountryCellComponent> => ({
        countryCode: data.item.country,
      }),
    },
  },
  {
    id: 'vatId',
    name: 'labels.vatId',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.vatId,
      }),
    },
  },
  {
    id: 'displayName',
    name: 'labels.displayName',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.displayName,
      }),
    },
  },
  {
    id: 'types',
    name: 'labels.businessType',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.types,
        dictionaryCode: DictionaryCode.CONTRACTOR_TYPE,
      }),
    },
  },
  {
    id: 'phone',
    name: 'labels.officePhone',
    width: 'fill',
    component: {
      ref: PhoneCellComponent,
      inputs: (data): OnyxComponentInputs<PhoneCellComponent> => ({
        phone: data.item.phone,
      }),
    },
  },
  {
    id: 'note',
    name: 'labels.note',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.note || '-',
      }),
    },
  },
  {
    id: 'paymentTermDays',
    name: 'labels.daysPaymentTerms',
    width: 'fill',
    component: {
      ref: UnitCellComponent,
      inputs: (data): OnyxComponentInputs<UnitCellComponent> => ({
        value: data.item.paymentTermDays,
        unit: 'labels.days',
      }),
    },
  },
  {
    id: 'tradeCreditLimitValue',
    name: 'labels.tradeCredit',
    width: 'fill',
    component: {
      ref: AmountCellComponent,
      inputs: (data): OnyxComponentInputs<AmountCellComponent> => ({
        amount: data.item.tradeCreditLimit,
      }),
    },
  },
  {
    id: 'electronicInvoiceEmail',
    name: 'labels.invoiceEmail',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.electronicInvoiceEmail || '-',
      }),
    },
  },
  {
    id: 'mainAddress',
    name: 'labels.mainAddress',
    width: 'fill',
    component: {
      ref: AddressCellComponent,
      inputs: (data): OnyxComponentInputs<AddressCellComponent> => ({
        address: data.item.mainAddress,
        format: 'full',
      }),
    },
  },
  {
    id: 'correspondenceAddress',
    name: 'labels.correspondenceAddress',
    width: 'fill',
    component: {
      ref: AddressCellComponent,
      inputs: (data): OnyxComponentInputs<AddressCellComponent> => ({
        address: data.item.correspondenceAddress,
        format: 'full',
      }),
    },
  },
];

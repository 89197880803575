import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe } from '@onyx/angular';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ColorHelper } from '../../../../../common/helpers/color.helper';
import { Driver } from '../../interfaces/driver';

@Component({
  selector: 'app-driver-license-cell',
  imports: [OnyxDatePipe, TranslatePipe, LowerCasePipe, NgTemplateOutlet],
  templateUrl: './driver-license-cell.component.html',
  styleUrl: './driver-license-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverLicenseCellComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public categories = input.required<
    | Driver['driversLicenseAndProfessionalQualifications']['categories']
    | undefined
  >();

  protected cellColor = computed(() => {
    const colors = this.categories()?.map((category) =>
      ColorHelper.getCellColor(
        category.expirationDate?.date as unknown as string,
      ),
    );

    return ColorHelper.findCellColor(colors);
  });
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxInformationHeadingComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxToastService,
} from '@onyx/angular';
import { map, skip } from 'rxjs';
import { DriversTimesComponent } from '../../../../common/components/drivers-times/drivers-times.component';
import { NotFoundPulseComponent } from '../../../../common/components/not-found-pulse/not-found-pulse.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { FleetHelper } from '../../../fleet/common/helpers/fleet.helper';
import { Fleet, SimplifiedFleet } from '../../../fleet/common/interfaces/fleet';
import { OrderPlanningMode } from '../../common/enums/order-planning-mode';
import { OrderStatus } from '../../common/enums/order-status';
import { OrderHelper } from '../../common/helpers/order.helper';
import { ManualProsposalData, Order } from '../../common/interfaces/order';
import { OrdersService } from '../../common/services/orders.service';
import { OrderModalDecisionButtonsComponent } from './order-modal-decision-buttons/order-modal-decision-buttons.component';
import { OrderModalVehiclesComponent } from './order-modal-vehicles/order-modal-vehicles.component';
import { OrderModalOutsourcingDataComponent } from './outsourcing/order-modal-outsourcing-data/order-modal-outsourcing-data.component';
import { OrderModalOutsourcingFormComponent } from './outsourcing/order-modal-outsourcing-form/order-modal-outsourcing-form.component';

@Component({
  selector: 'app-order-modal-main-section',
  standalone: true,
  imports: [
    OnyxTabsComponent,
    OnyxInformationHeadingComponent,
    OrderModalOutsourcingFormComponent,
    TranslatePipe,
    OrderModalVehiclesComponent,
    DriversTimesComponent,
    NotFoundPulseComponent,
    OrderModalDecisionButtonsComponent,
    OnyxDropdownComponent,
    OnyxButtonComponent,
    OrderModalOutsourcingDataComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './order-modal-main-section.component.html',
  styleUrl: './order-modal-main-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalMainSectionComponent implements OnInit {
  protected readonly I18N = 'orders.orderModal';
  protected readonly ORDER_PLANNING_TABS: OnyxTab<OrderPlanningMode>[] = [
    {
      name: `${DictionaryCode.VEHICLE_PLANNING_MODE}.auto`,
      value: OrderPlanningMode.AUTO,
      icon: { name: 'signet-light', size: 16 },
      iconColor: 'black',
    },
    {
      name: `${DictionaryCode.VEHICLE_PLANNING_MODE}.manual`,
      value: OrderPlanningMode.MANUAL,
      icon: { name: 'manual', size: 16 },
      iconColor: 'violet',
    },
    {
      name: 'labels.outsourcing',
      value: OrderPlanningMode.OUTSOURCING,
      icon: { name: 'cursor', size: 16 },
      iconColor: 'orange',
    },
  ];

  protected readonly OrderPlanningMode = OrderPlanningMode;
  protected readonly OrderStatus = OrderStatus;

  public order = input.required<Order>();

  protected tab = signal(OrderPlanningMode.AUTO);
  protected selectedManualVehicle = signal<Fleet | null>(null);
  protected vehicle = signal<SimplifiedFleet | null>(null);
  protected trailer = signal<SimplifiedFleet | null>(null);
  protected isSetInvalid = signal<boolean | null>(null);
  protected isEngineCalculating = signal(false);
  protected manualProposalData = signal<ManualProsposalData | null>(null);

  protected selectedManualVehicleControl = this.fb.control<string | null>(null);

  protected searchingVehicleData = computed(() =>
    OrderHelper.getSearchingVehicleData(this.order(), this.translateService),
  );
  protected vehiclesSource = computed(() =>
    this.fleetHelper.getVehiclesSource({
      setsOnly: this.searchingVehicleData().isLowDeck ?? false,
    }),
  );
  protected hasApprovedVehicle = computed(() =>
    OrderHelper.hasApprovedVehicle(this.order().status.value),
  );
  protected hasEngineProposal = computed(
    () =>
      this.order().engineProposalData != null &&
      this.order().status.value === OrderStatus.TO_ACCEPT,
  );

  constructor(
    private injector: Injector,
    private fleetHelper: FleetHelper,
    private translateService: TranslateService,
    private ordersService: OrdersService,
    private toastService: OnyxToastService,
    private fb: NonNullableFormBuilder,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    this.manualProposalData.set(this.order().manualProposalData);

    this.selectedManualVehicleControl.valueChanges
      .pipe(
        map(() => this.selectedManualVehicleControl.getRawValue()),
        skip(this.manualProposalData()?.vehicle ? 2 : 1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((vehicleUuid) => {
        const manualProposal = this.manualProposalData();
        if (!manualProposal || vehicleUuid === manualProposal.vehicle.uuid) {
          return;
        }

        this.ordersService.deleteManualProposal(this.order().uuid).subscribe({
          next: () => this.manualProposalData.set(null),
          error: (error) =>
            ValidationHelper.handleUnexpectedError(error, this.toastService),
        });
      });

    effect(
      () => {
        const manualProposal = this.manualProposalData();
        this.isEngineCalculating.set(manualProposal?.isLoading ?? false);

        if (!this.selectedManualVehicle() && manualProposal?.vehicle?.uuid) {
          this.selectedManualVehicleControl.setValue(
            manualProposal.vehicle.uuid,
          );
        }
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const isAuto = this.tab() === OrderPlanningMode.AUTO;
        const proposalData = isAuto ? this.order().engineProposalData : null;
        const selectedManualVehicle = isAuto
          ? null
          : this.selectedManualVehicle();
        const assignedData =
          !proposalData && !selectedManualVehicle
            ? this.order().assignedData
            : null;

        this.vehicle.set(
          assignedData?.vehicle ??
            proposalData?.vehicle ??
            selectedManualVehicle ??
            null,
        );
        this.trailer.set(
          assignedData?.trailer ??
            proposalData?.trailer ??
            selectedManualVehicle?.trailer ??
            null,
        );
      },
      { injector: this.injector },
    );

    effect(
      () => {
        if (
          this.tab() !== OrderPlanningMode.MANUAL &&
          !this.manualProposalData()
        ) {
          this.selectedManualVehicleControl.setValue(null);
        }
      },
      { injector: this.injector },
    );
  }

  protected calculateVehicle(): void {
    const selectedManualVehicle = this.selectedManualVehicle();
    if (!selectedManualVehicle) return;

    this.ordersService
      .addManualProposal(this.order().uuid, selectedManualVehicle.uuid)
      .subscribe({
        next: (manualProposal) => this.manualProposalData.set(manualProposal),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }

  protected assignVehicle(): void {
    const selectedManualVehicle = this.selectedManualVehicle();
    if (!selectedManualVehicle) return;

    this.ordersService
      .confirmManualProposal(this.order().uuid, selectedManualVehicle.uuid)
      .subscribe({
        next: () =>
          this.toastService.showSuccess('drivers.assignVehicleModal.success'),
        error: (error) =>
          ValidationHelper.handleUnexpectedError(error, this.toastService),
      });
  }
}

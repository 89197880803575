import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ImportProgress } from '../../../common/interfaces/import-progress';
import { DriversImportData } from '../constants/drivers-import-schema';
import { DriversService } from '../services/drivers.service';

export const importDriversResolver: ResolveFn<
  (data: DriversImportData[]) => Observable<ImportProgress>
> = () => {
  const driversService = inject(DriversService);
  return (data) => driversService.importDrivers(data);
};

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxAvatarComponent,
  OnyxBadgeComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxNamePipe,
} from '@onyx/angular';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { DriverHelper } from '../../../../drivers/common/helpers/driver.helper';
import { Driver } from '../../../../drivers/common/interfaces/driver';
import { EmployeeHelper } from '../../../../management-panel/employees/common/helpers/employee.helper';
import { Employee } from '../../../../management-panel/employees/common/interfaces/employee';

@Component({
  selector: 'app-fleet-modal-crew-item',
  imports: [
    OnyxIconComponent,
    OnyxAvatarComponent,
    OnyxBadgeComponent,
    TranslatePipe,
    OnyxIconButtonComponent,
    OnyxNamePipe,
  ],
  templateUrl: './fleet-modal-crew-item.component.html',
  styleUrl: './fleet-modal-crew-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalCrewItemComponent {
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly ActionHelper = ActionHelper;

  public driver = input<Driver | null>();
  public employee = input<Employee | null>();

  constructor(
    private driverHelper: DriverHelper,
    private employeeHelper: EmployeeHelper,
  ) {}

  protected openModal(): void {
    const [driver, employee] = [this.driver(), this.employee()];
    if (driver) {
      this.driverHelper.openModal(driver);
    } else if (employee) {
      this.employeeHelper.openModal(employee);
    }
  }
}

import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { importGuard } from '../common/guards/import.guard';
import { DRIVERS_IMPORT_COLUMNS } from './common/constants/drivers-import-columns';
import { addDriverGuard } from './common/guards/add-driver.guard';
import { addDriverResolver } from './common/resolvers/add-driver.resolver';
import { driverCardResolver } from './common/resolvers/driver-card.resolver';
import { editDriverResolver } from './common/resolvers/edit-driver.resolver';
import { importDriversResolver } from './common/resolvers/import-drivers.resolver';

export enum DriversRoute {
  DRIVERS_LIST = '/drivers',
  ADD_DRIVER = '/drivers/add',
  IMPORT_RESULT = '/drivers/import',
  DRIVER_CARD = '/drivers/:uuid',
  EDIT_DRIVER = '/drivers/:uuid/edit',
}

export const DRIVERS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./drivers-list/drivers-list.component').then(
        (m) => m.DriversListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./driver-form/driver-form.component').then(
        (m) => m.DriverFormComponent,
      ),
    canActivate: [addDriverGuard],
    data: { mode: OnyxFormMode.ADD },
    resolve: { driver: addDriverResolver },
  },
  {
    path: 'import',
    loadComponent: () =>
      import(
        '../common/components/import/import-result/import-result.component'
      ).then((m) => m.ImportResultComponent),
    canActivate: [importGuard],
    data: { columns: DRIVERS_IMPORT_COLUMNS },
    resolve: { import: importDriversResolver },
  },
  {
    path: ':uuid',
    children: [],
    resolve: { driver: driverCardResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./driver-form/driver-form.component').then(
        (m) => m.DriverFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { driver: editDriverResolver },
  },
] as const;

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { OnyxToastService } from '@onyx/angular';
import { catchError, forkJoin, map, of } from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { FleetValidationByType } from '../../../../common/interfaces/validation/fleet-validation';
import { ValidationService } from '../../../../common/services/validation.service';
import { OrderForm } from '../../order-form/order-form.component';
import { OrdersRoute } from '../../orders.routes';
import { OrderHelper } from '../helpers/order.helper';
import { OrdersService } from '../services/orders.service';

export const editOrderResolver: ResolveFn<{
  fleetValidation: FleetValidationByType;
  form: OrderForm;
  identifier: string;
}> = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const ordersService = inject(OrdersService);
  const validationService = inject(ValidationService);
  const toastService = inject(OnyxToastService);

  const uuid = route.paramMap.get('uuid');
  const urlTree = router.parseUrl(OrdersRoute.ORDERS_LIST);

  if (!uuid) {
    return new RedirectCommand(urlTree, { replaceUrl: true });
  }

  return forkJoin([
    ordersService.getOrder(uuid),
    validationService.getFleetValidationByType(),
  ]).pipe(
    map(([dto, fleetValidation]) => {
      if (!OrderHelper.hasEdit(dto)) {
        toastService.showError('toasts.archived', { keepOnNavigation: true });
        return new RedirectCommand(urlTree, { replaceUrl: true });
      }

      return {
        fleetValidation,
        form: OrderHelper.fromDto(dto),
        identifier: dto.identifier,
      };
    }),
    catchError((response: HttpErrorResponse) => {
      if (response.status === HttpStatusCode.BadRequest) {
        toastService.showError('orders.toasts.notFound', {
          keepOnNavigation: true,
        });
      } else {
        ValidationHelper.handleUnexpectedError(response, toastService, {
          keepOnNavigation: true,
        });
      }

      return of(new RedirectCommand(urlTree, { replaceUrl: true }));
    }),
  );
};

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxBadgeComponent,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { ModalNoteComponent } from '../../../common/components/modals/modal-note/modal-note.component';
import { ValidationHelper } from '../../../common/helpers/validation.helper';
import { OrderHelper } from '../common/helpers/order.helper';
import { Order, SimplifiedOrder } from '../common/interfaces/order';
import { OrdersService } from '../common/services/orders.service';
import { OrdersRoute } from '../orders.routes';
import { OrderModalClientComponent } from './order-modal-chips/order-modal-client/order-modal-client.component';
import { OrderModalDetailsComponent } from './order-modal-chips/order-modal-details/order-modal-details.component';
import { OrderModalDocumentsComponent } from './order-modal-chips/order-modal-documents/order-modal-documents.component';
import { OrderModalDriversComponent } from './order-modal-chips/order-modal-drivers/order-modal-drivers.component';
import { OrderModalVehiclesPreviewComponent } from './order-modal-chips/order-modal-vehicles-preview/order-modal-vehicles-preview.component';
import { OrderModalHeadingComponent } from './order-modal-heading/order-modal-heading.component';
import { OrderModalMainSectionComponent } from './order-modal-main-section/order-modal-main-section.component';
import { OrderModalPlanComponent } from './order-modal-plan/order-modal-plan.component';
import { OrderModalRouteComponent } from './order-modal-route/order-modal-route.component';
import { OrderModalStatusesComponent } from './order-modal-statuses/order-modal-statuses.component';

export type OrderModalData = Order | SimplifiedOrder | string;

enum OrderModalSection {
  SUMMARY = 'summary',
  ROUTE = 'route',
  CLIENT = 'client',
  VEHICLES = 'vehicles',
  PROFITABILITY = 'profitability',
  DRIVERS = 'drivers',
  DOCUMENTS = 'documents',
  EXPENSES = 'expenses',
  DETAILS = 'details',
  NOTE = 'note',
}

@Component({
  selector: 'app-order-modal',
  standalone: true,
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxDropdownDirective,
    OnyxTooltipDirective,
    OnyxIconComponent,
    OnyxBadgeComponent,
    TranslatePipe,
    OrderModalHeadingComponent,
    OrderModalMainSectionComponent,
    OnyxChipsComponent,
    OrderModalClientComponent,
    OrderModalVehiclesPreviewComponent,
    OrderModalDriversComponent,
    OrderModalDocumentsComponent,
    OrderModalDetailsComponent,
    ModalNoteComponent,
    OrderModalStatusesComponent,
    OrderModalPlanComponent,
    OrderModalRouteComponent,
  ],
  templateUrl: './order-modal.component.html',
  styleUrl: './order-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalComponent {
  protected readonly I18N = 'orders.orderModal';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly OrdersRoute = OrdersRoute;
  protected readonly OrderModalSection = OrderModalSection;

  private readonly uuid: string;

  protected order = signal<Order | null>(null);
  protected group = signal([OrderModalSection.SUMMARY]);
  protected globalLoading = signal(false);
  protected globalError = signal(false);
  protected loading = signal(false);
  protected showLoading = signal(false);
  protected error = signal(false);
  protected close$ = new Subject<void>();
  protected order$ = new Subject<void>();

  protected options = computed(() => {
    const order = this.order();
    return order
      ? this.orderHelper.getOptions(order, { close$: this.close$ })
      : null;
  });
  protected hasEdit = computed(() => {
    const order = this.order();
    return order ? OrderHelper.hasEdit(order) : false;
  });
  protected chips = computed<OnyxChip<OrderModalSection>[]>(() => [
    { name: 'labels.summary', value: OrderModalSection.SUMMARY },
    { name: 'labels.route', value: OrderModalSection.ROUTE },
    { name: 'labels.client', value: OrderModalSection.CLIENT },
    { name: 'labels.vehicles', value: OrderModalSection.VEHICLES },
    { name: 'labels.profitability', value: OrderModalSection.PROFITABILITY },
    { name: 'labels.drivers', value: OrderModalSection.DRIVERS },
    {
      name: 'labels.documents',
      value: OrderModalSection.DOCUMENTS,
      count: this.order()?.documents.length,
    },
    { name: 'labels.expenses', value: OrderModalSection.EXPENSES },
    { name: 'labels.details', value: OrderModalSection.DETAILS },
    { name: 'labels.note', value: OrderModalSection.NOTE },
  ]);

  constructor(
    @Inject(DIALOG_DATA) protected orderData: OrderModalData,
    protected dialogRef: DialogRef<void>,
    private toastService: OnyxToastService,
    private destroyRef: DestroyRef,
    private ordersService: OrdersService,
    private orderHelper: OrderHelper,
  ) {
    this.uuid = isString(this.orderData) ? this.orderData : this.orderData.uuid;

    this.order$
      .pipe(
        tap(() => this.globalLoading.set(true)),
        switchMap(() =>
          this.ordersService.getOrder(this.uuid).pipe(
            catchError((response) => {
              this.order.set(null);
              this.globalLoading.set(false);
              this.globalError.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (order) => {
          this.order.set(order);
          this.globalLoading.set(false);
          this.globalError.set(false);
        },
      });

    if (isString(this.orderData) || !OrderHelper.isOrderType(this.orderData)) {
      this.order$.next();
    } else {
      this.order.set(this.orderData);
    }

    this.ordersService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.order$.next());
  }

  protected edit(): void {
    this.orderHelper.edit(this.uuid, { close$: this.close$ });
  }

  protected share(): void {
    const order = this.order();
    if (!order) return;

    ActionHelper.copy(
      `${window.location.origin}${OrdersRoute.ORDER_CARD.replace(':uuid', order.uuid)}`,
      this.toastService,
    );
  }
}

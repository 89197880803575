<onyx-widget-box
  [heading]="
    status() === OrderStatus.TO_DO ? 'labels.firstPointEta' : 'labels.eta'
  "
  [color]="pointEstimationDifference().color"
>
  <div class="difference f-paragraph-semibold-2">
    <div class="date">
      {{ pointDeadline().date | onyxDate: 'short-date' }}
      <span>·</span>
      {{ pointDeadline().time | onyxTime }}
    </div>

    <p class="difference-time {{ pointEstimationDifference().color }}">
      {{ pointEstimationDifference().diff }}
    </p>
  </div>
</onyx-widget-box>

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  computed,
  effect,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxButtonComponent,
  OnyxChipsComponent,
  OnyxDatePipe,
  OnyxDropdownDirective,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxModalComponent,
  OnyxPaginated,
  OnyxPhonePipe,
  OnyxTableComponent,
  OnyxTableNotFound,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { cloneDeep, isString } from 'lodash';
import {
  EMPTY,
  Subject,
  catchError,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UnavailabilitiesComponent } from '../../../../../common/components/unavailabilities/unavailabilities.component';
import { SHORT_PAGINATION } from '../../../../../common/constants/short-pagination';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { StatisticsType } from '../../../../../common/enums/statistics-type';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { EmployeeStatistics } from '../../../../../common/interfaces/statistics/employee-statistics';
import { StatisticsService } from '../../../../../common/services/statistics.service';
import { FleetHelper } from '../../../../fleet/common/helpers/fleet.helper';
import { SimplifiedFleet } from '../../../../fleet/common/interfaces/fleet';
import { EmployeeStatusComponent } from '../../common/components/employee-status/employee-status.component';
import { EMPLOYEE_MODAL_CHIPS } from '../../common/constants/employee-modal-chips';
import { EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS } from '../../common/constants/employees-assigned-vehicles-list-columns';
import { EmployeeModalSection } from '../../common/enums/employee-modal-section';
import { EmployeeStatus } from '../../common/enums/employee-status';
import { EmployeeHelper } from '../../common/helpers/employee.helper';
import { Employee, SimplifiedEmployee } from '../../common/interfaces/employee';
import { EmployeesService } from '../../common/services/employees.service';

export type EmployeeModalData = Employee | SimplifiedEmployee | string;

@Component({
  selector: 'app-employee-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    OnyxInformationRowComponent,
    EmployeeStatusComponent,
    OnyxInformationHeadingComponent,
    OnyxDatePipe,
    OnyxTableComponent,
    OnyxChipsComponent,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxPhonePipe,
    UnavailabilitiesComponent,
    OnyxDropdownDirective,
    OnyxTooltipDirective,
  ],
  templateUrl: './employee-modal.component.html',
  styleUrl: './employee-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeModalComponent {
  protected readonly I18N = 'employees.employeeModal';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS =
    EMPLOYEES_ASSIGNED_VEHICLES_LIST_COLUMNS;
  protected readonly ASSIGNED_VEHICLES_NOT_FOUND: OnyxTableNotFound = {
    icon: { name: 'menu-fleet', size: 24 },
    text: `${this.I18N}.assignedVehiclesNotFound`,
  };

  protected readonly ActionHelper = ActionHelper;
  protected readonly EmployeeStatus = EmployeeStatus;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly EmployeeModalSection = EmployeeModalSection;

  private readonly uuid: string;

  protected employee = signal<Employee | null>(null);
  protected chips = signal(cloneDeep(EMPLOYEE_MODAL_CHIPS));
  protected group = signal([this.chips()[0].value]);
  protected loading = signal(false);
  protected error = signal(false);
  protected assignedVehicles = signal<OnyxPaginated<SimplifiedFleet> | null>(
    null,
  );
  protected sectionLoading = signal(false);
  protected pagination = signal(SHORT_PAGINATION);
  protected close$ = new Subject<void>();
  protected employee$ = new Subject<void>();

  protected options = computed(() => {
    const employee = this.employee();
    return employee
      ? this.employeeHelper.getOptions(employee, { close$: this.close$ })
      : null;
  });
  protected hasEdit = computed(() => {
    const employee = this.employee();
    if (!employee) return false;

    return (
      !EmployeeHelper.isArchived(employee) &&
      this.employeeHelper.hasEditPermission(employee)
    );
  });

  private loadAssignedVehicles$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected employeeData: EmployeeModalData,
    protected dialogRef: DialogRef<void>,
    private employeesService: EmployeesService,
    private destroyRef: DestroyRef,
    private toastService: OnyxToastService,
    private statisticsService: StatisticsService,
    private fleetHelper: FleetHelper,
    private employeeHelper: EmployeeHelper,
  ) {
    this.uuid = isString(this.employeeData)
      ? this.employeeData
      : this.employeeData.uuid;

    this.employee$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.employeesService.getEmployee(this.uuid).pipe(
            catchError((response) => {
              this.employee.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (employee) => {
          this.employee.set(employee);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (
      isString(this.employeeData) ||
      !EmployeeHelper.isEmployeeType(this.employeeData)
    ) {
      this.employee$.next();
    } else {
      this.employee.set(this.employeeData);
    }

    this.loadAssignedVehicles$
      .pipe(
        skip(1),
        tap(() => this.sectionLoading.set(true)),
        switchMap(() =>
          this.employeesService.getAssignedVehicles(
            this.uuid,
            this.pagination(),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((assignedVehicles) => {
        this.assignedVehicles.set(assignedVehicles);
        this.sectionLoading.set(false);
      });

    effect(() => {
      this.pagination();
      untracked(() => this.loadAssignedVehicles$.next());
    });

    const getStatistics$ = () =>
      this.statisticsService.getStatistics(StatisticsType.EMPLOYEE, {
        employeeUuid: this.uuid,
      });

    this.employeesService.reload$
      .pipe(
        tap(() => {
          this.employee$.next();
          this.loadAssignedVehicles$.next();
        }),
        startWith(undefined),
        switchMap(() => getStatistics$()),
      )
      .subscribe((statistics) => this.updateChips(statistics));
  }

  protected edit(): void {
    const employee = this.employee();
    if (employee) this.employeeHelper.edit(employee);
  }

  protected openFleetModal(fleet: SimplifiedFleet): void {
    if (fleet) this.fleetHelper.openModal(fleet);
  }

  protected assignVehicles(): void {
    const employee = this.employee();
    if (!employee) return;

    this.employeeHelper.assignVehicles(employee);
  }

  private updateChips(statistics: EmployeeStatistics): void {
    this.chips.update((chips) =>
      chips.map((chip) => ({
        ...chip,
        count: statistics[chip.value],
      })),
    );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  OnyxCallbackOption,
  OnyxDropdownOptionsSource,
  OnyxModalService,
  OnyxToastService,
} from '@onyx/angular';
import { isString } from 'lodash';
import { map } from 'rxjs';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { HelperOptions } from '../../../../../common/interfaces/utilities/helper-options';
import { UniqueField } from '../../../../../common/interfaces/utilities/unique-field';
import {
  ContractorForm,
  ContractorFormDto,
} from '../../contractor-form/contractor-form.component';
import {
  ContractorModalComponent,
  ContractorModalData,
} from '../../contractor-modal/contractor-modal.component';
import { ContractorsRoute } from '../../contractors.routes';
import { BlockContractorModalComponent } from '../components/block-contractor-modal/block-contractor-modal.component';
import { ContractorCategory } from '../enums/contractor-category';
import { ContractorStatus } from '../enums/contractor-status';
import { Contractor, SimplifiedContractor } from '../interfaces/contractor';
import { ContractorsService } from '../services/contractors.service';

@Injectable({
  providedIn: 'root',
})
export class ContractorHelper {
  private readonly I18N = 'contractors';

  constructor(
    private contractorsService: ContractorsService,
    private router: Router,
    private modalService: OnyxModalService,
    private toastService: OnyxToastService,
  ) {}

  public getContractorsSource(
    showOnlyActive = false,
  ): OnyxDropdownOptionsSource<Contractor> {
    return {
      list: (query, limit) =>
        this.contractorsService.searchContractors(query, limit, {
          category: ContractorCategory.ACTIVE,
          status: showOnlyActive ? [ContractorStatus.ACTIVE] : [],
        }),
      get: (uuid) =>
        this.contractorsService.getContractor(uuid).pipe(
          map((contractor) => ({
            name: contractor.companyProfile.displayName,
            value: contractor,
          })),
        ),
      idKey: 'uuid',
    };
  }

  public getOptions(
    contractor: Contractor,
    options: HelperOptions,
  ): OnyxCallbackOption[] {
    const isArchived = ContractorHelper.isArchived(contractor);
    return [
      ...(!isArchived
        ? [
            {
              name: 'buttons.edit',
              value: () => {
                this.edit(contractor.uuid, options);
              },
            },
          ]
        : []),
      ...(contractor.status.value === ContractorStatus.ACTIVE
        ? [
            {
              name: 'buttons.block',
              value: () => this.block(contractor),
            },
            {
              name: 'buttons.archive',
              value: () => this.toggleArchived(contractor, true),
            },
          ]
        : []),
      ...(contractor.status.value === ContractorStatus.BLOCKED
        ? [
            {
              name: 'buttons.unblock',
              value: () => this.unblock(contractor.uuid),
            },
          ]
        : []),
      ...(isArchived
        ? [
            {
              name: 'buttons.unarchive',
              value: () => this.toggleArchived(contractor, false),
            },
          ]
        : []),
    ];
  }

  public openModal(contractor: ContractorModalData): void {
    this.modalService.open<ContractorModalData>(
      ContractorModalComponent,
      contractor,
    );
  }

  public edit(uuid: string, options: HelperOptions): void {
    this.router.navigateByUrl(
      `${ContractorsRoute.EDIT_CONTRACTOR.replace(':uuid', uuid)}`,
    );
    CommonHelper.handleOptions(uuid, options);
  }

  public block(contractor: Contractor): void {
    this.modalService
      .open<Contractor, boolean>(BlockContractorModalComponent, contractor)
      .subscribe();
  }

  public unblock(uuid: string): void {
    this.contractorsService.unblockContractor(uuid).subscribe({
      next: () =>
        this.toastService.showSuccess(
          `${this.I18N}.toasts.contractorUnblocked`,
        ),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  private toggleArchived(contractor: Contractor, force?: boolean): void {
    force ??= contractor.status.value !== ContractorStatus.ARCHIVED;

    const { uuid } = contractor;
    const action$ = force
      ? this.contractorsService.archiveContractor(uuid)
      : this.contractorsService.unarchiveContractor(uuid);
    const toast = force
      ? `${this.I18N}.toasts.contractorArchived`
      : `${this.I18N}.toasts.contractorUnarchived`;

    action$.subscribe({
      next: () => this.toastService.showSuccess(toast),
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  public static isArchived(contractor: Contractor | ContractorStatus): boolean {
    const status = isString(contractor) ? contractor : contractor.status.value;
    return status === ContractorStatus.ARCHIVED;
  }

  public static isContractorType(
    contractor: SimplifiedContractor | Contractor,
  ): contractor is Contractor {
    return 'status' in contractor;
  }

  public static fromDto(dto: Contractor): ContractorForm {
    return {
      ...dto,
      companyProfile: {
        ...dto.companyProfile,
        types: dto.companyProfile.types.map((type) => type.value),
      },
      payments: {
        ...dto.payments,
        tradeCreditLimit: dto.payments
          .tradeCreditLimit as ContractorForm['payments']['tradeCreditLimit'],
      },
      branches: dto.branches.map((branch) => ({
        ...branch,
        vatIdentification: branch.vatIdentification ?? {
          country: null,
          vatId: null,
        },
      })),
      pointOfInterests: {
        selectedPoints: dto.pointOfInterests.map(
          (pointOfInterest) => pointOfInterest.pointOfInterest.uuid,
        ),
        pointOfInterests: dto.pointOfInterests.map((pointOfInterest) => ({
          pointOfInterest: pointOfInterest.pointOfInterest,
          gate: pointOfInterest.gate,
          uuid: pointOfInterest.pointOfInterest.uuid,
        })),
      },
    };
  }

  public static getUniqueFields(dto: ContractorFormDto): UniqueField[] {
    const I18N = 'contractors.contractorForm';
    return [
      {
        values: dto.contactPersons.map(
          (contactPerson) => contactPerson.transId,
        ),
        key: 'transId',
        paramKey: `${I18N}.uniqueErrors.formTransId`,
      },
      {
        values: dto.contactPersons.map(
          (contactPerson) => contactPerson.timocomId,
        ),
        key: 'timocomId',
        paramKey: `${I18N}.uniqueErrors.formTimocomId`,
      },
      {
        values: dto.contactPersons.map((contactPerson) => contactPerson.email),
        key: 'email',
        paramKey: `${I18N}.uniqueErrors.formEmail`,
      },
      {
        values: dto.contactPersons.map(
          (contactPerson) =>
            `${contactPerson.phone?.areaCode} ${contactPerson.phone?.number}`,
        ),
        key: 'phone',
        paramKey: `${I18N}.uniqueErrors.formPhone`,
      },
    ];
  }
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { UpperCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  Inject,
  Injector,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  ONYX_TOOLTIP_DELAY,
  OnyxChip,
  OnyxChipsComponent,
  OnyxDropdownDirective,
  OnyxFormMode,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxMapComponent,
  OnyxMapEvent,
  OnyxMapMarkerType,
  OnyxModalComponent,
  OnyxOverlayPosition,
  OnyxPluralTranslatePipe,
  OnyxTableComponent,
  OnyxToastService,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { isString } from 'lodash';
import { catchError, EMPTY, Subject, switchMap, tap } from 'rxjs';
import { AddressComponent } from '../../../../common/components/address/address.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { ContractorContactsComponent } from '../common/components/contractor-contacts/contractor-contacts.component';
import { getContractorsFormDocumentsColumns } from '../common/constants/contractors-form-documents-columns';
import { ContractorStatus } from '../common/enums/contractor-status';
import { ContractorHelper } from '../common/helpers/contractor.helper';
import {
  Contractor,
  SimplifiedContractor,
} from '../common/interfaces/contractor';
import { ContractorsService } from '../common/services/contractors.service';

export type ContractorModalData = Contractor | SimplifiedContractor | string;

enum ContractorModalSection {
  ORDERS = 'orders',
  ADDRESSES = 'addresses',
  DOCUMENTS = 'documents',
  COMPANY = 'company',
}

@Component({
  selector: 'app-contractor-modal',
  imports: [
    OnyxModalComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    OnyxChipsComponent,
    OnyxTableComponent,
    OnyxTooltipDirective,
    OnyxPluralTranslatePipe,
    OnyxMapComponent,
    AddressComponent,
    OnyxDropdownDirective,
    UpperCasePipe,
    ContractorContactsComponent,
  ],
  templateUrl: './contractor-modal.component.html',
  styleUrl: './contractor-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractorModalComponent implements AfterViewInit {
  protected readonly I18N = 'contractors';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;
  protected readonly COLUMNS = getContractorsFormDocumentsColumns(
    OnyxFormMode.ADD,
  );
  protected readonly GROUP_CHIPS: OnyxChip<ContractorModalSection>[] = [
    { name: 'labels.orders', value: ContractorModalSection.ORDERS },
    {
      name: `labels.addresses`,
      value: ContractorModalSection.ADDRESSES,
    },
    { name: `labels.documents`, value: ContractorModalSection.DOCUMENTS },
    {
      name: `${this.I18N}.aboutCompany`,
      value: ContractorModalSection.COMPANY,
    },
  ];

  protected readonly ActionHelper = ActionHelper;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly ContractorModalSection = ContractorModalSection;
  protected readonly ContractorStatus = ContractorStatus;
  protected readonly OnyxOverlayPosition = OnyxOverlayPosition;

  private readonly map = viewChild(OnyxMapComponent);
  private readonly uuid: string;

  protected contractor = signal<Contractor | null>(null);
  protected group = signal([this.GROUP_CHIPS[0].value]);
  protected loading = signal(false);
  protected error = signal(false);
  protected close$ = new Subject<void>();
  protected contractor$ = new Subject<void>();

  protected options = computed(() => {
    const contractor = this.contractor();
    return contractor
      ? this.contractorHelper.getOptions(contractor, { close$: this.close$ })
      : null;
  });
  protected isArchived = computed(() => {
    const contractor = this.contractor();
    return contractor ? ContractorHelper.isArchived(contractor) : false;
  });

  constructor(
    @Inject(DIALOG_DATA) protected contractorData: ContractorModalData,
    protected dialogRef: DialogRef<void>,
    private contractorsService: ContractorsService,
    private toastService: OnyxToastService,
    private injector: Injector,
    private destroyRef: DestroyRef,
    private contractorHelper: ContractorHelper,
  ) {
    this.uuid = isString(this.contractorData)
      ? this.contractorData
      : this.contractorData.uuid;

    this.contractor$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() =>
          this.contractorsService.getContractor(this.uuid).pipe(
            catchError((response) => {
              this.contractor.set(null);
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (contractor) => {
          this.contractor.set(contractor);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    if (
      isString(this.contractorData) ||
      !ContractorHelper.isContractorType(this.contractorData)
    ) {
      this.contractor$.next();
    } else {
      this.contractor.set(this.contractorData);
    }

    this.contractorsService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.contractor$.next());
  }

  public ngAfterViewInit(): void {
    effect(
      () => {
        const map = this.map();
        const contractor = this.contractor();
        if (!map || !contractor) return;

        map.dispatch(
          new OnyxMapEvent.AddUpdateMarker({
            id: OnyxMapMarkerType.BASE,
            type: OnyxMapMarkerType.BASE,
            coordinates: contractor.addresses.mainAddress.coordinates,
          }),
        );

        const correspondenceAddress =
          contractor.addresses.correspondenceAddress;
        if (correspondenceAddress) {
          map.dispatch(
            new OnyxMapEvent.AddUpdateMarker({
              id: OnyxMapMarkerType.CORRESPONDENCE_ADDRESS,
              type: OnyxMapMarkerType.CORRESPONDENCE_ADDRESS,
              coordinates: correspondenceAddress.coordinates,
            }),
          );
        }

        const branches = contractor.branches;
        if (branches) {
          const points = branches.map((branch, index) => ({
            id: `branch-${index}`,
            coordinates: branch.correspondenceAddress?.coordinates,
            type: OnyxMapMarkerType.POINT,
          }));

          for (const { id, coordinates, type } of points) {
            if (coordinates) {
              map.dispatch(
                new OnyxMapEvent.AddUpdateMarker({
                  id,
                  type,
                  coordinates,
                }),
              );
            }
          }
        }

        map.dispatch(new OnyxMapEvent.FitContent());
      },
      { injector: this.injector },
    );
  }

  protected block(): void {
    const contractor = this.contractor();
    if (!contractor) return;

    this.contractorHelper.block(contractor);
  }

  protected unblock(): void {
    const contractor = this.contractor();
    if (!contractor) return;

    this.contractorHelper.unblock(contractor.uuid);
  }

  protected edit(): void {
    const contractor = this.contractor();
    if (!contractor) return;

    this.contractorHelper.edit(contractor.uuid, { close$: this.close$ });
  }

  protected unarchiveContractor(uuid: string): void {
    this.contractorsService.unarchiveContractor(uuid).subscribe({
      next: () => {
        this.toastService.showSuccess(
          `${this.I18N}.toasts.contractorUnarchived`,
        );
        this.close$.next();
      },
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }
}

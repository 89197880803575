import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxNamePipe,
} from '@onyx/angular';
import { FleetIdentifierPipe } from '../../../../../../common/components/pipes/fleet-identifier.pipe';
import { Order } from '../../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-outsourcing-data',
  standalone: true,
  imports: [
    OnyxDataWidgetComponent,
    OnyxInformationHeadingComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxNamePipe,
    FleetIdentifierPipe,
  ],
  templateUrl: './order-modal-outsourcing-data.component.html',
  styleUrl: './order-modal-outsourcing-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalOutsourcingDataComponent {
  public order = input.required<Order>();
}

<onyx-data-widget
  supheading="labels.kilometers"
  [headingIcon]="{ name: 'road', size: 16, color: 'violet' }"
  [color]="isEmptyDistanceError() ? 'red' : 'gray'"
>
  <span
    heading
    [innerHTML]="totalDistance() | number: '1.2-2' | numberDecoration: 'km'"
  ></span>

  <div class="container">
    <div class="list">
      @if (order().realizationData?.remainingDistance; as remainingDistance) {
        <div class="item f-medium-3">
          <p>{{ 'labels.remaining' | translate }}</p>

          <app-unit-cell
            [value]="remainingDistance"
            [decimalPlaces]="3"
            unit="km"
          >
          </app-unit-cell>
        </div>
      }

      <div class="item f-medium-3">
        <p>{{ I18N + '.loaded' | translate }}</p>

        <app-unit-cell [value]="distance()" [decimalPlaces]="3" unit="km">
        </app-unit-cell>
      </div>

      @if (emptyDistance(); as empty) {
        <div
          class="item f-medium-3"
          [ngClass]="{ error: isEmptyDistanceError() }"
        >
          <p>{{ 'labels.empty' | translate }}</p>

          <app-unit-cell
            [value]="empty"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>

          @if (isEmptyDistanceError()) {
            <onyx-icon
              name="error-circle-empty"
              [size]="14"
              onyxTooltip="labels.emptyDistanceExceeded"
              [onyxTooltipDelay]="TOOLTIP_DELAY"
              onyxTooltipColor="white"
            ></onyx-icon>
          }
        </div>
      }
    </div>

    @if (plannedData(); as plannedData) {
      <div
        class="plan"
        [onyxTooltip]="planTooltip"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        onyxTooltipColor="white"
      >
        <span>{{ 'labels.plan' | translate }}</span>

        <div class="plan-list f-medium-3">
          <app-unit-cell
            [value]="plannedData.totalDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>

          <span>·</span>

          <app-unit-cell
            [value]="plannedData.distance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>

          <span>·</span>

          <app-unit-cell
            [value]="plannedData.emptyDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>
      </div>
    }
  </div>
</onyx-data-widget>

<ng-template #planTooltip>
  @if (plannedData(); as plannedData) {
    <div class="tooltip f-medium-3">
      <p>{{ 'labels.plan' | translate }}</p>

      <div class="tooltip-data">
        <div class="item">
          <p>{{ I18N + '.totalKilometers' | translate }}</p>

          <app-unit-cell
            [value]="plannedData.totalDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>

        <div class="item">
          <p>{{ I18N + '.loaded' | translate }}</p>

          <app-unit-cell
            [value]="plannedData.distance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>

        <div class="item">
          <p>{{ 'labels.empty' | translate }}</p>

          <app-unit-cell
            [value]="plannedData.emptyDistance"
            [decimalPlaces]="3"
            unit="km"
          ></app-unit-cell>
        </div>
      </div>
    </div>
  }
</ng-template>

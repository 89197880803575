import { Pipe, PipeTransform } from '@angular/core';

const FRACTIONAL_PART_PATTERN = /([.,]\d+)$/;

@Pipe({
  name: 'numberDecoration',
})
export class NumberDecorationPipe implements PipeTransform {
  public transform(value: string | null, unit?: string): string {
    if (value == null) return '';

    return value.replace(FRACTIONAL_PART_PATTERN, (fractional) => {
      const unitSpacing = unit ? (unit === '%' ? '' : ' ') : '';
      return `<span class="f-fraction">${fractional}${unitSpacing}${unit ?? ''}</span>`;
    });
  }
}

export enum OrderStatus {
  VEHICLE_SEARCH = 'vehicle-search',
  TO_ACCEPT = 'to-accept',
  TO_DO = 'to-do',
  IN_PROGRESS = 'in-progress',
  COMPLETING_DOCUMENTS = 'completing-documents',
  INVOICE_TO_ISSUE = 'invoice-to-issue',
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  PARTIALLY_PAID = 'partially-paid',
  PAID = 'paid',
  CANCELED = 'canceled',
  ARCHIVED = 'archived',
}

export enum OnyxTableViewMode {
  LIST = 'list',
  MAP = 'map',
}

export enum OnyxTableSelectionType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum OnyxTableRowSize {
  SMALL = 40,
  MEDIUM = 48,
  LARGE = 56,
  XL = 64,
  XXL = 72,
}

export enum OnyxTableColor {
  WHITE = 'white',
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
}

export enum OnyxTableAction {
  EDIT,
  OPEN_PROFILE,
  CALL,
  COPY,
}

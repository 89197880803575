@let size = driverData.size;
<onyx-modal
  type="right"
  [size]="size"
  [heading]="
    driver()
      ? driver()!.driverData.firstName + ' ' + driver()!.driverData.lastName
      : ''
  "
  [loading]="loading()"
  [(error)]="error"
  [roles]="driver() ? [{ value: 'labels.driver', color: 'blue' }] : []"
  [close$]="close$"
  (errorChange)="driver$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container headingLeftOptions>
    @if (driver()) {
      <!-- TEMP: Replace with actual tacho -->
      <onyx-icon name="tacho-offline" [size]="20"></onyx-icon>
    }
  </ng-container>

  <ng-container rightOptions>
    @if (driver(); as driver) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @if (!isArchived()) {
        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          onyxTooltip="buttons.edit"
          [onyxTooltipDelay]="TOOLTIP_DELAY"
          (click)="edit()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      @if (driver.driverData.workPhone; as phone) {
        <onyx-icon-button
          color="blue"
          type="primary"
          size="m"
          [circle]="true"
          onyxTooltip="buttons.call"
          [onyxTooltipDelay]="TOOLTIP_DELAY"
          (click)="ActionHelper.makeCall(phone)"
        >
          <onyx-icon name="telephone" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }
    }
  </ng-container>

  @if (driver(); as driver) {
    <div class="container {{ size }}">
      <onyx-information-row label="labels.vehicle" [size]="size">
        @let vehicle = driver.assignedVehicle;
        @if (vehicle) {
          <div class="vehicle">
            <onyx-icon
              [name]="vehicle.generalInformation.category"
              [size]="18"
            ></onyx-icon>

            {{ vehicle | fleetIdentifier }}
          </div>

          <div class="action">
            <onyx-icon-button
              type="transparent"
              size="s"
              color="black"
              (click)="openVehicleModal(vehicle)"
            >
              <onyx-icon name="side-modal" [size]="16"></onyx-icon>
            </onyx-icon-button>
          </div>
        } @else {
          -
        }
      </onyx-information-row>

      <onyx-information-row class="order" label="labels.order" [size]="size">
        N/A
      </onyx-information-row>

      @let driverData = driver.driverData;

      @if (driver.driverData.workPhone) {
        <onyx-information-row label="labels.workPhone" [size]="size">
          {{ driver.driverData.workPhone | onyxPhone }}

          <div class="action">
            <onyx-icon-button
              type="transparent"
              size="s"
              color="black"
              (click)="copyPhone(driver.driverData.workPhone!)"
            >
              <onyx-icon name="copy" [size]="16"></onyx-icon>
            </onyx-icon-button>
          </div>
        </onyx-information-row>
      }

      @if (driverData.privateEmail; as email) {
        <onyx-information-row label="labels.email" [size]="size">
          {{ email }}

          <div class="action">
            <onyx-icon-button
              type="transparent"
              size="s"
              color="black"
              (click)="copy(email)"
            >
              <onyx-icon name="copy" [size]="16"></onyx-icon>
            </onyx-icon-button>
          </div>
        </onyx-information-row>
      }

      <div class="chips">
        <onyx-chips
          [chips]="GROUP_CHIPS"
          type="secondary"
          [single]="true"
          [wrap]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @switch (group()[0]) {
        @case (DriverModalSection.WORK_TIME) {
          N/A
        }
        @case (DriverModalSection.DELEGATION) {
          N/A
        }
        @case (DriverModalSection.STATISTICS) {
          N/A
        }
        @case (DriverModalSection.UNAVAILABILITIES) {
          <app-unavailabilities [driver]="driver"></app-unavailabilities>
        }
        @case (DriverModalSection.DRIVER_DATA) {
          <app-driver-modal-data
            [driver]="driver"
            [size]="size"
          ></app-driver-modal-data>
        }
        @case (DriverModalSection.DOCUMENTS) {
          <app-driver-modal-documents
            [driver]="driver"
            [size]="size"
          ></app-driver-modal-documents>
        }
        @case (DriverModalSection.NOTE) {
          <p class="note">
            {{ driver.note || '-' }}
          </p>
        }
      }
    </div>
  }
</onyx-modal>

import { DecimalPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TABLE_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxDatePipe,
  OnyxTableComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../../common/components/cells/amount-cell/amount-cell.component';
import { NumberDecorationPipe } from '../../../../../common/components/pipes/number-decoration.pipe';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { VatRate } from '../../../../../common/enums/vat-rate';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { Amount } from '../../../../../common/interfaces/common/amount';
import { ORDER_MODAL_PLAN_FINANCES_COLUMNS } from '../../../common/constants/order-modal-plan-finances-columns';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

export interface SettlementData {
  net: Pick<Amount, 'value' | 'currency'>;
  gross: Pick<Amount, 'value' | 'currency'>;
  vatRate: Pick<Amount, 'value' | 'currency'> & { vatRate: VatRate };
}

@Component({
  selector: 'app-order-modal-plan-finances',
  standalone: true,
  imports: [
    AmountCellComponent,
    OnyxDataWidgetComponent,
    NumberDecorationPipe,
    DecimalPipe,
    TranslatePipe,
    UpperCasePipe,
    OnyxTableComponent,
    OnyxTooltipDirective,
    OnyxDatePipe,
  ],
  templateUrl: './order-modal-plan-finances.component.html',
  styleUrl: './order-modal-plan-finances.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanFinancesComponent {
  protected readonly I18N = 'orders.orderModal.plan';
  protected readonly TOOLTIP_DELAY = ONYX_TABLE_TOOLTIP_DELAY;
  protected readonly ORDER_MODAL_PLAN_FINANCES_COLUMNS =
    ORDER_MODAL_PLAN_FINANCES_COLUMNS;

  protected readonly DictionaryCode = DictionaryCode;

  public order = input.required<Order>();

  protected settlementData = computed<SettlementData[]>(() => {
    const { defaultCurrencyValue, defaultCurrency, value, currency } =
      this.totalOrderPrice();
    const vatRate = this.order().basicInformation.vatRate;
    const vatRateValue = CommonHelper.isNumeric(vatRate) ? +vatRate : null;

    const calculateSettlementEntry = (
      netValue: number,
      currency: string,
    ): SettlementData => {
      const vatAmount = vatRateValue ? (netValue * vatRateValue) / 100 : 0;
      const grossValue = netValue + vatAmount;

      return {
        net: { value: netValue, currency },
        gross: { value: grossValue, currency },
        vatRate: {
          value: vatAmount,
          currency,
          vatRate: vatRate,
        },
      };
    };

    return [
      calculateSettlementEntry(defaultCurrencyValue, defaultCurrency),
      ...(defaultCurrency !== currency
        ? [calculateSettlementEntry(value, currency)]
        : []),
    ];
  });

  protected totalOrderPrice = computed(() =>
    OrderHelper.getTotalPrice(this.order()),
  );
  protected ratePerKm = computed(() => OrderHelper.getRatePerKm(this.order()));
  protected pricePerDay = computed(() =>
    OrderHelper.getPricePerDay(this.order()),
  );
}

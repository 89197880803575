import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxButtonComponent, OnyxIconComponent } from '@onyx/angular';
import { Subject } from 'rxjs';
import { DocumentsFormListComponent } from '../../../../../common/components/forms/documents-form/documents-form-list/documents-form-list.component';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-documents',
  standalone: true,
  imports: [
    OnyxIconComponent,
    OnyxButtonComponent,
    TranslatePipe,
    DocumentsFormListComponent,
  ],
  templateUrl: './order-modal-documents.component.html',
  styleUrl: './order-modal-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalDocumentsComponent {
  public order = input.required<Order>();
  public close$ = input.required<Subject<void>>();

  constructor(private orderHelper: OrderHelper) {}

  protected addDocument(): void {
    this.orderHelper.edit(this.order().uuid, { close$: this.close$() });
  }
}

import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxLanguagePipe,
  OnyxTemperaturePipe,
  OnyxTooltipDirective,
  OverflowHelper,
} from '@onyx/angular';
import { Fleet } from '../../../../dashboard/fleet/common/interfaces/fleet';
import { Order } from '../../../../dashboard/orders/common/interfaces/order';
import { DictionaryCode } from '../../../enums/dictionary-code';
import { CommonHelper } from '../../../helpers/common.helper';
import { JoinPipe } from '../../pipes/join.pipe';

@Component({
  selector: 'app-parameters-cell',
  imports: [
    TranslatePipe,
    OnyxTemperaturePipe,
    OnyxLanguagePipe,
    OnyxTooltipDirective,
    NgTemplateOutlet,
    NgClass,
    JoinPipe,
  ],
  templateUrl: './parameters-cell.component.html',
  styleUrl: './parameters-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParametersCellComponent {
  protected readonly I18N = 'fleet.fleetList.cells.parameters.';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly Object = Object;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OverflowHelper = OverflowHelper;

  public additionalParameters = input.required<
    Fleet['additionalParameters'] | Order['parameters']
  >();

  protected parameters = computed(() =>
    CommonHelper.getSortedParameters(
      this.additionalParameters(),
      this.translateService,
    ),
  );
  protected visibleParameters = computed(() => this.parameters().slice(0, 2));

  constructor(private translateService: TranslateService) {}
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxDataWidgetComponent,
  OnyxInformationHeadingComponent,
} from '@onyx/angular';
import { AddressComponent } from '../../../../common/components/address/address.component';
import { OrderPointCategory } from '../../common/enums/order-point-category';
import { Order } from '../../common/interfaces/order';
import {
  OrderLoadingPoint,
  OrderUnloadingPoint,
} from '../../common/interfaces/order-point';

@Component({
  selector: 'app-order-modal-route',
  standalone: true,
  imports: [
    OnyxDataWidgetComponent,
    OnyxInformationHeadingComponent,
    TranslatePipe,
    AddressComponent,
  ],
  templateUrl: './order-modal-route.component.html',
  styleUrl: './order-modal-route.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalRouteComponent {
  public order = input.required<Order>();

  protected loadingPoints = computed(() =>
    this.getPointsData(OrderPointCategory.LOADING),
  );
  protected unloadingPoints = computed(() =>
    this.getPointsData(OrderPointCategory.UNLOADING),
  );

  protected getPointsData<
    Category extends OrderPointCategory.LOADING | OrderPointCategory.UNLOADING,
    Point = Category extends OrderPointCategory.LOADING
      ? OrderLoadingPoint
      : OrderUnloadingPoint,
  >(
    category: Category,
  ): {
    point: Point;
    count: number;
  } {
    const points = this.order().points.filter(
      (point) => point.category === category,
    );

    if (category === OrderPointCategory.LOADING) {
      return { point: points[0] as Point, count: points.length };
    }
    return {
      point: points.at(-1) as Point,
      count: points.length,
    };
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Amount } from '../interfaces/common/amount';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AmountService extends ApiService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public calculateAmount(
    amount: Pick<Amount, 'date' | 'value' | 'currency' | 'resultCurrency'>,
  ): Observable<Required<Amount>> {
    return this.post(`/amount`, amount);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AddressHelper,
  OnyxAddress,
  OnyxAddressService,
  OnyxAddressType,
  OnyxPaginated,
} from '@onyx/angular';
import { chain } from 'lodash';
import { Observable, of } from 'rxjs';
import { AddressStorageKey } from '../enums/address-storage-key';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService extends ApiService implements OnyxAddressService {
  private recentSearches: OnyxAddress[] = [];

  constructor(protected override http: HttpClient) {
    super(http);

    const recentSearches = localStorage.getItem(
      AddressStorageKey.RECENT_SEARCHES,
    );
    this.recentSearches = JSON.parse(recentSearches ?? '[]');
  }

  public geocode(
    types: OnyxAddressType[],
    query: string,
    limit: number,
  ): Observable<OnyxPaginated<OnyxAddress>> {
    return this.get('/geocode', {
      params: {
        'types[]': types,
        query,
        limit,
      },
    });
  }

  public getRecentSearches(
    types: OnyxAddressType[],
    limit: number,
  ): Observable<OnyxAddress[]> {
    return chain(this.recentSearches)
      .filter((address) => types.includes(address.type))
      .thru((addresses) => of(addresses.slice(0, limit)))
      .value();
  }

  public addToRecentSearches(address: OnyxAddress): Observable<void> {
    this.recentSearches = chain(this.recentSearches)
      .unshift(address)
      .uniqBy((address) => AddressHelper.composeCoordinatesLabel(address))
      .value();

    localStorage.setItem(
      AddressStorageKey.RECENT_SEARCHES,
      JSON.stringify(this.recentSearches),
    );

    return of(undefined);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnyxTimePipe } from '@onyx/angular';
import { BusinessHours } from '../../interfaces/common/business-hours';

@Pipe({
  name: 'businessHours',
})
export class BusinessHoursPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private timePipe: OnyxTimePipe,
  ) {}

  public transform(
    businessHours: BusinessHours,
    key?: keyof BusinessHours,
    format?: 'short' | 'long',
    only?: 'label' | 'value',
  ): string {
    format ??= 'short';

    const formatRange = (key: keyof BusinessHours): string => {
      const label = this.translateService.instant(
        `labels.${key}${format === 'short' ? 'Shortcut' : ''}`,
      );
      if (only === 'label') return label;

      const value = businessHours[key]
        ? this.timePipe.transform(businessHours[key])
        : this.translateService.instant('labels.closed');
      if (only === 'value') return value;

      return `${label}: ${value}`;
    };

    if (key) return formatRange(key);

    const weekday = formatRange('weekday');
    const saturday = formatRange('saturday');
    const sunday = formatRange('sunday');

    return `${weekday}, ${saturday}, ${sunday}`;
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { OnyxTime } from '../components';

export const onyxMinTimeValidator =
  (min: DateTime | OnyxTime = DateTime.now()): ValidatorFn =>
  (control: AbstractControl<OnyxTime>): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    const time = DateTime.fromFormat(value, 'HH:mm:ss');
    const minTime = isString(min) ? DateTime.fromFormat(min, 'HH:mm:ss') : min;

    return time < minTime ? { minTime: minTime.toFormat('HH:mm') } : null;
  };

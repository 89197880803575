<section>
  <onyx-information-heading>
    {{ I18N + '.proposal' | translate }}
  </onyx-information-heading>

  <div class="decision-buttons">
    <onyx-button
      color="red"
      [onyxDropdownOptions]="rejectOptions$ | async"
      onyxDropdownWidth="l"
      (onyxDropdownValueChange)="$event?.()"
    >
      {{ 'buttons.reject' | translate }}
    </onyx-button>

    <onyx-button
      color="violet"
      [onyxDropdownOptions]="changeOptions$ | async"
      onyxDropdownWidth="l"
      (onyxDropdownValueChange)="$event?.()"
    >
      {{ I18N + '.changeVehicle' | translate }}
    </onyx-button>

    <onyx-button class="accept" color="green" (click)="accept()">
      {{ 'buttons.accept' | translate }}
    </onyx-button>
  </div>
</section>

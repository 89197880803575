import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxAddress } from '@onyx/angular';
import {
  AddressComponent,
  AddressFormat,
} from '../../address/address.component';

@Component({
  selector: 'app-address-cell',
  imports: [AddressComponent],
  templateUrl: './address-cell.component.html',
  styleUrl: './address-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCellComponent {
  public address = input.required<OnyxAddress | null>();
  public format = input.required<AddressFormat>();
  public showFlag = input(true);
}

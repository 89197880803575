<onyx-modal
  type="right"
  size="m"
  [heading]="
    order() ? ('labels.order' | translate) + ' ' + order()?.identifier : ''
  "
  [loading]="globalLoading()"
  [(error)]="globalError"
  [extend]="true"
  [close$]="close$"
  (errorChange)="order$.next()"
  (closeModal)="dialogRef.close()"
>
  <ng-container rightOptions>
    @if (order()) {
      <onyx-icon-button
        color="black"
        type="transparent"
        size="m"
        [onyxDropdownOptions]="options()"
        [onyxDropdownDisabled]="!options()?.length"
        (onyxDropdownValueChange)="$event?.()"
        onyxTooltip="labels.options"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="$event.stopPropagation()"
      >
        <onyx-icon name="options-horizontal" [size]="16"></onyx-icon>
      </onyx-icon-button>

      @if (hasEdit()) {
        <onyx-icon-button
          color="black"
          type="outlined"
          size="m"
          [circle]="true"
          onyxTooltip="buttons.edit"
          [onyxTooltipDelay]="TOOLTIP_DELAY"
          (click)="edit()"
        >
          <onyx-icon name="edit" [size]="16"></onyx-icon>
        </onyx-icon-button>
      }

      <onyx-icon-button
        color="blue"
        type="primary"
        size="m"
        [circle]="true"
        onyxTooltip="buttons.share"
        [onyxTooltipDelay]="TOOLTIP_DELAY"
        (click)="share()"
      >
        <onyx-icon name="share" [size]="16"></onyx-icon>
      </onyx-icon-button>
    }
  </ng-container>

  <ng-container leftOptions>
    @if (order(); as order) {
      @if (order.parameters.sentNotificationNumber) {
        <onyx-badge color="light-blue">
          {{ 'labels.sentNotificationShortcut' | translate }}
        </onyx-badge>
      }

      <onyx-icon
        class="ftl"
        [name]="order.parameters.isDedicated ? 'ftl' : 'ltl'"
        [size]="16"
      ></onyx-icon>
    }
  </ng-container>

  <ng-container bottomOptions>
    @if (order(); as order) {
      <app-order-modal-heading [order]="order"></app-order-modal-heading>
    }
  </ng-container>

  @if (order(); as order) {
    <div class="container">
      <app-order-modal-statuses [order]="order"></app-order-modal-statuses>

      <app-order-modal-main-section
        [order]="order"
      ></app-order-modal-main-section>

      <div class="chips-container">
        <onyx-chips
          [chips]="chips()"
          type="secondary"
          [single]="true"
          [wrap]="true"
          [gap]="8"
          [(activeValues)]="group"
        ></onyx-chips>
      </div>

      @switch (group()[0]) {
        @case (OrderModalSection.SUMMARY) {
          <app-order-modal-route [order]="order"></app-order-modal-route>

          <app-order-modal-plan [order]="order"></app-order-modal-plan>
        }
        @case (OrderModalSection.ROUTE) {
          N/A
        }
        @case (OrderModalSection.CLIENT) {
          <app-order-modal-client [order]="order"></app-order-modal-client>
        }
        @case (OrderModalSection.VEHICLES) {
          <app-order-modal-vehicles-preview
            [order]="order"
          ></app-order-modal-vehicles-preview>
        }
        @case (OrderModalSection.PROFITABILITY) {
          N/A
        }
        @case (OrderModalSection.DRIVERS) {
          <app-order-modal-drivers [order]="order"></app-order-modal-drivers>
        }
        @case (OrderModalSection.DOCUMENTS) {
          <app-order-modal-documents
            [order]="order"
            [close$]="close$"
          ></app-order-modal-documents>
        }
        @case (OrderModalSection.EXPENSES) {
          N/A
        }
        @case (OrderModalSection.DETAILS) {
          <app-order-modal-details [order]="order"></app-order-modal-details>
        }
        @case (OrderModalSection.NOTE) {
          <app-modal-note
            [note]="order.note"
            [route]="OrdersRoute.EDIT_ORDER.replace(':uuid', order.uuid)"
            [close$]="close$"
          ></app-modal-note>
        }
      }
    </div>
  }
</onyx-modal>

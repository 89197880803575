import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isString } from 'lodash';
import { DateTime } from 'luxon';
import { OnyxDate } from '../components';

export const onyxMinDateValidator =
  (minDate = DateTime.now()): ValidatorFn =>
  (control: AbstractControl<string | OnyxDate>): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    let date: DateTime;
    if (isString(value)) {
      date = DateTime.fromISO(value)!;
    } else if (value.date) {
      date = DateTime.fromISO(value.date);
    } else {
      date = DateTime.fromISO(value.from!);
    }

    date = date.startOf('day');
    minDate = minDate.startOf('day');

    return date < minDate ? { minDate: minDate.toISODate() } : null;
  };

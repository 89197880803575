import { FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { OnyxTime } from '../components';
import { onyxMinDateValidator } from './onyx-min-date.validator';
import { onyxMinTimeValidator } from './onyx-min-time.validator';

export const onyxMinDateTimeValidator =
  (
    minDate = DateTime.now(),
    minTime: DateTime | OnyxTime = DateTime.now(),
    dateControlName = 'date',
    timeControlName = 'time',
  ) =>
  (form: FormGroup): void => {
    const dateControl = form.get(dateControlName);
    const timeControl = form.get(timeControlName);
    if (!dateControl?.valid || !timeControl?.valid) return;

    const dateError = onyxMinDateValidator(minDate)(dateControl);
    dateControl.setErrors(dateError ?? null);

    if (dateControl.invalid) return;

    const date = DateTime.fromISO(dateControl.getRawValue())!;
    if (date.startOf('day') > minDate.startOf('day')) {
      timeControl.setErrors(null);
      return;
    }

    const timeError = onyxMinTimeValidator(minTime)(timeControl);
    timeControl.setErrors(timeError ?? null);
  };

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxModalComponent,
  OnyxPhone,
  OnyxPhoneNumberInputComponent,
  onyxPhoneValidator,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { Subject } from 'rxjs';
import { ValidationHelper } from '../../../../../../common/helpers/validation.helper';
import { DictionariesService } from '../../../../../../common/services/dictionaries.service';
import { EmployeeRole } from '../../enums/employee-role';
import { Employee } from '../../interfaces/employee';
import { EmployeesService } from '../../services/employees.service';

export type EditEmployeeModalData = Employee;

export type EditEmployeeModalForm = ReturnType<
  ReturnType<EditEmployeeModalComponent['buildForm']>['getRawValue']
>;

@Component({
  selector: 'app-add-employee',
  imports: [
    OnyxButtonComponent,
    OnyxTextFieldComponent,
    OnyxDropdownComponent,
    OnyxModalComponent,
    OnyxButtonComponent,
    AsyncPipe,
    TranslatePipe,
    ReactiveFormsModule,
    OnyxPhoneNumberInputComponent,
  ],
  templateUrl: './edit-employee-modal.component.html',
  styleUrl: './edit-employee-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEmployeeModalComponent {
  protected readonly I18N = 'employees.editEmployee';

  protected readonly employeeRoles$ =
    this.dictionariesService.getAvailableEmployeeRoles();

  protected close$ = new Subject<void>();
  protected form = this.buildForm();
  protected loading = signal(false);

  constructor(
    @Inject(DIALOG_DATA) protected employee: EditEmployeeModalData,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private employeesService: EmployeesService,
    private toastService: OnyxToastService,
  ) {
    this.form.setValue({
      email: this.employee.email,
      firstName: this.employee.firstName,
      lastName: this.employee.lastName,
      phone: this.employee.phone,
      roles: this.employee.roles.map((role) => role.value),
    });
  }

  protected archiveEmployee(): void {
    this.employeesService.archiveEmployee(this.employee.uuid).subscribe({
      next: () => {
        this.toastService.showSuccess('employees.toasts.employeeArchived');
        this.close$.next();
      },
      error: (response) =>
        ValidationHelper.handleUnexpectedError(response, this.toastService),
    });
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();

    this.loading.set(true);
    this.employeesService
      .editEmployee(this.employee.uuid, form)
      .subscribe({
        next: () => {
          this.toastService.showSuccess('labels.updatedData');
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected buildForm() {
    return this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      phone: this.fb.control<OnyxPhone | null>(null, [
        Validators.required,
        onyxPhoneValidator,
      ]),
      email: this.fb.control({ value: '', disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      roles: this.fb.control<EmployeeRole[]>([], [Validators.required]),
    });
  }
}

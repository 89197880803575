import { LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDatePipe, OnyxInformationRowComponent } from '@onyx/angular';
import { AmountCellComponent } from '../../../../common/components/cells/amount-cell/amount-cell.component';
import { CountryCellComponent } from '../../../../common/components/cells/country-cell/country-cell.component';
import { ModalDateRowComponent } from '../../../../common/components/modal-documents/modal-date-row/modal-date-row.component';
import { ModalDocumentsHeadingComponent } from '../../../../common/components/modal-documents/modal-documents-heading/modal-documents-heading.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { ColorHelper } from '../../../../common/helpers/color.helper';
import { ContractType } from '../../common/enums/contract-type';
import { DriverSettlementMethod } from '../../common/enums/driver-settlement-method';
import { Driver } from '../../common/interfaces/driver';
import { DriverModalData } from '../driver-modal.component';

@Component({
  selector: 'app-driver-modal-documents',
  imports: [
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxDatePipe,
    CountryCellComponent,
    AmountCellComponent,
    ModalDocumentsHeadingComponent,
    LowerCasePipe,
    ModalDateRowComponent,
  ],
  templateUrl: './driver-modal-documents.component.html',
  styleUrl: './driver-modal-documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalDocumentsComponent {
  protected readonly ColorHelper = ColorHelper;
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly DriverSettlementMethod = DriverSettlementMethod;
  protected readonly ContractType = ContractType;

  public driver = input.required<Driver>();
  public size = input.required<DriverModalData['size']>();
}

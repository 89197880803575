<onyx-information-heading>
  @if (subheading(); as subheading) {
    {{ subheading | translate }}
  }

  {{ heading() | translate }}

  <app-modal-documents-buttons
    action
    [scans]="convertedScans()"
  ></app-modal-documents-buttons>
</onyx-information-heading>

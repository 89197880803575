import { DecimalPipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../../common/components/cells/amount-cell/amount-cell.component';
import { NumberDecorationPipe } from '../../../../../common/components/pipes/number-decoration.pipe';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-tolls',
  standalone: true,
  imports: [
    AmountCellComponent,
    OnyxDataWidgetComponent,
    TranslatePipe,
    NumberDecorationPipe,
    DecimalPipe,
    UpperCasePipe,
    OnyxTooltipDirective,
  ],
  templateUrl: './order-modal-plan-tolls.component.html',
  styleUrl: './order-modal-plan-tolls.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanTollsComponent {
  protected readonly I18N = 'orders.orderModal.plan';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  public order = input.required<Order>();

  protected tolls = computed(
    () => this.order().realizationData?.tolls ?? this.order().route?.tolls ?? 0,
  );
  protected otherCosts = computed(
    () =>
      this.order().realizationData?.otherCosts ??
      this.order().route.otherCosts ??
      0,
  );
  protected tollsTotalPrice = computed(
    () =>
      (this.tolls().defaultCurrencyValue +
        this.otherCosts().defaultCurrencyValue) /
      100,
  );

  protected plannedData = computed(() => {
    const { status, route } = this.order();
    if (OrderHelper.isOrderPending(status.value)) return;

    const tolls = route.tolls;
    const otherCosts = route.otherCosts;

    return {
      total: {
        value: tolls.defaultCurrencyValue + otherCosts.defaultCurrencyValue,
        currency: tolls.defaultCurrency,
      },
      tolls,
      otherCosts,
    };
  });
}

import { Pipe, PipeTransform } from '@angular/core';
import { PickDeep } from 'type-fest';
import { Fleet } from '../../../dashboard/fleet/common/interfaces/fleet';

@Pipe({
  name: 'fleetIdentifier',
})
export class FleetIdentifierPipe implements PipeTransform {
  public transform(
    identifier:
      | PickDeep<
          Fleet,
          | 'generalInformation.sideNumber'
          | 'generalInformation.registrationNumber'
        >
      | Pick<Fleet['generalInformation'], 'sideNumber' | 'registrationNumber'>
      | null,
    format: 'dot' | 'bracket' | 'short' = 'dot',
  ): string {
    if (!identifier) return '';

    const { sideNumber, registrationNumber } =
      'generalInformation' in identifier
        ? identifier.generalInformation
        : identifier;
    if (!sideNumber) return registrationNumber;

    return {
      ['dot']: `${registrationNumber} · ${sideNumber}`,
      ['bracket']: `${registrationNumber} (${sideNumber})`,
      ['short']: sideNumber || registrationNumber,
    }[format];
  }
}

import { Routes } from '@angular/router';
import { OnyxFormMode } from '@onyx/angular';
import { importGuard } from '../../common/guards/import.guard';
import { CONTRACTORS_IMPORT_COLUMNS } from './common/constants/contractors-import-columns';
import { addContractorGuard } from './common/guards/add-contractor.guard';
import { addContractorResolver } from './common/resolvers/add-contractor.resolver';
import { editContractorResolver } from './common/resolvers/edit-contractor.resolver';
import { importContractorsResolver } from './common/resolvers/import-contractors.resolver';

export enum ContractorsRoute {
  CONTRACTORS_LIST = '/management-panel/contractors',
  ADD_CONTRACTOR = '/management-panel/contractors/add',
  IMPORT_RESULT = '/management-panel/contractors/import',
  EDIT_CONTRACTOR = '/management-panel/contractors/:uuid/edit',
}

export const CONTRACTORS_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    loadComponent: () =>
      import('./contractors-list/contractors-list.component').then(
        (m) => m.ContractorsListComponent,
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./contractor-form/contractor-form.component').then(
        (m) => m.ContractorFormComponent,
      ),
    canActivate: [addContractorGuard],
    data: { mode: OnyxFormMode.ADD },
    resolve: { contractor: addContractorResolver },
  },
  {
    path: 'import',
    loadComponent: () =>
      import(
        '../../common/components/import/import-result/import-result.component'
      ).then((m) => m.ImportResultComponent),
    canActivate: [importGuard],
    data: { columns: CONTRACTORS_IMPORT_COLUMNS },
    resolve: { import: importContractorsResolver },
  },
  {
    path: ':uuid/edit',
    loadComponent: () =>
      import('./contractor-form/contractor-form.component').then(
        (m) => m.ContractorFormComponent,
      ),
    data: { mode: OnyxFormMode.EDIT },
    resolve: { contractor: editContractorResolver },
  },
] as const;

<onyx-information-heading>
  <div class="outsourcing-heading">
    <onyx-icon name="cursor" [size]="16"></onyx-icon>

    {{ 'labels.outsourcing' | translate }}
  </div>
</onyx-information-heading>

@let outsourcingData = order().outsourcingData!;
<div class="container">
  <onyx-data-widget supheading="labels.contractor">
    <p heading>{{ outsourcingData.contractor.companyProfile.displayName }}</p>
  </onyx-data-widget>

  <onyx-data-widget supheading="labels.contactPerson">
    <p heading>
      @if (outsourcingData.contactPerson; as contactPerson) {
        {{ contactPerson | onyxName }}
      } @else {
        -
      }
    </p>
  </onyx-data-widget>

  <onyx-data-widget supheading="labels.vehicles">
    <div heading class="wrapper">
      <p>{{ outsourcingData.vehicleRegistrationNumber }}</p>

      <p>
        @if (
          outsourcingData.trailerRegistrationNumber;
          as trailerRegistrationNumber
        ) {
          {{ trailerRegistrationNumber }}
        } @else if (order().basicInformation.loadedSemiTrailer) {
          {{ order().basicInformation.loadedSemiTrailer | fleetIdentifier }}
        }
      </p>
    </div>
  </onyx-data-widget>

  <onyx-data-widget supheading="labels.drivers">
    <div heading class="wrapper">
      <p>{{ outsourcingData.primaryDriver.name }}</p>

      @if (outsourcingData.secondaryDriver; as secondaryDriver) {
        <p>{{ secondaryDriver.name }}</p>
      }
    </div>
  </onyx-data-widget>
</div>

<section>
  @if (!hasApprovedVehicle()) {
    @if (!hasEngineProposal() || tab() !== OrderPlanningMode.AUTO) {
      <onyx-information-heading>
        {{ I18N + '.vehicleChoice' | translate }}
      </onyx-information-heading>

      <onyx-tabs
        [tabs]="ORDER_PLANNING_TABS"
        size="m"
        [(value)]="tab"
        [fill]="true"
      ></onyx-tabs>
    }
  }

  @if (!hasApprovedVehicle()) {
    @switch (tab()) {
      @case (OrderPlanningMode.AUTO) {
        @if (hasEngineProposal()) {
          <app-order-modal-decision-buttons
            [(tab)]="tab"
            [order]="order()"
          ></app-order-modal-decision-buttons>
        } @else {
          <app-not-found-pulse
            heading="orders.ordersList.cells.searchingVehicle"
            description="orders.orderModal.engineChoosesBestVehicle"
          ></app-not-found-pulse>
        }
      }
      @case (OrderPlanningMode.MANUAL) {
        <div class="wrapper">
          <onyx-dropdown
            [formControl]="selectedManualVehicleControl"
            [options]="vehiclesSource()"
            (selectedValueChange)="selectedManualVehicle.set($event)"
          ></onyx-dropdown>

          <onyx-button
            [color]="
              manualProposalData()?.result?.ok === false || isSetInvalid()
                ? 'red'
                : 'violet'
            "
            [disabled]="isEngineCalculating() || !selectedManualVehicle()"
            (click)="
              manualProposalData()?.result?.ok != null
                ? assignVehicle()
                : calculateVehicle()
            "
          >
            {{
              (!!manualProposalData() ? 'buttons.confirm' : 'buttons.calculate')
                | translate
            }}
          </onyx-button>
        </div>

        @if (isEngineCalculating()) {
          <app-not-found-pulse
            [heading]="I18N + '.engineCalculating'"
            [description]="I18N + '.upToMinute'"
            height="180px"
          ></app-not-found-pulse>
        }
      }
      @case (OrderPlanningMode.OUTSOURCING) {
        <app-order-modal-outsourcing-form
          [order]="order()"
        ></app-order-modal-outsourcing-form>
      }
    }
  }

  @if (
    tab() !== OrderPlanningMode.OUTSOURCING &&
    (!hasApprovedVehicle() || !order().outsourcingData)
  ) {
    <app-order-modal-vehicles
      [order]="order()"
      [vehicle]="vehicle()"
      [trailer]="trailer()"
      [cabotages]="
        manualProposalData()?.cabotages || order().engineProposalData?.cabotages
      "
      (invalidChange)="isSetInvalid.set($event)"
    ></app-order-modal-vehicles>

    @let drivers =
      order().assignedData?.drivers ||
      (tab() === OrderPlanningMode.AUTO &&
        order().engineProposalData?.drivers) ||
      (tab() === OrderPlanningMode.MANUAL &&
        selectedManualVehicle()?.drivers) ||
      null;

    @if (vehicle()) {
      <app-drivers-times
        [primaryDriver]="drivers?.primaryDriver"
        [secondaryDriver]="drivers?.secondaryDriver"
      ></app-drivers-times>
    }
  }

  @if (hasApprovedVehicle() && order().outsourcingData) {
    <app-order-modal-outsourcing-data
      [order]="order()"
    ></app-order-modal-outsourcing-data>
  }
</section>

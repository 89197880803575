import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  ResolveFn,
  Router,
} from '@angular/router';
import { FleetRoute } from '../../fleet.routes';
import { FleetHelper } from '../helpers/fleet.helper';

export const fleetCardResolver: ResolveFn<void> = (
  route: ActivatedRouteSnapshot,
) => {
  const fleetHelper = inject(FleetHelper);
  const router = inject(Router);

  const uuid = route.paramMap.get('uuid')!;
  fleetHelper.openModal(uuid);

  const urlTree = router.parseUrl(FleetRoute.FLEET_LIST);
  return new RedirectCommand(urlTree, { replaceUrl: true });
};

import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxLogoComponent } from '@onyx/angular';

@Component({
  selector: 'app-not-found-pulse',
  standalone: true,
  imports: [OnyxLogoComponent, TranslatePipe, NgStyle],
  templateUrl: './not-found-pulse.component.html',
  styleUrl: './not-found-pulse.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPulseComponent {
  public heading = input.required<string>();
  public description = input.required<string>();
  public height = input<`${string}px`>('150px');
}

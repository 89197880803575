import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'onyx-logo',
  imports: [],
  templateUrl: './onyx-logo.component.html',
  styleUrl: './onyx-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxLogoComponent {
  public type = input.required<'horizontal' | 'vertical' | 'signet'>();
  public theme = input.required<'light' | 'dark'>();
}

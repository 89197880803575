@if (realizationData(); as realizationData) {
  <onyx-widget-box
    [heading]="realizationData.heading"
    [color]="realizationData.isPast ? 'red' : 'blue'"
  >
    @let status = this.delegation().status.value;
    @let isPlanned = status === DelegationStatus.PLANNED;
    <div class="details">
      @if (
        delegation().hasDailyReturns &&
        !isPlanned &&
        status !== DelegationStatus.CANCELED
      ) {
        <onyx-dropdown
          [options]="dailyReturnsDropdownOptions()"
          [(value)]="selectedDate"
          [ribbon]="true"
          [transparentRibbon]="true"
          width="max-content"
        ></onyx-dropdown>
      }

      <p
        class="content f-paragraph-semibold-2"
        [ngClass]="{ red: realizationData.isPast }"
      >
        {{ realizationData.isPast && isPlanned ? '+' : ''
        }}{{ realizationData.content }}
      </p>
    </div>
  </onyx-widget-box>
}

@if (contractors(); as contractors) {
  @for (contractor of contractors; track contractor.contractor.uuid) {
    <onyx-tag
      color="light-gray"
      (click)="openModal(contractor.contractor); $event.stopPropagation()"
    >
      {{ contractor.contractor.companyProfile.displayName }}

      @if (contractor.gate) {
        ({{ 'labels.gate' | translate | lowercase }} {{ contractor.gate }})
      }
    </onyx-tag>
  }
} @else {
  -
}

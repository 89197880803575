@let standardRealization = hasApprovedVehicle() && !order().outsourcingData;
<div
  class="statuses"
  [ngClass]="{ 'outsourcing-realization': !standardRealization }"
>
  <app-order-modal-status
    [order]="order()"
    [color]="pointEstimationDifference().color"
    [point]="point()"
  ></app-order-modal-status>

  @let status = order().status.value;
  @if (!standardRealization) {
    <app-order-modal-loading-deadline
      [order]="order()"
    ></app-order-modal-loading-deadline>
  }

  @if (standardRealization) {
    <app-order-modal-eta
      [pointDeadline]="pointDeadline()"
      [pointEstimationDifference]="pointEstimationDifference()!"
      [status]="status"
    ></app-order-modal-eta>
  }

  @if (hasApprovedVehicle()) {
    <app-order-modal-point-status
      [point]="point()"
    ></app-order-modal-point-status>
  }

  @if (standardRealization) {
    <app-order-modal-point-time-windows
      [point]="point()"
    ></app-order-modal-point-time-windows>
  }
</div>

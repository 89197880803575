import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxIconComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { Cabotages } from '../../../../../common/interfaces/common/cabotages';
import { Fleet, SimplifiedFleet } from '../../interfaces/fleet';

@Component({
  selector: 'app-fleet-description-parameters',
  standalone: true,
  imports: [TranslatePipe, OnyxIconComponent, NgClass, OnyxTooltipDirective],
  templateUrl: './fleet-description-parameters.component.html',
  styleUrl: './fleet-description-parameters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetDescriptionParametersComponent {
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly DictionaryCode = DictionaryCode;

  public fleet = input.required<Fleet | SimplifiedFleet>();
  public cabotages = input<Cabotages>();
}

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Inject,
  OnInit,
  signal,
} from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  OnyxBadgeComponent,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalComponent,
  OnyxToastService,
} from '@onyx/angular';
import { intersectionBy, union } from 'lodash';
import { Subject } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { EmployeeHelper } from '../../../../management-panel/employees/common/helpers/employee.helper';
import { Employee } from '../../../../management-panel/employees/common/interfaces/employee';
import { Fleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

export type FleetAssignEmployeesModalData = Fleet[];

@Component({
  selector: 'app-fleet-assign-employees-modal',
  imports: [
    OnyxModalComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxBadgeComponent,
    OnyxIconComponent,
    FleetIdentifierPipe,
    OnyxIconButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './fleet-assign-employees-modal.component.html',
  styleUrl: './fleet-assign-employees-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetAssignEmployeesModalComponent implements OnInit {
  protected readonly I18N = 'fleet.assignEmployeesModal';
  protected readonly EMPLOYEES_SOURCE =
    this.employeeHelper.getEmployeesSource();

  protected form = this.buildForm();
  protected employee = signal<Employee | null>(null);
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  protected isSingle = computed(() => this.vehicles.length === 1);

  constructor(
    @Inject(DIALOG_DATA) protected vehicles: FleetAssignEmployeesModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private toastService: OnyxToastService,
    private fleetService: FleetService,
    private translateService: TranslateService,
    private employeeHelper: EmployeeHelper,
  ) {}

  public ngOnInit(): void {
    if (this.vehicles.length > 1) {
      const allEmployees = this.vehicles
        .map((vehicle) => vehicle.assignedEmployees)
        .flat();

      const commonEmployees = intersectionBy(allEmployees, 'uuid');
      const selectedEmployees =
        commonEmployees.length > 0 ? commonEmployees : union(allEmployees);

      this.form.controls.employees.setValue(
        selectedEmployees.map((employee) => employee!.uuid),
      );

      this.toastService.showInformation(
        commonEmployees.length > 0
          ? `${this.I18N}.commonEmployess`
          : `${this.I18N}.allEmployees`,
      );
    } else if (this.vehicles[0]?.assignedEmployees) {
      const uuids = this.vehicles[0]?.assignedEmployees.map(
        (employee) => employee.uuid,
      );
      this.form.controls.employees.setValue(uuids);
    }
  }

  protected openModal(employee: Employee | null): void {
    if (employee) this.employeeHelper.openModal(employee);
  }

  protected submit(): void {
    if (!ValidationHelper.checkValidity(this.form, this.toastService)) return;

    const form = this.form.getRawValue();
    const action$ = this.isSingle()
      ? this.fleetService.assignEmployees(this.vehicles[0].uuid, form)
      : this.fleetService.batchFleet(
          this.vehicles.map((vehicle) => vehicle.uuid),
          { assignedEmployees: form.employees },
        );

    this.loading.set(true);
    action$
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            this.translateService.instant(`${this.I18N}.assignedEmployees`, {
              value: form.employees.length,
            }),
          );
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      employees: this.fb.control<string[]>([]),
    });
  }
}

import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnChanges,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, Validators } from '@angular/forms';
import { castArray } from 'lodash';
import { Observable, Subject, takeUntil } from 'rxjs';
import { OnyxMaybeArray } from '../../../interfaces';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxInputLabelComponent } from '../../labels/onyx-input-label/onyx-input-label.component';
import { OnyxTooltipContext } from '../../tooltip';

@Component({
  selector: 'onyx-form-group',
  imports: [NgStyle, OnyxFormControlErrorComponent, OnyxInputLabelComponent],
  templateUrl: './onyx-form-group.component.html',
  styleUrl: './onyx-form-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxFormGroupComponent implements OnChanges {
  public type = input<'label' | 'title'>('label');
  public label = input('');
  public hint = input<OnyxTooltipContext>();
  public width = input('100%');
  public controls = input<OnyxMaybeArray<AbstractControl>>();
  public gap = input(6);
  public errors = input(true);
  public errorsAlign = input<'left' | 'right'>('left');

  public labelClick = output<void>();

  private optional_ = signal(false);
  protected get optional() {
    return this.optional_.asReadonly();
  }

  private disabled_ = signal(false);
  protected get disabled() {
    return this.disabled_.asReadonly();
  }

  private next$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    private destroyRef: DestroyRef,
  ) {
    effect(() => (this.elementRef.nativeElement.style.width = this.width()));
  }

  public ngOnChanges(): void {
    effect(
      () => {
        this.next$.next();
        this.update();

        for (const formControl of castArray(this.controls() ?? [])) {
          const statusChanges$ = (
            formControl.statusChanges as Observable<any> | undefined
          )?.pipe(takeUntil(this.next$), takeUntilDestroyed(this.destroyRef));
          const valueChanges$ = (
            formControl.valueChanges as Observable<any> | undefined
          )?.pipe(takeUntil(this.next$), takeUntilDestroyed(this.destroyRef));

          statusChanges$?.subscribe(() => this.update());
          valueChanges$?.subscribe(() => this.update());
        }
      },
      { injector: this.injector },
    );
  }

  private update(): void {
    const controls = this.controls();
    if (controls == null) return;

    let optional = true;
    let disabled = true;

    for (const control of castArray(controls)) {
      if (
        control.hasValidator(Validators.required) &&
        !control.hasValidator(Validators.requiredTrue)
      ) {
        optional = false;
      }
      if (!control.disabled) disabled = false;
    }

    this.optional_.set(optional);
    this.disabled_.set(disabled);
  }
}

<div class="error">
  <div class="icon-wrapper">
    <div class="icon">
      <onyx-icon name="error-circle" [size]="20"></onyx-icon>
    </div>

    <p class="f-medium-1">
      {{ I18N + '.message' | translate }}
    </p>
  </div>

  <onyx-button color="red" size="s" (click)="error.set(false)">
    {{ I18N + '.refresh' | translate }}
  </onyx-button>
</div>

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ActionHelper,
  OnyxDatePipe,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxPhone,
  OnyxPhonePipe,
  OnyxToastService,
  PhoneHelper,
} from '@onyx/angular';
import { AddressComponent } from '../../../../common/components/address/address.component';
import { CountryCellComponent } from '../../../../common/components/cells/country-cell/country-cell.component';
import { StringCellComponent } from '../../../../common/components/cells/string-cell/string-cell.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { Driver } from '../../common/interfaces/driver';
import { DriverModalData } from '../driver-modal.component';

@Component({
  selector: 'app-driver-modal-data',
  imports: [
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    CountryCellComponent,
    OnyxPhonePipe,
    AddressComponent,
    StringCellComponent,
    OnyxDatePipe,
    OnyxIconComponent,
    OnyxIconButtonComponent,
  ],
  templateUrl: './driver-modal-data.component.html',
  styleUrl: './driver-modal-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalDataComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public driver = input.required<Driver>();
  public size = input.required<DriverModalData['size']>();

  constructor(private toastService: OnyxToastService) {}

  protected copyPhone(phone: OnyxPhone): void {
    ActionHelper.copy(PhoneHelper.composeLabel(phone), this.toastService);
  }
}

import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  Injector,
  input,
  model,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { cloneDeep, isArray, isEqual, isString } from 'lodash';
import { distinctUntilChanged, filter, forkJoin } from 'rxjs';
import { OnyxMaybeArray } from '../../../interfaces';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxFileSizePipe } from '../../../pipes/onyx-file-size.pipe';
import { OnyxStorageService, OnyxToastService } from '../../../services';
import { OnyxButtonComponent, OnyxIconButtonComponent } from '../../buttons';
import { OnyxIconComponent } from '../../icons';
import { OnyxInputLabelComponent } from '../../labels/onyx-input-label/onyx-input-label.component';
import { OnyxTooltipContext } from '../../tooltip';

export type OnyxUploadInput =
  | OnyxMaybeArray<File>
  | OnyxMaybeArray<string>
  | null;

@Component({
  selector: 'onyx-upload',
  imports: [
    NgClass,
    NgStyle,
    OnyxButtonComponent,
    OnyxInputLabelComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxFileSizePipe,
    OnyxIconButtonComponent,
    NgTemplateOutlet,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxUploadComponent),
      multi: true,
    },
    {
      provide: OnyxFileSizePipe,
      useClass: OnyxFileSizePipe,
    },
  ],
  templateUrl: './onyx-upload.component.html',
  styleUrl: './onyx-upload.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxUploadComponent
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor
{
  protected readonly I18N = `${I18N_NAMESPACE}.upload`;
  protected readonly IMAGE_TYPES = [
    'image/avif',
    'image/bmp',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
  ];
  protected readonly MAX_IMAGE_SIZE = 1_048_576; // 1 mb
  protected readonly FILE_TYPES = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ...this.IMAGE_TYPES,
  ];
  protected readonly MAX_FILE_SIZE = 8_388_608; // 8 mb

  public value = model<OnyxUploadInput>();
  public disabled = model(false);
  public type = input<'file' | 'image'>('file');
  public label = input<string>();
  public limit = input(1);
  public columns = input<number>();
  public hideBorder = input(false);
  public hint = input<OnyxTooltipContext>();
  public gap = input(16);

  protected onChange?: (value: OnyxMaybeArray<File> | null) => void;
  protected onTouched?: () => void;
  protected inactive = signal(false);
  protected isDragOver = signal(false);
  protected fileBlob = signal<string | null>(null);
  protected files = signal<File[] | null>(null);
  protected customErrors = signal<string[]>([]);
  protected loading = signal(false);
  protected isFile = computed(() => this.type() === 'file');
  protected countLabel = computed(() => {
    const [fileCount, label] = [this.files()?.length, this.label()];
    if (!this.multiple() || !fileCount) return label;

    return `${label ? `${this.translateService.instant(label)} ` : ''}(${fileCount})`;
  });
  protected isReachedLimit = computed(() => {
    const fileCount = this.files()?.length;
    return fileCount === this.limit();
  });
  protected multiple = computed(() => this.limit() > 1);

  private inputElementRef =
    viewChild.required<ElementRef<HTMLInputElement>>('inputElement');

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private translateService: TranslateService,
    private toastService: OnyxToastService,
    private elementRef: ElementRef,
    private storageService: OnyxStorageService,
    private fileSizePipe: OnyxFileSizePipe,
  ) {
    super(injector, destroyRef);

    effect(() => {
      const element = this.elementRef.nativeElement as HTMLElement;
      const columns = this.columns() ?? this.limit();
      element.style.gridTemplateColumns = `repeat(${columns}, minmax(0, 1fr))`;
      element.style.gap = `repeat(${columns}, minmax(0, 1fr))`;
    });

    toObservable(this.value)
      .pipe(
        filter((value) => value !== undefined),
        distinctUntilChanged(isEqual),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.writeValue(value));
  }

  public registerOnChange(
    fn: (value: OnyxMaybeArray<File> | null) => void,
  ): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: OnyxUploadInput): void {
    if (value == null) {
      this.resetFiles();
      this.inactive.set(false);
      return;
    }

    if (isArray(value)) {
      if (!this.multiple()) {
        throw new Error('Unexpected array of files for limit equal to 1.');
      }

      if (value.every((item) => isString(item))) {
        this.loading.set(true);
        const files$ = value.map((file) => this.storageService.getFile(file));
        forkJoin(files$)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((files) => this.setFiles(files))
          .add(() => this.loading.set(false));
      } else {
        this.files.set(value);
      }
    } else {
      if (this.multiple()) {
        throw new Error('Unexpected single file for limit greater than 1.');
      }

      if (isString(value)) {
        this.loading.set(true);
        this.storageService
          .getFile(value)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((file) => this.setFiles([file]))
          .add(() => this.loading.set(false));
      } else {
        this.setFiles([value]);
      }
    }
  }

  public focus(): void {
    if (this.disabled()) return;
    this.inputElementRef().nativeElement.click();
  }

  protected onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver.set(true);
  }

  protected onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragOver.set(false);
  }

  protected onDrop(event: DragEvent): void {
    if (this.inactive()) return;

    event.preventDefault();
    this.isDragOver.set(false);
    const files = (event.dataTransfer?.files ?? []) as FileList;
    this.handleFiles(files);
  }

  protected onSpaceKeyPress(): void {
    this.inputElementRef().nativeElement.click();
  }

  protected onFileInputChange(event: Event): void {
    if (this.inactive()) return;

    const input = event.target as HTMLInputElement;
    const files = input.files!;
    this.handleFiles(files);
  }

  protected deleteFile(index = 0): void {
    if (this.disabled()) return;

    const files = this.files();

    if (this.multiple() && files) {
      files.splice(index, 1);
      const updatedFiles = files.length === 0 ? null : cloneDeep(files);
      this.files.set(updatedFiles);

      if (updatedFiles == null) this.resetFiles();

      if (!updatedFiles || updatedFiles.length < this.limit()) {
        this.inactive.set(false);
      }
    } else {
      this.resetFiles();
      this.customErrors.set([]);
      this.inactive.set(false);
      if (this.inputElementRef) this.inputElementRef().nativeElement.value = '';
    }
  }

  protected onChangeFile(allowDisabled = true): void {
    if ((this.inactive() && !allowDisabled) || this.disabled()) return;

    this.inactive.set(false);
    this.onTouched?.();
    setTimeout(() => {
      this.inputElementRef().nativeElement.click();
    });
  }

  protected previewFile(file: File) {
    this.storageService.showPreview(file);
  }

  protected getDisplayFileName(file: File): string {
    const extension = file.type.split('/').pop() ?? '';
    const label =
      this.label() ?? this.translateService.instant(`${this.I18N}.file`);

    return file.name || `${label}.${extension}`;
  }

  private setFiles(files: File[]): void {
    files = files.slice(0, this.limit());
    this.files.set(files);

    if (!this.multiple()) {
      this.onChange?.(files[0]);
      this.value.set(files[0]);
    } else {
      this.onChange?.(files);
      this.value.set(files);
    }

    if (!this.isFile()) {
      this.fileBlob.set(URL.createObjectURL(files[0]));
    }

    if (files.length === this.limit()) this.inactive.set(true);
  }

  private resetFiles(): void {
    const value = this.multiple() ? [] : null;

    this.files.set(null);
    this.onChange?.(value);
    this.value.set(value);
  }

  private handleInvalidFile(errorMessageKey: string): void {
    this.resetFiles();

    if (this.inputElementRef) this.inputElementRef().nativeElement.value = '';
    this.translateService
      .get(`${this.I18N}.${errorMessageKey}`)
      .subscribe((translatedMessage: string) => {
        this.customErrors.update((array) => [...array, translatedMessage]);
      });
  }

  private handleFiles(files: FileList): void {
    const convertedFiles = Array.from(files);

    if (this.isFile()) {
      for (const file of convertedFiles) {
        if (file.size === 0) {
          this.toastService.showError(
            this.translateService.instant(`${this.I18N}.emptyFileError`),
          );
          return;
        } else if (file.size > this.MAX_FILE_SIZE) {
          this.toastService.showError(
            this.translateService.instant(`${this.I18N}.tooLargeError`, {
              maxSize: this.fileSizePipe.transform(this.MAX_FILE_SIZE),
            }),
          );
          return;
        }
      }
    } else {
      const file = files[0];
      this.customErrors.set([]);
      this.fileBlob.set(URL.createObjectURL(file));

      if (
        !this.IMAGE_TYPES.includes(file.type) ||
        file.size === 0 ||
        file.size > this.MAX_IMAGE_SIZE
      ) {
        if (!this.IMAGE_TYPES.includes(file.type)) {
          this.handleInvalidFile('formatError');
        } else if (file.size === 0) {
          this.handleInvalidFile('emptyFileError');
        } else if (file.size > this.MAX_IMAGE_SIZE) {
          this.handleInvalidFile('maxSizeError');
        }
        return;
      }
    }

    if (!this.multiple()) {
      this.setFiles(convertedFiles);
    } else {
      this.setFiles([...(this.files() ?? []), ...convertedFiles]);
    }

    if (this.inputElementRef) this.inputElementRef().nativeElement.value = '';
  }
}

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { tap } from 'rxjs';
import { BusinessDocuments } from '../../../common/interfaces/documents/business-documents';
import { DocumentsService } from '../../../dashboard/management-panel/documents/common/services/documents.service';
import { AuthService } from '../services/auth.service';

export const registerDocumentsResolver: ResolveFn<BusinessDocuments> = () => {
  const documentsService = inject(DocumentsService);
  const authService = inject(AuthService);

  return documentsService
    .getDocuments()
    .pipe(tap((documents) => authService.updateDocuments(documents)));
};

<section>
  <onyx-information-heading>
    {{ I18N + '.generalInformation' | translate }}
  </onyx-information-heading>

  @let generalInformation = vehicle().generalInformation;
  <onyx-information-row label="labels.registrationNumber">
    {{ generalInformation.registrationNumber }}
  </onyx-information-row>

  <onyx-information-row label="labels.sideNumber">
    {{ generalInformation.sideNumber ?? '-' }}
  </onyx-information-row>

  <onyx-information-row label="labels.vinNumber">
    {{ generalInformation.vinNumber }}
  </onyx-information-row>

  <onyx-information-row label="labels.makeAndModel">
    {{ generalInformation.make }} {{ generalInformation.model }}
  </onyx-information-row>

  <onyx-information-row label="labels.manufactureYear">
    {{ generalInformation.manufactureYear }}
  </onyx-information-row>

  <onyx-information-row label="labels.firstRegistrationDate">
    {{ generalInformation.firstRegistrationDate | onyxDate: 'date-dot' }}
  </onyx-information-row>

  <onyx-information-row label="labels.registrationCountry">
    <app-country-cell
      [countryCode]="generalInformation.registrationCountry"
    ></app-country-cell>
  </onyx-information-row>

  <onyx-information-row label="labels.state">
    {{
      DictionaryCode.VEHICLE_STATE + '.' + generalInformation.state | translate
    }}
  </onyx-information-row>

  <onyx-information-row label="labels.planning">
    {{
      DictionaryCode.VEHICLE_PLANNING_MODE +
        '.' +
        generalInformation.planningMode | translate
    }}
  </onyx-information-row>

  <onyx-information-row label="labels.mileage"> N/A </onyx-information-row>

  <onyx-information-row label="labels.base"> N/A </onyx-information-row>

  <onyx-information-heading>
    {{ I18N + '.weightAndDimensions' | translate }}
  </onyx-information-heading>

  @let vehicleMeasurements = vehicle().vehicleMeasurements;
  <onyx-information-row label="labels.curbWeight">
    <app-unit-cell
      [value]="vehicleMeasurements.curbWeight"
      unit="kg"
    ></app-unit-cell>
  </onyx-information-row>

  <onyx-information-row label="labels.axleCount">
    {{ vehicleMeasurements.axleCount }}
  </onyx-information-row>

  <onyx-information-row label="labels.vehicleDimensions">
    <div class="dimensions">
      <app-unit-cell
        [value]="vehicleMeasurements.length"
        [decimalPlaces]="1"
        unit="m"
      ></app-unit-cell>

      <span>·</span>

      <app-unit-cell
        [value]="vehicleMeasurements.width"
        [decimalPlaces]="1"
        unit="m"
      ></app-unit-cell>

      <span>·</span>

      <app-unit-cell
        [value]="vehicleMeasurements.height"
        [decimalPlaces]="1"
        unit="m"
      ></app-unit-cell>
    </div>
  </onyx-information-row>

  @if (!isVehicle()) {
    <onyx-information-heading>
      {{ I18N + '.cargoSpace' | translate }}
    </onyx-information-heading>

    @let cargoSpace = vehicle().cargoSpace;
    <onyx-information-row label="labels.loadCapacity">
      <app-unit-cell
        [value]="cargoSpace.loadCapacity"
        unit="kg"
      ></app-unit-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.volume">
      <app-unit-cell
        [value]="cargoSpace.volumeCapacity"
        [decimalPlaces]="1"
        unit="m³"
      ></app-unit-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.chambersNumber">
      {{ cargoSpace.chambersNumber ?? '-' }}
    </onyx-information-row>

    <onyx-information-row label="labels.tankCertificates">
      <app-string-cell
        [value]="cargoSpace.tankCertificates"
        [dictionaryCode]="DictionaryCode.TANK_CERTIFICATE"
      ></app-string-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.dischargeType">
      <app-string-cell
        [value]="cargoSpace.dischargeTypes"
        [dictionaryCode]="DictionaryCode.DISCHARGE_TYPE"
      ></app-string-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.dischargeType">
      <app-unit-cell
        [value]="cargoSpace.europalletsNumber"
        [unit]="'units.pieces' | translate"
      ></app-unit-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.cargoSpaceDimensions">
      <div class="dimensions">
        <app-unit-cell
          [value]="cargoSpace.length"
          [decimalPlaces]="1"
          unit="m"
        ></app-unit-cell>

        <span>·</span>

        <app-unit-cell
          [value]="cargoSpace.width"
          [decimalPlaces]="1"
          unit="m"
        ></app-unit-cell>

        <span>·</span>

        <app-unit-cell
          [value]="cargoSpace.height"
          [decimalPlaces]="1"
          unit="m"
        ></app-unit-cell>
      </div>
    </onyx-information-row>
  }

  @if (isVehicle()) {
    <onyx-information-heading>
      {{ 'fleet.fleetForm.fuelTankCapacity.heading' | translate }}
    </onyx-information-heading>

    @let fuelTankCapacity = vehicle().fuelTankCapacity!;
    <onyx-information-row label="labels.fuel">
      {{
        DictionaryCode.FUEL_TYPE + '.' + fuelTankCapacity.fuelType | translate
      }}
    </onyx-information-row>

    <onyx-information-row label="labels.mainFuelTank">
      <app-unit-cell
        [value]="fuelTankCapacity.mainTank"
        [unit]="fuelType()?.units?.[0] ?? ''"
      ></app-unit-cell>
    </onyx-information-row>

    @if (fuelType()?.requireAdditionalTank) {
      <onyx-information-row label="labels.additionalTank">
        <app-unit-cell
          [value]="fuelTankCapacity.additionalTank"
          [unit]="fuelType()?.units?.[1] ?? ''"
        ></app-unit-cell>
      </onyx-information-row>
    }

    <onyx-information-row label="labels.adBlueTank">
      <app-unit-cell
        [value]="fuelTankCapacity.adBlueTank"
        unit="L"
      ></app-unit-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.generatorFuelTank">
      <app-unit-cell
        [value]="fuelTankCapacity.generatorFuelTank"
        unit="L"
      ></app-unit-cell>
    </onyx-information-row>
  }

  <onyx-information-heading>
    {{ 'labels.ownershipType' | translate }}
  </onyx-information-heading>

  @let ownership = vehicle().ownership;
  <onyx-information-row label="labels.forSale">
    {{ (ownership.isForSale ? 'buttons.yes' : 'buttons.no') | translate }}
  </onyx-information-row>

  @let ownershipType = vehicle().ownership.type;
  <onyx-information-row label="labels.ownershipType">
    {{ DictionaryCode.OWNERSHIP_TYPE + '.' + ownershipType | translate }}
  </onyx-information-row>

  @switch (ownershipType) {
    @case (OwnershipType.OWN) {
      <onyx-information-row label="labels.vehicleValue">
        <app-amount-cell [amount]="ownership.vehicleValue"></app-amount-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.depreciationPeriod">
        <app-date-range-cell
          [range]="{
            from: ownership.depreciationPeriod!.from!,
            to: ownership.depreciationPeriod!.to!,
          }"
        ></app-date-range-cell>
      </onyx-information-row>
    }
    @case (OwnershipType.RENTAL) {
      <onyx-information-row label="labels.selfDeposit">
        <app-amount-cell [amount]="ownership.selfDeposit"></app-amount-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.installment">
        <app-amount-cell [amount]="ownership.installment"></app-amount-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.firstInstallment">
        {{
          ownership.firstAndLastInstallmentDate!.from! | onyxDate: 'date-dot'
        }}
      </onyx-information-row>

      <onyx-information-row label="labels.lastInstallment">
        {{ ownership.firstAndLastInstallmentDate!.to! | onyxDate: 'date-dot' }}
      </onyx-information-row>
    }
    @default {
      <onyx-information-row label="labels.selfDeposit">
        <app-amount-cell [amount]="ownership.selfDeposit"></app-amount-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.buyout">
        <app-amount-cell [amount]="ownership.buyout"></app-amount-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.installment">
        <app-amount-cell [amount]="ownership.installment"></app-amount-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.firstInstallment">
        {{
          ownership.firstAndLastInstallmentDate!.from! | onyxDate: 'date-dot'
        }}
      </onyx-information-row>

      <onyx-information-row label="labels.lastInstallment">
        {{ ownership.firstAndLastInstallmentDate!.to! | onyxDate: 'date-dot' }}
      </onyx-information-row>

      <onyx-information-row label="labels.generatorFuelTank">
        <app-unit-cell [value]="ownership.margin" unit="%"></app-unit-cell>
      </onyx-information-row>

      <onyx-information-row label="labels.interestRate">
        <app-string-cell
          [value]="ownership.interestRateType"
          [dictionaryCode]="DictionaryCode.INTEREST_RATE_TYPE"
        ></app-string-cell>
      </onyx-information-row>
    }
  }

  <onyx-information-heading>
    {{ 'forms.countriesWhitelist.heading' | translate }}
  </onyx-information-heading>

  @let allowedCountries = vehicle().allowedCountries;
  <onyx-information-row label="forms.countriesWhitelist.group">
    @if (!allowedCountries.group) {
      <app-string-cell
        [value]="allowedCountries.whitelist"
        [dictionaryCode]="DictionaryCode.COUNTRY"
      ></app-string-cell>
    } @else {
      @let isNational = allowedCountries.group === 'national';
      <app-string-cell
        [value]="isNational ? language : allowedCountries.group"
        [dictionaryCode]="
          isNational ? DictionaryCode.COUNTRY : DictionaryCode.COUNTRIES_GROUP
        "
      ></app-string-cell>
    }
  </onyx-information-row>

  <onyx-information-heading>
    {{ 'labels.tachograph' | translate }}
  </onyx-information-heading>

  @if (isVehicle()) {
    @let tacho = vehicle().tachograph;
    <onyx-information-row label="labels.model">
      <app-string-cell
        [value]="tacho?.model"
        [dictionaryCode]="DictionaryCode.TACHOGRAPH_MODEL"
      ></app-string-cell>
    </onyx-information-row>

    <onyx-information-row label="labels.make">
      <app-string-cell
        [value]="tacho?.make"
        [dictionaryCode]="DictionaryCode.TACHOGRAPH_MAKE"
      ></app-string-cell>
    </onyx-information-row>

    <app-modal-date-row
      label="labels.nextLegalizationDate"
      [expirationDate]="tacho?.nextLegalizationDate!"
      size="m"
    ></app-modal-date-row>
  }
</section>

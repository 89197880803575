import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxLinkComponent, OnyxNamePipe } from '@onyx/angular';
import { chain } from 'lodash';
import { DriverHelper } from '../../../drivers/common/helpers/driver.helper';
import { SimplifiedDriver } from '../../../drivers/common/interfaces/driver';
import { SimplifiedEmployee } from '../../../management-panel/employees/common/interfaces/employee';
import { Delegation } from '../../common/interfaces/delegation';

@Component({
  selector: 'app-delegation-modal-heading',
  imports: [TranslatePipe, OnyxLinkComponent, OnyxNamePipe, OnyxNamePipe],
  templateUrl: './delegation-modal-heading.component.html',
  styleUrl: './delegation-modal-heading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationModalHeadingComponent {
  public primaryDriver = input.required<Delegation['primaryDriver']>();
  public secondaryDriver = input.required<Delegation['secondaryDriver']>();
  public author = input<SimplifiedEmployee>();

  protected drivers = computed(() =>
    chain([this.primaryDriver(), this.secondaryDriver()]).compact().value(),
  );

  constructor(private driverHelper: DriverHelper) {}

  protected openDriverModal(driver: SimplifiedDriver): void {
    this.driverHelper.openModal(driver);
  }
}

<onyx-modal
  type="center"
  heading="{{ I18N + '.' + data.mode + 'Unavailability' | translate }}: {{
    data.type === UnavailabilityType.EMPLOYEE
      ? data.item.firstName
      : data.item.driverData.firstName
  }} {{
    data.type === UnavailabilityType.EMPLOYEE
      ? data.item.lastName
      : data.item.driverData.lastName
  }}"
  size="m"
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <form [formGroup]="form">
    @let controls = form.controls;
    <onyx-datepicker
      [formControl]="controls.dateRange"
      label="labels.from"
      labelTo="labels.to"
      [showDateRange]="true"
      [yearsRange]="{ past: 0, future: 10 }"
    ></onyx-datepicker>

    <div class="group">
      <onyx-dropdown
        [formControl]="controls.reason"
        label="labels.reason"
        [options]="unavailabilityReasons$ | async"
        size="m"
      ></onyx-dropdown>

      <onyx-upload
        [formControl]="controls.file"
        [label]="I18N + '.documentary'"
      ></onyx-upload>
    </div>
  </form>

  @if (data.mode === OnyxFormMode.EDIT) {
    <onyx-button
      bottomLeftOptions
      type="outlined"
      color="red"
      (click)="delete()"
    >
      {{ 'buttons.delete' | translate }}
    </onyx-button>
  }

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      color="blue"
      [spinner]="loading()"
      (click)="!loading() ? submit() : null"
    >
      {{
        (data.mode === OnyxFormMode.ADD ? 'buttons.add' : 'buttons.save')
          | translate
      }}
    </onyx-button>
  </ng-container>
</onyx-modal>

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxDatePipe, OnyxInformationRowComponent } from '@onyx/angular';
import { ColorHelper } from '../../../helpers/color.helper';

@Component({
  selector: 'app-modal-date-row',
  imports: [OnyxInformationRowComponent, OnyxDatePipe],
  templateUrl: './modal-date-row.component.html',
  styleUrl: './modal-date-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDateRowComponent {
  protected readonly ColorHelper = ColorHelper;

  public label = input.required<string>();
  public expirationDate = input.required<string>();
  public size = input<'s' | 'm'>('s');
}

import { DecimalPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxIconComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { UnitCellComponent } from '../../../../../common/components/cells/unit-cell/unit-cell.component';
import { NumberDecorationPipe } from '../../../../../common/components/pipes/number-decoration.pipe';
import { EmptyDistanceStrategy } from '../../../../management-panel/engine-config/common/enums/empty-distance-strategy';
import { EmptyDistance } from '../../../../management-panel/engine-config/common/interfaces/empty-distance';
import { OrderHelper } from '../../../common/helpers/order.helper';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-plan-kilometers',
  standalone: true,
  imports: [
    OnyxDataWidgetComponent,
    UnitCellComponent,
    TranslatePipe,
    NumberDecorationPipe,
    DecimalPipe,
    OnyxTooltipDirective,
    NgClass,
    OnyxIconComponent,
  ],
  templateUrl: './order-modal-plan-kilometers.component.html',
  styleUrl: './order-modal-plan-kilometers.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalPlanKilometersComponent {
  protected readonly I18N = 'orders.orderModal.plan';
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  // TEMP: Implement from authService when BE ready
  protected readonly engineConfig: EmptyDistance = {
    strategy: EmptyDistanceStrategy.PERCENTAGE,
    distanceThreshold: null,
    percentageThreshold: 1,
  };

  public order = input.required<Order>();

  protected totalDistance = computed(() =>
    OrderHelper.getTotalDistance(this.order()),
  );
  protected distance = computed(() => OrderHelper.getDistance(this.order()));
  protected emptyDistance = computed(() =>
    OrderHelper.getEmptyDistance(this.order()),
  );
  protected plannedData = computed(() => {
    const { status, route, assignedData } = this.order();
    if (OrderHelper.isOrderPending(status.value)) return;

    const distance = route.distance;
    const emptyDistance = assignedData?.emptyDistance ?? 0;

    return {
      totalDistance: distance + emptyDistance,
      distance,
      emptyDistance,
    };
  });

  protected isEmptyDistanceError = computed(() => {
    const emptyDistance = this.emptyDistance();
    if (
      !emptyDistance ||
      this.order().manualProposalData?.result.ok !== false
    ) {
      return false;
    }

    const emptyKilometers = emptyDistance / 1_000;
    const config = this.engineConfig;

    const invalidDistanceStrategy =
      emptyKilometers > (config.distanceThreshold ?? Infinity);
    const invalidPercentageStrategy =
      emptyKilometers >
      ((this.distance() / 1000) * (config.percentageThreshold ?? Infinity)) /
        100;

    switch (config.strategy) {
      case EmptyDistanceStrategy.VALUE:
        return invalidDistanceStrategy;
      case EmptyDistanceStrategy.PERCENTAGE:
        return invalidPercentageStrategy;
      case EmptyDistanceStrategy.PERCENTAGE_AND_VALUE:
        return invalidPercentageStrategy && invalidDistanceStrategy;
      case EmptyDistanceStrategy.PERCENTAGE_OR_VALUE:
        return invalidPercentageStrategy || invalidDistanceStrategy;
      default:
        return false;
    }
  });
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxPaginated,
} from '@onyx/angular';
import { chain } from 'lodash';
import { map, Observable, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../../common/services/api.service';
import { BaseFormDto } from '../../base-form/base-form.component';
import { Base } from '../interfaces/base';

@Injectable({
  providedIn: 'root',
})
export class BasesService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private filterPipe: OnyxFilterPipe,
  ) {
    super(http);
  }

  public listBases(): Observable<Base[]> {
    return this.get<OnyxPaginated<Base>>('/bases', {
      params: {
        page: 1,
        limit: Number.MAX_SAFE_INTEGER,
      },
    }).pipe(map((paginated) => paginated.items));
  }

  public searchBases(
    query: string,
    limit: number,
  ): Observable<OnyxDropdownOptionsSourceResult<Base>> {
    return this.listBases().pipe(
      map((response) => ({
        options: chain(response)
          .map((base) => ({
            name: base.name,
            value: base,
          }))
          .thru((options) =>
            this.filterPipe.transform(options, query, ['name']).slice(0, limit),
          )
          .value(),
        totalItems: response.length,
      })),
    );
  }

  public getBase(uuid: string): Observable<Base> {
    return this.get(`/bases/${uuid}`);
  }

  public addBase(dto: BaseFormDto): Observable<void> {
    return this.post<void>('/bases', dto).pipe(tap(() => this._reload$.next()));
  }

  public editBase(uuid: string, dto: BaseFormDto): Observable<void> {
    return this.put<void>(`/bases/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public deleteBase(uuid: string): Observable<void> {
    return this.delete<void>(`/bases/${uuid}`).pipe(
      tap(() => this._reload$.next()),
    );
  }
}

import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { round } from 'lodash';

@Component({
  selector: 'app-number-cell',
  imports: [DecimalPipe],
  templateUrl: './number-cell.component.html',
  styleUrl: './number-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberCellComponent {
  public value = input.required<number | null>();
  public decimalPlaces = input(0);
  public disablePipe = input(false);
  public acceptZero = input(false);

  protected decimalValue = computed(() => {
    if (this.value() == null && !this.acceptZero()) return null;

    return round(
      this.value()! / Math.pow(10, this.decimalPlaces()),
      this.decimalPlaces(),
    );
  });
  protected pipeFormat = computed(() => `1.0-${this.decimalPlaces()}`);
}

@let outsourcingData = order().outsourcingData;
<section [ngClass]="{ outsourcing: outsourcingData }">
  @if (outsourcingData) {
    <onyx-information-heading>
      <div class="outsourcing-heading">
        <onyx-icon name="cursor" [size]="16"></onyx-icon>

        {{ 'labels.outsourcing' | translate }}
      </div>
    </onyx-information-heading>

    @let secondaryDriver = outsourcingData?.secondaryDriver;
    <div class="outsourcing-drivers" [ngClass]="{ single: !secondaryDriver }">
      <onyx-data-widget supheading="labels.driver">
        <h4 heading>{{ outsourcingData.primaryDriver.name }}</h4>
      </onyx-data-widget>

      @if (secondaryDriver) {
        <onyx-data-widget supheading="labels.secondaryDriver">
          <h4 heading>{{ secondaryDriver.name }}</h4>
        </onyx-data-widget>
      }
    </div>
  } @else {
    @let drivers =
      order().manualProposalData?.drivers ||
      order().engineProposalData?.drivers ||
      order().assignedData?.drivers ||
      null;

    <app-drivers-times
      [primaryDriver]="drivers?.primaryDriver"
      [secondaryDriver]="drivers?.secondaryDriver"
      variant="extended"
      (driverChange)="driver.set($event)"
    ></app-drivers-times>
  }
</section>

import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  forwardRef,
  Injector,
  input,
  model,
  OnInit,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { distinctUntilChanged, filter, fromEventPattern, skip } from 'rxjs';
import {
  ONYX_TOOLTIP_DELAY,
  ONYX_TRANSITION_DURATION,
} from '../../../constants';
import { OnyxTooltipDirective } from '../../../directives';
import { OnyxBaseFormControlComponent } from '../../../internal/components/onyx-base-form-control/onyx-base-form-control.component';
import { OnyxFormControlErrorComponent } from '../../../internal/components/onyx-form-control-error/onyx-form-control-error.component';
import { OnyxIconComponent } from '../../icons';
import { OnyxInputLabelComponent } from '../../labels';
import { OnyxTooltipContext } from '../../tooltip';
import { OnyxTab } from '../interfaces';

@Component({
  selector: 'onyx-tabs',
  imports: [
    OnyxIconComponent,
    NgClass,
    TranslatePipe,
    OnyxTooltipDirective,
    OnyxInputLabelComponent,
    OnyxFormControlErrorComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnyxTabsComponent),
      multi: true,
    },
  ],
  templateUrl: './onyx-tabs.component.html',
  styleUrl: './onyx-tabs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxTabsComponent<T>
  extends OnyxBaseFormControlComponent
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  public tabs = input.required<OnyxTab<T>[] | null | undefined>();
  public size = input<'s' | 'm'>('s');
  public fill = input(false);
  public label = input<string>();
  public value = model<T | null>();
  public hint = input<OnyxTooltipContext>();
  public disabled = model(false);
  public forceOptional = input(false);
  public showErrors = input(true);

  protected onChange?: (value: T | null) => void;
  protected onTouched?: () => void;
  protected activeTabIndex = signal<number | null>(null);

  private loadingValue = signal<T | null>(null);

  private containerElementRef =
    viewChild.required<ElementRef>('containerElement');
  private tabElementRefs = viewChildren<ElementRef>('tabElement');
  private indicatorElementRef =
    viewChild.required<ElementRef>('indicatorElement');

  constructor(
    protected override injector: Injector,
    protected override destroyRef: DestroyRef,
    private elementRef: ElementRef,
  ) {
    super(injector, destroyRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    toObservable(this.value, { injector: this.injector })
      .pipe(
        filter((value) => value !== undefined),
        distinctUntilChanged(isEqual),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.writeValue(value));

    effect(
      () => {
        const tabs = this.tabs();
        if (tabs == null) return;

        const value = this.loadingValue();
        if (value != null) {
          this.writeValue(value);
          this.loadingValue.set(null);
        }
      },
      { injector: this.injector },
    );
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    fromEventPattern<void>(
      (handler) => {
        const intersectionObserver = new IntersectionObserver(([entry]) => {
          if (entry.intersectionRatio === 1) handler();
        });
        intersectionObserver.observe(this.containerElementRef().nativeElement);

        return intersectionObserver;
      },
      (_, intersectionObserver: IntersectionObserver) => {
        intersectionObserver.disconnect();
      },
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateIndicator(false));

    fromEventPattern<void>(
      (handler) => {
        const resizeObserver = new ResizeObserver(() => handler());
        resizeObserver.observe(this.elementRef.nativeElement);

        return resizeObserver;
      },
      (_, resizeObserver?: ResizeObserver) => resizeObserver?.disconnect(),
    )
      .pipe(
        skip(1),
        takeUntilDestroyed(this.destroyRef),
        filter(() => this.fill()),
      )
      .subscribe(() => this.updateIndicator(false));
  }

  public writeValue(value: T | null): void {
    const tabs = this.tabs();
    const index = tabs?.findIndex((tab) => tab.value === value);

    if (index === undefined || index === -1) {
      this.loadingValue.set(value);
      this.changeTab(null, true);
      return;
    }

    this.changeTab(index, true);
  }

  public registerOnChange(fn: (value: T | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public focus(): void {
    this.tabElementRefs()[this.activeTabIndex() ?? 0]?.nativeElement.focus();
  }

  protected changeTab(index: number | null, force = false): void {
    const tabs = this.tabs();
    if (tabs == null) index = null;

    let value = null;
    if (tabs != null && index != null) {
      const tab = tabs[index];
      if (tab.disabled) return;

      value = tab.value;
    }

    this.activeTabIndex.set(index);
    setTimeout(() => this.updateIndicator(!force));

    if (!force) {
      this.value.set(value);
      this.onChange?.(value);
    }
  }

  private updateIndicator(animate: boolean): void {
    const element = this.containerElementRef()?.nativeElement;
    const indicator = this.indicatorElementRef()?.nativeElement;
    const tabs = this.tabElementRefs();
    if (!element || !indicator || !tabs) return;

    const TRANSITION_DURATION = `${ONYX_TRANSITION_DURATION}ms`;
    if (!animate) {
      indicator.style.transitionDuration = '0s';
      setTimeout(
        () => (indicator.style.transitionDuration = TRANSITION_DURATION),
      );
    }

    const activeTabIndex = this.activeTabIndex();
    if (activeTabIndex != null) {
      const tab = tabs[activeTabIndex]?.nativeElement;

      indicator.style.opacity = '1';
      indicator.style.width = `${tab.clientWidth}px`;
      indicator.style.transform = `translateX(${
        tab.offsetLeft - element.clientLeft - 2
      }px)`;

      if (
        tab.offsetLeft < element.scrollLeft ||
        tab.offsetLeft + tab.clientWidth > element.offsetWidth
      ) {
        element.scroll({ left: tab.offsetLeft, behavior: 'smooth' });
      }
    } else {
      indicator.style.opacity = '0';
    }

    if (!indicator.style.transitionDuration) {
      setTimeout(
        () => (indicator.style.transitionDuration = TRANSITION_DURATION),
      );
    }
  }
}

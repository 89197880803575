@if (businessHours(); as businessHours) {
  <div class="opening-hours">
    <div class="labels f-regular-3">
      <p>{{ businessHours | businessHours: 'weekday' : 'short' : 'label' }}</p>
      <p>{{ businessHours | businessHours: 'saturday' : 'short' : 'label' }}</p>
      <p>{{ businessHours | businessHours: 'sunday' : 'short' : 'label' }}</p>
    </div>

    <div class="hours f-medium-3">
      <p>{{ businessHours | businessHours: 'weekday' : 'short' : 'value' }}</p>
      <p>{{ businessHours | businessHours: 'saturday' : 'short' : 'value' }}</p>
      <p>{{ businessHours | businessHours: 'sunday' : 'short' : 'value' }}</p>
    </div>
  </div>
} @else {
  -
}

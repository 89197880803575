import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  Injector,
  input,
  OnInit,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxErrorComponent,
  OnyxModalService,
  OnyxSpinnerComponent,
  OnyxToastService,
} from '@onyx/angular';
import { chain } from 'lodash';
import {
  catchError,
  combineLatest,
  EMPTY,
  forkJoin,
  of,
  Subject,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { ValidationHelper } from '../../../../common/helpers/validation.helper';
import { Driver } from '../../../drivers/common/interfaces/driver';
import { DriversService } from '../../../drivers/common/services/drivers.service';
import { Employee } from '../../../management-panel/employees/common/interfaces/employee';
import { EmployeesService } from '../../../management-panel/employees/common/services/employees.service';
import {
  FleetAssignDriverModalComponent,
  FleetAssignDriverModalData,
} from '../../common/components/fleet-assign-driver-modal/fleet-assign-driver-modal.component';
import {
  FleetAssignEmployeesModalComponent,
  FleetAssignEmployeesModalData,
} from '../../common/components/fleet-assign-employees-modal/fleet-assign-employees-modal.component';
import { Fleet } from '../../common/interfaces/fleet';
import { FleetModalCrewItemComponent } from './fleet-modal-crew-item/fleet-modal-crew-item.component';

@Component({
  selector: 'app-fleet-modal-crew',
  imports: [
    OnyxButtonComponent,
    OnyxSpinnerComponent,
    FleetModalCrewItemComponent,
    OnyxErrorComponent,
    TranslatePipe,
  ],
  templateUrl: './fleet-modal-crew.component.html',
  styleUrl: './fleet-modal-crew.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalCrewComponent implements OnInit {
  public vehicle = input.required<Fleet>();
  public isVehicle = input.required<boolean>();

  protected drivers = signal<Driver[] | null>(null);
  protected employees = signal<Employee[] | null>(null);
  protected loading = signal(false);
  protected showLoading = signal(false);
  protected error = signal(false);
  protected crew$ = new Subject<void>();

  constructor(
    private driversService: DriversService,
    private employeesService: EmployeesService,
    private toastService: OnyxToastService,
    private modalService: OnyxModalService,
    private destroyRef: DestroyRef,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    if (!this.isVehicle()) return;

    effect(
      () => {
        this.showLoading.set(false);
        if (this.loading()) {
          timer(1000)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.showLoading.set(true));
        }
      },
      { injector: this.injector },
    );

    this.crew$
      .pipe(
        tap(() => this.loading.set(true)),
        switchMap(() => {
          const drivers$ = chain(this.vehicle().drivers)
            .values()
            .compact()
            .map((driver) => this.driversService.getDriver(driver.uuid))
            .value();

          const employees$ = chain(this.vehicle().assignedEmployees)
            .map((employee) => this.employeesService.getEmployee(employee.uuid))
            .value();

          return combineLatest({
            drivers: drivers$.length ? forkJoin(drivers$) : of([]),
            employees: employees$.length ? forkJoin(employees$) : of([]),
          }).pipe(
            catchError((response) => {
              this.loading.set(false);
              this.error.set(true);

              ValidationHelper.handleUnexpectedError(
                response,
                this.toastService,
              );
              return EMPTY;
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: ({ drivers, employees }) => {
          this.drivers.set(drivers);
          this.employees.set(employees);
          this.loading.set(false);
          this.error.set(false);
        },
      });

    effect(
      () => {
        this.vehicle();
        untracked(() => this.crew$.next());
      },
      { injector: this.injector },
    );
  }

  protected assignEmployees(): void {
    this.modalService.open<FleetAssignEmployeesModalData>(
      FleetAssignEmployeesModalComponent,
      [this.vehicle()],
    );
  }

  protected assignDrivers(): void {
    this.modalService.open<FleetAssignDriverModalData>(
      FleetAssignDriverModalComponent,
      this.vehicle(),
    );
  }
}

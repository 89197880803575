import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  TemplateRef,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxBadge, OnyxBadgeComponent } from '../../badges';
import { OnyxButtonComponent } from '../../buttons/onyx-button/onyx-button.component';
import { OnyxModalComponent } from '../onyx-modal/onyx-modal.component';

export interface OnyxConfirmModalData {
  heading: string;
  showClose?: boolean;
  badges?: OnyxBadge<string>[];
  message?: string;
  templateRef?: TemplateRef<any>;
  cancelButtonText?: string;
  confirmButtonText: string;
  confirmButtonColor?: string;
}

@Component({
  selector: 'onyx-confirm-modal',
  imports: [
    OnyxModalComponent,
    NgTemplateOutlet,
    OnyxButtonComponent,
    TranslatePipe,
    OnyxBadgeComponent,
  ],
  templateUrl: './onyx-confirm-modal.component.html',
  styleUrl: './onyx-confirm-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxConfirmModalComponent {
  protected readonly I18N = I18N_NAMESPACE;

  protected close$ = new Subject<boolean | undefined>();

  constructor(
    @Inject(DIALOG_DATA) protected data: OnyxConfirmModalData,
    protected dialogRef: DialogRef<boolean>,
  ) {}
}

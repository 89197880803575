import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  OnChanges,
  effect,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroupDirective, NgControl } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { castArray } from 'lodash';
import { Observable, Subject, takeUntil } from 'rxjs';
import { OnyxMaybeArray } from '../../../interfaces';
import { OnyxDateFormat, OnyxDatePipe } from '../../../pipes';
import { I18N_NAMESPACE } from '../../constants';

interface Error {
  code: string;
  value: any;
}

@Component({
  selector: 'onyx-form-control-error',
  imports: [TranslatePipe, NgClass],
  providers: [
    {
      provide: OnyxDatePipe,
      useClass: OnyxDatePipe,
    },
  ],
  templateUrl: './onyx-form-control-error.component.html',
  styleUrls: ['./onyx-form-control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxFormControlErrorComponent implements OnChanges {
  protected readonly I18N = `${I18N_NAMESPACE}.formControlErrors`;

  public controls =
    input.required<OnyxMaybeArray<NgControl | AbstractControl>>();
  public type = input<'default' | 'toggle' | 'checkbox'>('default');
  public align = input<'left' | 'right'>('left');

  protected error = signal<Error | null>(null);

  private formGroupDirective = signal<FormGroupDirective | null>(null);
  private next$ = new Subject<void>();

  constructor(
    private injector: Injector,
    private destroyRef: DestroyRef,
    private onyxDatePipe: OnyxDatePipe,
  ) {}

  public ngOnChanges(): void {
    this.formGroupDirective.set(
      this.injector.get(FormGroupDirective, null, {
        optional: true,
      }),
    );
    this.formGroupDirective()
      ?.ngSubmit.asObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.update());

    effect(
      () => {
        this.next$.next();
        this.update();

        for (const control of castArray(this.controls())) {
          const statusChanges$ = (
            control.statusChanges as Observable<any> | undefined
          )?.pipe(takeUntil(this.next$), takeUntilDestroyed(this.destroyRef));
          const valueChanges$ = (
            control.valueChanges as Observable<any> | undefined
          )?.pipe(takeUntil(this.next$), takeUntilDestroyed(this.destroyRef));

          statusChanges$?.subscribe(() => this.update());
          valueChanges$?.subscribe(() => this.update());
        }
      },
      { injector: this.injector },
    );
  }

  private update(): void {
    this.error.set(this.getError());
  }

  private getError(): Error | null {
    for (const control of castArray(this.controls())) {
      const errors = control.errors;
      const isDirty = control.dirty! && control.touched!;
      const isSubmitted = this.formGroupDirective()?.submitted ?? false;

      if (errors && (isDirty || isSubmitted)) {
        const [errorCode, errorValue] = Object.entries(control.errors)[0];
        return this.transformError(errorCode, errorValue);
      }
    }

    return null;
  }

  private transformError(errorCode: string, errorValue: any): Error {
    if (errorCode === 'min' && errorValue.min === Number.EPSILON) {
      return { code: 'minEpsilon', value: null };
    } else if (errorCode === 'required' && this.type() !== 'default') {
      return { code: 'requiredTrue', value: null };
    }

    const transformDate = (date: string) =>
      this.onyxDatePipe.transform(date, OnyxDateFormat.DATE_DOT);

    const transformedValue =
      {
        min: errorValue.min,
        max: errorValue.max,
        minlength: errorValue.requiredLength,
        maxlength: errorValue.requiredLength,
        minDate: transformDate(errorValue),
        maxDate: transformDate(errorValue),
      }[errorCode] ?? errorValue;

    return {
      code: errorCode,
      value: transformedValue,
    };
  }
}

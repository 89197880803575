<div class="not-found" [ngStyle]="{ height: height() }">
  <div class="animation">
    <div class="outer">
      <div class="inner">
        <onyx-logo type="signet" theme="light"></onyx-logo>
      </div>
    </div>

    <div class="circle"></div>
  </div>

  <p class="f-paragraph-regular-3">{{ heading() | translate }}</p>

  <p class="description f-medium-3" [innerHTML]="description() | translate"></p>
</div>

<div class="parameters f-medium-3">
  @if (fleet().fuelTankCapacity?.fuelType; as fuelType) {
    <p>{{ DictionaryCode.FUEL_TYPE + '.' + fuelType | translate }}</p>
  }

  @let lowDeck = fleet().additionalParameters.isLowDeck;
  @if (lowDeck != null) {
    <p>
      {{ (lowDeck ? 'labels.lowDeck' : 'labels.standardDeck') | translate }}
    </p>
  }

  @if (fleet().generalInformation.type; as type) {
    <p>
      {{ DictionaryCode.VEHICLE_TYPE + '.' + type | translate }}
    </p>
  }

  @let additionalParameters = fleet().additionalParameters;
  @if (additionalParameters.semiTrailerSize; as semiTrailerSize) {
    <p>
      {{ DictionaryCode.SEMI_TRAILER_SIZE + '.' + semiTrailerSize | translate }}
    </p>
  }

  @if (additionalParameters.trailerSize; as trailerSize) {
    <p>
      {{ DictionaryCode.TRAILER_SIZE + '.' + trailerSize | translate }}
    </p>
  }

  @if (cabotages(); as cabotages) {
    @let number = cabotages.number;
    @let limit = cabotages.limit;
    @let isLimitExceeded = number > limit;

    <p class="cabotages" [ngClass]="{ error: isLimitExceeded }">
      {{ 'labels.cabotages' | translate }}

      {{ number }}/{{ limit }}

      @if (isLimitExceeded) {
        <onyx-icon
          name="error-circle-empty"
          [size]="14"
          onyxTooltip="labels.cabotagesLimitExceeded"
          [onyxTooltipDelay]="TOOLTIP_DELAY"
          onyxTooltipColor="white"
        ></onyx-icon>
      }
    </p>
  }
</div>

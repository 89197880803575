import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { OnyxDateFormat, OnyxDatePipe } from '@onyx/angular';
import { ColorHelper } from '../../../helpers/color.helper';

@Component({
  selector: 'app-date-cell',
  imports: [OnyxDatePipe, TranslatePipe, NgTemplateOutlet],
  templateUrl: './date-cell.component.html',
  styleUrl: './date-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCellComponent {
  public date = input.required<string | null>();
  public format = input<OnyxDateFormat>(OnyxDateFormat.DATE_DOT);
  public showWarnings = input(false);
  public prefix = input<string | null>(null);

  protected cellColor = computed(() =>
    ColorHelper.getCellColor(this.date(), this.showWarnings()),
  );
}

import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { AmountCellComponent } from '../../../../../common/components/cells/amount-cell/amount-cell.component';
import { DateRangeCellComponent } from '../../../../../common/components/cells/date-range-cell/date-range-cell.component';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { ColorHelper } from '../../../../../common/helpers/color.helper';
import { DriverSettlementMethod } from '../../enums/driver-settlement-method';
import { Driver } from '../../interfaces/driver';

@Component({
  selector: 'app-driver-contract-cell',
  imports: [
    DateRangeCellComponent,
    TranslatePipe,
    AmountCellComponent,
    LowerCasePipe,
    NgTemplateOutlet,
  ],
  templateUrl: './driver-contract-cell.component.html',
  styleUrl: './driver-contract-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverContractCellComponent {
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly DriverSettlementMethod = DriverSettlementMethod;

  public employementConditions = input.required<
    Driver['employmentConditions'] | null
  >();

  protected cellColor = computed(() =>
    ColorHelper.getCellColor(
      this.employementConditions()!.contractTerm?.to as unknown as string,
    ),
  );
}

<div class="information-row {{ color() }} {{ size() }}">
  <div
    class="label"
    [onyxTooltip]="label()"
    [onyxTooltipEnabled]="
      OverflowHelper.enableOverflowTooltip.bind(this, labelTextElement)
    "
    #labelTextElement
  >
    {{ label() | translate }}
  </div>

  <div class="content f-medium-2">
    <ng-content></ng-content>
  </div>
</div>

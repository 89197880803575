@if (contractor(); as contactor) {
  <div class="contractor">
    <onyx-avatar
      type="company"
      size="m"
      [company]="{ logo: undefined }"
    ></onyx-avatar>

    <p>{{ contactor.companyProfile.displayName }}</p>
  </div>
} @else {
  -
}

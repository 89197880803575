import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  effect,
  signal,
  viewChildren,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { I18N_NAMESPACE } from '../../../../internal/constants';
import { OnyxDropdownOverlayService } from '../../../../internal/services/onyx-dropdown-overlay.service';
import { OnyxIconComponent } from '../../../icons';
import { CalendarRole, CalendarView } from '../../enums';
import {
  ONYX_DATEPICKER_CONFIG,
  OnyxDate,
  OnyxDatepickerConfig,
} from '../../interfaces';
import { OnyxDatepickerCalendarHeadbarComponent } from './onyx-datepicker-calendar-headbar/onyx-datepicker-calendar-headbar.component';
import { OnyxDatepickerCalendarComponent } from './onyx-datepicker-calendar/onyx-datepicker-calendar.component';

@Component({
  selector: 'onyx-datepicker-calendars',
  imports: [
    OnyxDatepickerCalendarComponent,
    OnyxDatepickerCalendarHeadbarComponent,
    OnyxIconComponent,
    TranslatePipe,
    NgClass,
  ],
  templateUrl: './onyx-datepicker-calendars.component.html',
  styleUrl: './onyx-datepicker-calendars.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxDatepickerCalendarsComponent implements OnInit {
  protected readonly CalendarView = CalendarView;
  protected readonly CalendarRole = CalendarRole;
  protected readonly DateTime = DateTime;
  protected readonly I18N = `${I18N_NAMESPACE}.datepicker`;

  protected listElements = viewChildren<ElementRef>('listElement');
  protected view = signal(CalendarView.CALENDAR);
  protected today = signal(DateTime.utc().toISODate());
  protected calendars = signal<{ from: string[]; to: string[] }>({
    from: [],
    to: [],
  });
  protected date = signal<OnyxDate>({});
  protected disableDate = signal<
    (dateRange: OnyxDate, date: string) => boolean
  >(() => false);
  protected yearsRange = signal<number[]>([]);

  constructor(
    @Inject(ONYX_DATEPICKER_CONFIG)
    protected config: OnyxDatepickerConfig,
    private injector: Injector,
    private onyxDropdownService: OnyxDropdownOverlayService,
  ) {
    if (config.showDateRange) {
      this.disableDate.set(this.disableDateBeforeFromDate);
    }
  }

  public ngOnInit(): void {
    const today = DateTime.utc().toISODate();
    const calendarDate = this.config.date().from ?? today;
    this.today.set(today);
    this.setCalendars(calendarDate);
    this.setYearsRange();

    effect(
      () => {
        const value = this.config.date();
        this.date.set(value);

        if (value.from) this.setCalendars(value.from);
      },
      { injector: this.injector },
    );

    effect(() => this.scrollToActiveElement(), { injector: this.injector });
  }

  @HostListener('click')
  protected disableFocusout(): void {
    this.config.disableFocusout();
  }

  protected setDate(value: OnyxDate, date: string): void {
    if (this.config.showDateRange) {
      this.setDateRange(value, date);
      if (value.to) this.onyxDropdownService.detachDropdown();
    } else {
      value.from = date;
      this.config.changeDate(value);
      this.onyxDropdownService.detachDropdown();
    }
  }

  protected setDateRange(value: OnyxDate, date: string): void {
    if (!value.from) {
      value.from = date;
    } else if (!value.to) {
      value.to = date;
      this.onyxDropdownService.detachDropdown();
    } else {
      value.from = date;
      value.to = null;
    }
    this.config.changeDate(value);
  }

  protected clear(): void {
    this.date.set({});
    this.config.changeDate({});
  }

  protected changeView(view: CalendarView): void {
    if (view === CalendarView.CALENDAR) {
      this.setCalendars(this.calendars().from[0]);
    }
    this.view.set(view);
  }

  protected setRelativeDate(days: 0 | 1 | 2): void {
    const date = DateTime.utc();
    const newDate = date.plus({ days });
    this.date().from = newDate.toISODate();
    this.config.changeDate(this.date());
  }

  protected selectCalendarYear(year: number): void {
    const date = DateTime.fromISO(this.calendars().from[0]);
    const newDate = date.set({ year });
    this.setCalendars(newDate.toISODate());
    this.view.set(CalendarView.CALENDAR);
  }

  protected selectCalendarMonth(month: number): void {
    const date = DateTime.fromISO(this.calendars().from[0]);
    const newDate = date.set({ month });
    this.setCalendars(newDate.toISODate());
    this.view.set(CalendarView.CALENDAR);
  }

  protected changeCalendarMonth(value: -1 | 1): void {
    const date = DateTime.fromISO(this.calendars().from[0]);
    const newDate = date.plus({ months: value });
    this.setCalendars(newDate.toISODate());
  }

  protected setCalendars(date: string | null): void {
    if (!date) {
      this.calendars.set({ from: [], to: [] });
      return;
    }

    const calendars: { from: string[]; to: string[] } = {
      from: this.createCalendarDays(date),
      to: [],
    };

    if (this.config.showDateRange) {
      const toDate = DateTime.fromISO(date).plus({ months: 1 });
      calendars.to = this.createCalendarDays(toDate.toISODate()!);
    }

    this.calendars.set(calendars);
  }

  private createCalendarDays(date: string): string[] {
    const calendar: string[] = [];
    const targetDate = DateTime.fromISO(date);
    const monthNumber = targetDate.month;
    let calendarDate = targetDate.startOf('month');

    do {
      calendar.push(calendarDate.toISODate()!);
      calendarDate = calendarDate.plus({ days: 1 });
    } while (calendarDate.month === monthNumber);

    return calendar;
  }

  private disableDateBeforeFromDate = (
    value: OnyxDate,
    date: string,
  ): boolean => {
    return !value.to && value.from && value.from > date ? true : false;
  };

  private setYearsRange(): void {
    const years = [];
    let year = DateTime.utc().year - this.config.yearsRange.past;
    const yearRange =
      year + this.config.yearsRange.past + this.config.yearsRange.future;

    do years.push(year);
    while (++year <= yearRange);

    this.yearsRange.set(years);
  }

  private scrollToActiveElement() {
    const activeElement = this.listElements().find((element) =>
      element.nativeElement.classList.contains('active'),
    );
    if (activeElement) {
      activeElement.nativeElement.scrollIntoView({
        block: 'center',
      });
    }
  }
}

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxButtonComponent,
  OnyxIconBoxColor,
  OnyxIconBoxComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxModalService,
  OnyxNamePipe,
} from '@onyx/angular';
import { FleetIdentifierPipe } from '../../../../common/components/pipes/fleet-identifier.pipe';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import {
  FleetSetsModalComponent,
  FleetSetsModalData,
} from '../../common/components/fleet-sets-modal/fleet-sets-modal.component';
import { FleetHelper } from '../../common/helpers/fleet.helper';
import { Fleet } from '../../common/interfaces/fleet';
import { FleetModalParametersComponent } from '../fleet-modal-parameters/fleet-modal-parameters.component';
import { FleetModalVehicleHeadingComponent } from '../fleet-modal-vehicle-heading/fleet-modal-vehicle-heading.component';

@Component({
  selector: 'app-fleet-modal-assigned-vehicle',
  imports: [
    TranslatePipe,
    FleetIdentifierPipe,
    OnyxButtonComponent,
    OnyxIconBoxComponent,
    OnyxIconComponent,
    FleetModalVehicleHeadingComponent,
    FleetModalParametersComponent,
    OnyxIconButtonComponent,
    NgClass,
    OnyxNamePipe,
  ],
  templateUrl: './fleet-modal-assigned-vehicle.component.html',
  styleUrl: './fleet-modal-assigned-vehicle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalAssignedVehicleComponent {
  protected readonly I18N = 'fleet.fleetModal';

  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OnyxIconBoxColor = OnyxIconBoxColor;

  public vehicle = input<Fleet | null>();
  public primaryVehicle = input<Fleet | null>(null);
  public isVehicle = input.required({
    transform: (data: boolean) => !data,
  });

  constructor(
    private modalService: OnyxModalService,
    private fleetHelper: FleetHelper,
  ) {}

  protected assignVehicle(): void {
    const primaryVehicle = this.primaryVehicle();
    if (!primaryVehicle) return;

    this.modalService.open<FleetSetsModalData>(
      FleetSetsModalComponent,
      primaryVehicle,
    );
  }

  protected openVehicleModal(vehicle: Fleet): void {
    this.fleetHelper.openModal(vehicle);
  }
}

<section>
  <div
    class="statuses"
    [ngClass]="{
      single: delegation().status.value === DelegationStatus.CANCELED,
    }"
  >
    <app-delegation-modal-status [status]="delegation().status">
    </app-delegation-modal-status>

    <app-delegation-modal-realization-status [delegation]="delegation()">
    </app-delegation-modal-realization-status>
  </div>

  <app-delegation-modal-period
    [start]="delegation().start"
    [end]="delegation().end"
    [status]="delegation().status.value"
    [uuid]="delegation().uuid"
    [close$]="close$()"
  ></app-delegation-modal-period>

  <app-delegation-modal-vehicles
    [vehicle]="delegation().vehicle"
    [trailer]="delegation().trailer"
    [uuid]="delegation().uuid"
    [close$]="close$()"
  ></app-delegation-modal-vehicles>

  <app-delegation-modal-note
    [note]="delegation().note"
    [uuid]="delegation().uuid"
    [close$]="close$()"
  ></app-delegation-modal-note>
</section>

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  OnyxIconBoxColor,
  OnyxIconBoxComponent,
  OnyxIconComponent,
  OnyxLanguagePipe,
  OnyxTemperaturePipe,
} from '@onyx/angular';
import { isArray } from 'lodash';
import { UnitCellComponent } from '../../../../common/components/cells/unit-cell/unit-cell.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { Fleet } from '../../common/interfaces/fleet';

@Component({
  selector: 'app-fleet-modal-parameters',
  imports: [
    OnyxIconBoxComponent,
    OnyxIconComponent,
    TranslatePipe,
    OnyxTemperaturePipe,
    OnyxLanguagePipe,
    UnitCellComponent,
  ],
  templateUrl: './fleet-modal-parameters.component.html',
  styleUrl: './fleet-modal-parameters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetModalParametersComponent {
  protected readonly DictionaryCode = DictionaryCode;
  protected readonly OnyxIconBoxColor = OnyxIconBoxColor;
  protected readonly Object = Object;

  public vehicle = input.required<Fleet>();

  protected parameters = computed(() => {
    return Object.entries(this.vehicle()!.additionalParameters).filter(
      ([key, value]) =>
        key !== 'isLowDeck' &&
        value != null &&
        value !== false &&
        (isArray(value) ? value.length !== 0 : true),
    );
  });
}

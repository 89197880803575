<div class="form-group">
  @if (label(); as label) {
    <onyx-input-label
      [type]="type()"
      [label]="label"
      [optional]="optional()"
      [disabled]="disabled()"
      [hint]="hint()"
      (click)="labelClick.emit()"
    ></onyx-input-label>
  }

  <div class="group" [ngStyle]="{ 'gap.px': gap() }">
    <ng-content></ng-content>
  </div>

  <ng-content select="onyx-suggestions"></ng-content>
</div>

@if (controls() && errors()) {
  <onyx-form-control-error
    [controls]="controls()!"
    [align]="errorsAlign()"
  ></onyx-form-control-error>
}

@if (amount(); as amount) {
  @let currency = DictionaryCode.CURRENCY + '.' + amount.currency | translate;
  <div class="amount-cell">
    <span>
      {{
        amount.value / 100
          | currency: currency : (short() ? 'symbol' : 'code') : '1.0-2'
      }}
    </span>

    @if (percentage(); as percentage) {
      <span>({{ percentage }}%)</span>
    }
  </div>
} @else {
  -
}

<onyx-modal
  type="center"
  size="m"
  [heading]="
    fleet.trailer || fleet.vehicle ? I18N + '.editSet' : I18N + '.newSet'
  "
  [close$]="close$"
  (closeModal)="dialogRef.close()"
>
  <div class="container">
    @if (fleet.trailer) {
      <onyx-tabs
        [label]="I18N + '.whatToDo'"
        size="m"
        [tabs]="TABS"
        [fill]="true"
        [forceOptional]="true"
        [(value)]="mode"
      ></onyx-tabs>
    }
    @let isChangeMode = mode() === FleetSetsModalSection.CHANGE;
    @if (isChangeMode) {
      <form class="switch" [formGroup]="changeForm">
        <div class="item">
          @let controls = changeForm.controls;
          <onyx-dropdown
            [formControl]="controls.vehicle"
            label="labels.vehicle"
            [options]="VEHICLES_SOURCE"
            [optional]="true"
            (selectedValueChange)="vehicle.set($event)"
          ></onyx-dropdown>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="l"
            [disabled]="!vehicle()"
            (click)="openFleetModal(vehicle())"
          >
            <onyx-icon name="side-modal" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>

        <div class="item">
          <onyx-dropdown
            [formControl]="controls.trailer"
            label="labels.trailer"
            [options]="TRAILERS_SOURCE"
            [optional]="true"
            (selectedValueChange)="trailer.set($event)"
          ></onyx-dropdown>

          <onyx-icon-button
            type="transparent"
            color="black"
            size="l"
            [disabled]="!trailer()"
            (click)="openFleetModal(trailer())"
          >
            <onyx-icon name="side-modal" [size]="16"></onyx-icon>
          </onyx-icon-button>
        </div>
      </form>

      @if (
        warningMessage() &&
        (isVehicle
          ? changeForm.controls.trailer.dirty
          : changeForm.controls.vehicle.dirty) &&
        changeForm.valid
      ) {
        <onyx-message size="m" type="warning" [borderRadius]="true">
          <onyx-icon name="error-circle-empty" [size]="16"></onyx-icon>

          @if (warningMessage()?.currentVehicle; as vehicle) {
            {{ I18N + '.unassignTrailer' | translate: { vehicle } }}
          } @else {
            {{
              I18N + '.reassign'
                | translate
                  : {
                      vehicle: warningMessage()?.assignedVehicle,
                      trailer: warningMessage()?.currentTrailer,
                    }
            }}
          }
        </onyx-message>
      }
    } @else {
      <form class="swap" [formGroup]="swapForm">
        @let first = firstSet(); @let second = secondSet();
        <div class="item">
          @let controls = swapForm.controls;
          <onyx-dropdown
            [formControl]="controls.firstSet"
            [label]="I18N + '.firstSet'"
            [hint]="I18N + '.secondaryDriverHint'"
            [options]="setsSource"
            (selectedValueChange)="firstSet.set($event)"
          ></onyx-dropdown>

          @if (first && second) {
            <onyx-icon name="arrow-right" [size]="16"></onyx-icon>

            @let value =
              first.generalInformation.registrationNumber +
              ' · ' +
              second.trailer!.generalInformation.registrationNumber;
            <onyx-text-field
              [label]="I18N + '.firstSet'"
              [disabled]="true"
              [value]="value"
            ></onyx-text-field>
          }
        </div>

        <div class="item">
          <onyx-dropdown
            [formControl]="controls.secondSet"
            [label]="I18N + '.secondSet'"
            [options]="setsSource"
            [optional]="true"
            (selectedValueChange)="secondSet.set($event)"
          ></onyx-dropdown>

          @if (second && first) {
            <onyx-icon name="arrow-right" [size]="16"></onyx-icon>

            @let value =
              second.generalInformation.registrationNumber +
              ' · ' +
              first.trailer!.generalInformation.registrationNumber;
            <onyx-text-field
              [label]="I18N + '.secondSet'"
              [disabled]="true"
              [value]="value"
            ></onyx-text-field>
          }
        </div>
      </form>
    }
  </div>

  <ng-container bottomRightOptions>
    <onyx-button type="outlined" color="black" (click)="close$.next()">
      {{ 'buttons.cancel' | translate }}
    </onyx-button>

    <onyx-button
      type="primary"
      color="blue"
      [spinner]="loading()"
      (click)="
        !loading()
          ? isChangeMode
            ? submitChangeForm()
            : submitSwapForm()
          : null
      "
      [disabled]="
        isChangeMode ? false : !swapForm.controls.secondSet.getRawValue()
      "
    >
      {{ 'buttons.save' | translate }}
    </onyx-button>
  </ng-container>
</onyx-modal>

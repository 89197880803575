import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnyxPaginated, OnyxPagination } from '@onyx/angular';
import { Observable, of, Subject, tap } from 'rxjs';
import { ApiService } from '../../../../common/services/api.service';
import { OrderFormDto } from '../../order-form/order-form.component';
import { OrderModalOutsourcingForm } from '../../order-modal/order-modal-main-section/outsourcing/order-modal-outsourcing-form/order-modal-outsourcing-form.component';
import { OrderCategory } from '../enums/order-category';
import { OrderRejectReason } from '../enums/order-reject-reason';
import { OrderStatus } from '../enums/order-status';
import { ManualProsposalData, Order } from '../interfaces/order';
import { ORDER_MOCKS } from '../mocks/order-mocks';

@Injectable({
  providedIn: 'root',
})
export class OrdersService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(protected override http: HttpClient) {
    super(http);
  }

  public listOrders(
    _params: {
      category: OrderCategory;
      statuses: OrderStatus[] | null;
      recommendedAction: boolean;
      showAssignedOnly: boolean;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Order>> {
    // return this.get('/orders', {
    //   params: {
    //     category: params.category,
    //     'status[]': params.statuses ?? [],
    //     recommendedAction: params.recommendedAction,
    //     showAssignedOnly: params.showAssignedOnly,
    //     page: params.page,
    //     limit: params.limit,
    //   },
    // });
    return of(ORDER_MOCKS);
  }

  public getOrder(_uuid: string): Observable<Order> {
    // return this.get<Order>(`/orders/${uuid}`);

    return of(ORDER_MOCKS.items[0]);
  }

  public addOrder(dto: OrderFormDto): Observable<Order> {
    return this.post<Order>('/orders', dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public editOrder(uuid: string, dto: OrderFormDto): Observable<Order> {
    return this.put<Order>(`/orders/${uuid}`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public acceptVehicle(uuid: string): Observable<void> {
    return this.post<void>(`/orders/${uuid}/accept-vehicle`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public rejectVehicle(
    uuid: string,
    dto:
      | {
          reason: Exclude<OrderRejectReason, OrderRejectReason.OTHER>;
          other: null;
        }
      | {
          reason: OrderRejectReason.OTHER;
          other: string;
        },
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/reject-vehicle`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public addManualProposal(
    uuid: string,
    vehicleUuid: string,
  ): Observable<ManualProsposalData> {
    return this.post<ManualProsposalData>(`/orders/${uuid}/manual-proposal`, {
      vehicleUuid,
    }).pipe(tap(() => this._reload$.next()));
  }

  public deleteManualProposal(uuid: string): Observable<void> {
    return this.delete<void>(`/orders/${uuid}/manual-proposal`).pipe(
      tap(() => this._reload$.next()),
    );
  }
  public confirmManualProposal(
    uuid: string,
    vehicleUuid: string,
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/manual-proposal/confirm`, {
      vehicleUuid,
    }).pipe(tap(() => this._reload$.next()));
  }

  public addOutsourcing(
    uuid: string,
    dto: OrderModalOutsourcingForm,
  ): Observable<void> {
    return this.post<void>(`/orders/${uuid}/outsourcing`, dto).pipe(
      tap(() => this._reload$.next()),
    );
  }
}

import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ONYX_TOASTS_LIMIT,
  OnyxDropdownOptionsSourceResult,
  OnyxFilterPipe,
  OnyxPaginated,
  OnyxPagination,
  OnyxPhonePipe,
  OnyxToastService,
} from '@onyx/angular';
import { chain, isString } from 'lodash';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  EMPTY,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { CONFLICT_ERROR_CODE } from '../../../../../common/constants/conflict-error-code';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { BatchFileUpload } from '../../../../../common/interfaces/utilities/batch-file-upload';
import { ApiService } from '../../../../../common/services/api.service';
import { StorageService } from '../../../../../common/services/storage.service';
import { ImportProgress } from '../../../../common/interfaces/import-progress';
import { ContractorFormDto } from '../../contractor-form/contractor-form.component';
import { BlockContractorModalForm } from '../components/block-contractor-modal/block-contractor-modal.component';
import { ContractorsImportData } from '../constants/contractors-import-schema';
import { ContractorCategory } from '../enums/contractor-category';
import { ContractorStatus } from '../enums/contractor-status';
import { ContractorType } from '../enums/contractor-type';
import { Contractor } from '../interfaces/contractor';

@Injectable({
  providedIn: 'root',
})
export class ContractorsService extends ApiService {
  private _reload$ = new Subject<void>();
  public get reload$() {
    return this._reload$.asObservable();
  }

  constructor(
    protected override http: HttpClient,
    private storageService: StorageService,
    private filterPipe: OnyxFilterPipe,
    private toastService: OnyxToastService,
    private translateService: TranslateService,
    private phonePipe: OnyxPhonePipe,
  ) {
    super(http);
  }

  public listContractors(
    params: {
      category?: ContractorCategory;
      status?: ContractorStatus[];
      types?: ContractorType[];
      country?: string;
      vatId?: string;
    } & OnyxPagination,
  ): Observable<OnyxPaginated<Contractor>> {
    return this.get('/contractor', {
      params: {
        ...(params.category && { category: params.category }),
        ...(params.types &&
          params.types.length > 0 && { 'types[]': params.types }),
        ...(params.status &&
          params.status.length > 0 && { 'status[]': params.status }),
        ...(params.country && { country: params.country }),
        ...(params.vatId && { vatId: params.vatId }),
        page: params.page,
        limit: params.limit,
      },
    });
  }

  public searchContractors(
    query: string,
    limit: number,
    params?: Omit<
      Parameters<ContractorsService['listContractors']>[0],
      keyof OnyxPagination
    >,
  ): Observable<OnyxDropdownOptionsSourceResult<Contractor>> {
    return this.listContractors({
      ...params,
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
    }).pipe(
      map((response) => ({
        options: chain(response.items)
          .map((contractor) => ({
            name: contractor.companyProfile.displayName,
            value: contractor,
          }))
          .thru((options) =>
            this.filterPipe.transform(options, query, ['name']).slice(0, limit),
          )
          .value(),
        totalItems: response.totalItems,
      })),
    );
  }

  public getContractor(uuid: string): Observable<Contractor> {
    return this.get(`/contractor/${uuid}`);
  }

  public addContractor(dto: ContractorFormDto): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.post<void>('/contractor', dto)),
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public importContractors(
    data: ContractorsImportData[],
  ): Observable<ImportProgress> {
    const dto = data.map(
      (item): ContractorFormDto => ({
        companyProfile: {
          displayName: item.displayName,
          companyName: item.company.companyName,
          country: item.country,
          vatId: item.vatId,
          types: item.types,
          phone: item.phone,
        },
        contactPersons: [],
        documents: [],
        note: item.note,
        payments: {
          paymentTermDays: item.paymentTermDays,
          tradeCreditLimit: item.tradeCreditLimit,
          hasUnlimitedCreditLimit: item.tradeCreditLimit == null,
          electronicInvoiceEmail: item.electronicInvoiceEmail,
        },
        addresses: {
          mainAddress: item.mainAddress,
          correspondenceAddress: item.correspondenceAddress,
        },
        branches: [],
        pointOfInterests: [],
      }),
    );

    return from(dto).pipe(
      concatMap((dto, index) =>
        this.addContractor(dto).pipe(
          map(() => ({ ok: true, index })),
          defaultIfEmpty({ ok: false, index }),
          catchError((response) => {
            ValidationHelper.handleUnexpectedError(response, this.toastService);
            return of({ ok: false, index });
          }),
        ),
      ),
      takeWhile(({ ok }) => ok, true),
    );
  }

  public editContractor(
    uuid: string,
    dto: ContractorFormDto,
  ): Observable<void> {
    return this.uploadFiles(dto).pipe(
      switchMap((dto) => this.put<void>(`/contractor/${uuid}`, dto)),
      catchError((response) => this.catchConflictErrors(dto, response)),
      tap(() => this._reload$.next()),
    );
  }

  public blockContractor(
    uuid: string,
    form: BlockContractorModalForm,
  ): Observable<void> {
    return this.put<void>(`/contractor/${uuid}/status`, {
      status: ContractorStatus.BLOCKED,
      block: form,
    }).pipe(tap(() => this._reload$.next()));
  }

  public unblockContractor(uuid: string): Observable<void> {
    return this.put<void>(`/contractor/${uuid}/status`, {
      status: ContractorStatus.ACTIVE,
      block: null,
    }).pipe(tap(() => this._reload$.next()));
  }

  public archiveContractor(uuid: string): Observable<void> {
    return this.post<void>(`/contractor/${uuid}/archive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  public unarchiveContractor(uuid: string): Observable<void> {
    return this.post<void>(`/contractor/${uuid}/unarchive`, undefined).pipe(
      tap(() => this._reload$.next()),
    );
  }

  private uploadFiles(dto: ContractorFormDto): Observable<ContractorFormDto> {
    return of(dto).pipe(
      map((dto): BatchFileUpload[] => [
        ...dto.documents.map((document, index) => ({
          path: `documents[${index}].scan`,
          files: document.scan,
        })),
      ]),
      switchMap((data) => this.storageService.uploadBatch(data)),
      map((data) => this.storageService.mergeBatch(dto, data)),
    );
  }

  private catchConflictErrors(
    dto: ContractorFormDto,
    response: HttpErrorResponse,
  ): Observable<never> {
    if (response.status !== HttpStatusCode.BadRequest) throw response;

    if (
      response.error.companyProfile?.vatId ===
      'Value must be unique per country'
    ) {
      this.toastService.showError(
        this.translateService.instant(
          'contractors.toasts.conflictErrors.vatId',
          {
            country: dto.companyProfile.country.toUpperCase(),
            vatId: dto.companyProfile.vatId,
          },
        ),
      );
      return EMPTY;
    }

    if (response.error.companyProfile?.displayName === CONFLICT_ERROR_CODE) {
      this.toastService.showError(
        this.translateService.instant(
          'contractors.contractForm.uniqueErrors.displayName',
          { displayName: dto.companyProfile.displayName },
        ),
      );
      return EMPTY;
    }

    const CONFLICT_KEYS = ['email', 'phone', 'timocomId', 'transId'] as const;

    const errors = response.error.contactPersons as Record<
      `${number}`,
      Record<(typeof CONFLICT_KEYS)[number], string | undefined>
    >;
    const conflictErrors = chain(CONFLICT_KEYS)
      .filter((key) =>
        Object.values(errors).some(
          (value) => value[key] === CONFLICT_ERROR_CODE,
        ),
      )
      .slice(0, ONYX_TOASTS_LIMIT)
      .value();

    for (const field of conflictErrors) {
      const errorValues = chain(errors)
        .keys()
        .map((key) => dto.contactPersons[+key][field])
        .map((value) =>
          isString(value) ? value : this.phonePipe.transform(value),
        )
        .join(', ')
        .value();

      this.toastService.showError(
        this.translateService.instant(
          `contractors.toasts.conflictErrors.${`${field}Exists`}`,
          { [field]: errorValues },
        ),
      );
    }

    if (conflictErrors.length) return EMPTY;

    throw response;
  }
}

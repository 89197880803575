import { ChangeDetectionStrategy, Component, model } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { I18N_NAMESPACE } from '../../../internal/constants';
import { OnyxButtonComponent } from '../../buttons';
import { OnyxIconComponent } from '../../icons';

@Component({
  selector: 'onyx-error',
  imports: [OnyxIconComponent, OnyxButtonComponent, TranslatePipe],
  templateUrl: './onyx-error.component.html',
  styleUrl: './onyx-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnyxErrorComponent {
  protected readonly I18N = `${I18N_NAMESPACE}.error`;

  public error = model.required<boolean>();
}

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { ImportProgress } from '../../../../common/interfaces/import-progress';
import { ContractorsImportData } from '../constants/contractors-import-schema';
import { ContractorsService } from '../services/contractors.service';

export const importContractorsResolver: ResolveFn<
  (data: ContractorsImportData[]) => Observable<ImportProgress>
> = () => {
  const contractorsService = inject(ContractorsService);
  return (data) => contractorsService.importContractors(data);
};

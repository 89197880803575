<onyx-data-widget
  [supheading]="
    DictionaryCode.RATE_TYPE + '.' + order().basicInformation.rateType
  "
  [headingIcon]="{ name: 'finance', size: 16, color: 'blue' }"
>
  <div heading class="order-price">
    <span
      [innerHTML]="
        totalOrderPrice().defaultCurrencyValue / 100
          | number: '1.2-2'
          | numberDecoration: (totalOrderPrice().defaultCurrency | uppercase)
      "
      [onyxTooltip]="clearingTooltip"
      [onyxTooltipDelay]="TOOLTIP_DELAY"
      onyxTooltipColor="white"
    ></span>

    <span class="vat f-semibold-2">+{{ 'labels.vat' | translate }}</span>
  </div>

  <div class="list">
    <div class="item f-medium-3">
      <p>{{ 'labels.loadedKilometerRate' | translate }}</p>

      <span class="rate">
        <app-amount-cell
          [amount]="{
            value: ratePerKm(),
            currency: totalOrderPrice().defaultCurrency,
          }"
          [short]="false"
        ></app-amount-cell>
        /km
      </span>
    </div>

    <div class="item f-medium-3">
      <p>{{ 'labels.dailyIncome' | translate }}</p>

      <span>
        <app-amount-cell
          [amount]="{
            value: pricePerDay(),
            currency: totalOrderPrice().defaultCurrency,
          }"
        ></app-amount-cell>
      </span>
    </div>
  </div>
</onyx-data-widget>

<ng-template #clearingTooltip>
  <div class="tooltip">
    <onyx-table
      [columns]="ORDER_MODAL_PLAN_FINANCES_COLUMNS"
      [data]="settlementData()"
      tableSize="s"
    ></onyx-table>

    @let rateDate = totalOrderPrice().date;
    @if (settlementData().length > 1 && rateDate) {
      <span>
        {{ 'labels.nbpRate' | translate }}:
        {{ rateDate | onyxDate: 'date-dot' }}</span
      >
    }
  </div>
</ng-template>

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-boolean-cell',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './boolean-cell.component.html',
  styleUrl: './boolean-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanCellComponent {
  public value = input.required<boolean | null>();
}

import { Pipe, PipeTransform } from '@angular/core';
import { OnyxOptional } from '../interfaces';

@Pipe({
  name: 'onyxName',
})
export class OnyxNamePipe implements PipeTransform {
  public transform(
    value: OnyxOptional<{
      firstName?: OnyxOptional<string>;
      lastName?: OnyxOptional<string>;
    }>,
  ): string {
    if (!value || (!value.firstName && !value.lastName)) return '-';

    const { firstName, lastName } = value;
    return `${firstName ?? ''}${lastName ? ` ${lastName}` : ''}`;
  }
}

import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { OnyxTime, OnyxTimePipe } from '@onyx/angular';

@Component({
  selector: 'app-time-cell',
  imports: [OnyxTimePipe],
  templateUrl: './time-cell.component.html',
  styleUrl: './time-cell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeCellComponent {
  public time = input.required<OnyxTime | null>();
}

import { OnyxChip } from '@onyx/angular';
import { EmployeeModalSection } from '../enums/employee-modal-section';

export const EMPLOYEE_MODAL_CHIPS: OnyxChip<EmployeeModalSection>[] = [
  {
    name: 'labels.assignedVehicles',
    value: EmployeeModalSection.ASSIGNED_VEHICLES,
  },
  {
    name: 'unavailabilities.unavailabilities',
    value: EmployeeModalSection.PLANNED_UNAVAILABILITIES,
  },
];

import { NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  AddressHelper,
  OnyxAddress,
  OnyxFlagComponent,
  OnyxTooltipDirective,
} from '@onyx/angular';
import { DictionaryCode } from '../../enums/dictionary-code';

export type AddressFormat =
  | 'extended'
  | 'full'
  | 'short'
  | 'double-line'
  | 'city'
  | 'coordinates';

@Component({
  selector: 'app-address',
  imports: [
    UpperCasePipe,
    OnyxFlagComponent,
    TranslatePipe,
    OnyxTooltipDirective,
    NgTemplateOutlet,
  ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {
  protected readonly DictionaryCode = DictionaryCode;

  public address = input.required<OnyxAddress | null>();
  public format = input.required<AddressFormat>();
  public showFlag = input(true);

  protected coordinatesLabel = computed(() => {
    const address = this.address();
    return address ? AddressHelper.composeCoordinatesLabel(address) : null;
  });
}

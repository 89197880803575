@if (document(); as document) {
  <div class="document-cell {{ cellColor() }}">
    <ng-container
      *ngTemplateOutlet="documentTemplate; context: { document }"
    ></ng-container>
  </div>

  <div class="hidden">
    <ng-container
      *ngTemplateOutlet="documentTemplate; context: { document }"
    ></ng-container>
  </div>
} @else {
  -
}

<ng-template let-document="document" #documentTemplate>
  <div class="date">
    {{ 'labels.to' | translate }}
    {{ document.expirationDate | onyxDate: 'date-dot' }}
  </div>

  <div class="container f-regular-3">
    <p>{{ document.number }}</p>

    <app-country-cell
      [countryCode]="document.issuingCountry"
    ></app-country-cell>
  </div>
</ng-template>

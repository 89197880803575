import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  Inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  DropdownHelper,
  OnyxButtonComponent,
  OnyxDropdownComponent,
  OnyxDropdownOptionsSource,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxMessageComponent,
  OnyxModalComponent,
  OnyxTab,
  OnyxTabsComponent,
  OnyxTextFieldComponent,
  OnyxToastService,
} from '@onyx/angular';
import { concatMap, map, Subject } from 'rxjs';
import { FleetIdentifierPipe } from '../../../../../common/components/pipes/fleet-identifier.pipe';
import { ValidationHelper } from '../../../../../common/helpers/validation.helper';
import { FleetState } from '../../enums/fleet-state';
import { FleetHelper } from '../../helpers/fleet.helper';
import { Fleet } from '../../interfaces/fleet';
import { FleetService } from '../../services/fleet.service';

export type FleetSetsModalData = Fleet;

interface WarningMessage {
  currentVehicle?: string;
  currentTrailer?: string;
  assignedVehicle?: string;
}

enum FleetSetsModalSection {
  CHANGE,
  SWAP,
}

@Component({
  selector: 'app-fleet-sets-modal',
  imports: [
    OnyxModalComponent,
    OnyxTabsComponent,
    OnyxDropdownComponent,
    ReactiveFormsModule,
    TranslatePipe,
    OnyxButtonComponent,
    OnyxMessageComponent,
    OnyxIconComponent,
    OnyxTextFieldComponent,
    OnyxIconButtonComponent,
    OnyxIconComponent,
  ],
  templateUrl: './fleet-sets-modal.component.html',
  styleUrl: './fleet-sets-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetSetsModalComponent {
  protected readonly I18N = 'fleet.fleetSetsModal';
  protected readonly TABS: OnyxTab<FleetSetsModalSection>[] = [
    { name: `${this.I18N}.changeTrailer`, value: FleetSetsModalSection.CHANGE },
    { name: `${this.I18N}.changeSets`, value: FleetSetsModalSection.SWAP },
  ];
  protected readonly isVehicle = FleetHelper.isVehicle(this.fleet);
  protected readonly VEHICLES_SOURCE = this.fleetHelper.getVehiclesSource();
  protected readonly TRAILERS_SOURCE = this.fleetHelper.getTrailersSource();
  protected readonly setsSource: OnyxDropdownOptionsSource<Fleet> = {
    list: (query, limit) =>
      this.fleetService
        .searchFleet(query, limit, {
          category: [],
          state: FleetState.ACTIVE,
          showSetsOnly: true,
        })
        .pipe(
          map((result) => ({
            ...result,
            options: DropdownHelper.updateGroupOptions(
              result.options,
              (options) =>
                options.filter(
                  ({ value }) =>
                    value.uuid !== this.changeForm.getRawValue()?.vehicle,
                ),
            ),
          })),
        ),
    get: (uuid) =>
      this.fleetService.getFleet(uuid).pipe(
        map((fleet) => ({
          name: `${this.fleetIdentifierPipe.transform(
            fleet.generalInformation,
            'bracket',
          )} · ${this.fleetIdentifierPipe.transform(
            fleet.trailer!.generalInformation,
            'bracket',
          )}`,
          value: fleet,
        })),
      ),
    idKey: 'uuid',
  };

  protected readonly FleetSetsModalSection = FleetSetsModalSection;

  protected changeForm = this.buildChangeForm();
  protected changeFormChanges = toSignal(
    this.changeForm.valueChanges.pipe(map(() => this.changeForm.getRawValue())),
  );
  protected swapForm = this.buildSwapForm();
  protected vehicle = signal<Fleet | null>(null);
  protected trailer = signal<Fleet | null>(null);
  protected firstSet = signal<Fleet | null>(null);
  protected secondSet = signal<Fleet | null>(null);
  protected mode = signal(FleetSetsModalSection.CHANGE);
  protected close$ = new Subject<void>();
  protected loading = signal(false);
  protected warningMessage = signal<WarningMessage | null>(null);

  constructor(
    @Inject(DIALOG_DATA) protected fleet: FleetSetsModalData,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private fleetService: FleetService,
    private toastService: OnyxToastService,
    private fleetIdentifierPipe: FleetIdentifierPipe,
    private fleetHelper: FleetHelper,
  ) {
    effect(() => {
      const vehicle = this.vehicle();
      const trailer = this.trailer();
      const trailerVehicle = trailer?.vehicle;

      if (!trailer && vehicle) {
        this.warningMessage.set({
          currentVehicle: vehicle.generalInformation.registrationNumber,
        });
      } else if (
        vehicle &&
        trailerVehicle &&
        vehicle.uuid !== trailerVehicle.uuid
      ) {
        this.warningMessage.set({
          currentTrailer: trailer.generalInformation.registrationNumber,
          assignedVehicle: trailerVehicle.generalInformation.registrationNumber,
        });
      } else {
        this.warningMessage.set(null);
      }
    });
  }

  protected submitChangeForm(): void {
    if (!ValidationHelper.checkValidity(this.changeForm, this.toastService)) {
      return;
    }

    const form = this.changeForm.getRawValue();
    this.loading.set(true);

    this.fleetService
      .assignTrailer(form.vehicle!, form.trailer ?? null)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.changeSucced`);
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  protected openFleetModal(fleet: Fleet | null): void {
    if (fleet) this.fleetHelper.openModal(fleet);
  }

  protected submitSwapForm(): void {
    if (!ValidationHelper.checkValidity(this.swapForm, this.toastService)) {
      return;
    }

    const form = this.swapForm.getRawValue();
    this.loading.set(true);

    this.fleetService
      .assignTrailer(form.firstSet, this.secondSet()!.trailer!.uuid, false)
      .pipe(
        concatMap(() =>
          this.fleetService.assignTrailer(
            this.secondSet()!.uuid,
            this.firstSet()!.trailer!.uuid,
          ),
        ),
      )
      .subscribe({
        next: () => {
          this.toastService.showSuccess(`${this.I18N}.swapSucceed`);
          this.close$.next();
        },
        error: (response) =>
          ValidationHelper.handleUnexpectedError(response, this.toastService),
      })
      .add(() => this.loading.set(false));
  }

  private buildChangeForm() {
    return this.fb.group({
      vehicle: this.fb.control<string | null>(
        {
          value: this.isVehicle
            ? this.fleet.uuid
            : (this.fleet.vehicle?.uuid ?? null),
          disabled: this.isVehicle,
        },
        [Validators.required],
      ),
      trailer: this.fb.control<string | null>({
        value: this.isVehicle
          ? (this.fleet.trailer?.uuid ?? null)
          : this.fleet.uuid,
        disabled: !this.isVehicle,
      }),
    });
  }

  private buildSwapForm() {
    return this.fb.group({
      firstSet: this.fb.control<string>(
        { value: this.fleet.uuid, disabled: true },
        [Validators.required],
      ),
      secondSet: this.fb.control<string | null>(null, [Validators.required]),
    });
  }
}

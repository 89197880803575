import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import {
  ONYX_TOOLTIP_DELAY,
  OnyxDataWidgetComponent,
  OnyxDatePipe,
  OnyxIconComponent,
  OnyxIconName,
  OnyxInformationHeadingComponent,
  OnyxInformationRowComponent,
  OnyxNamePipe,
  OnyxTemperaturePipe,
} from '@onyx/angular';
import { AmountCellComponent } from '../../../../../common/components/cells/amount-cell/amount-cell.component';
import { JoinPipe } from '../../../../../common/components/pipes/join.pipe';
import { DictionaryCode } from '../../../../../common/enums/dictionary-code';
import { CommonHelper } from '../../../../../common/helpers/common.helper';
import { OrderSemiTrailerSize } from '../../../common/enums/order-semi-trailer-size';
import { Order } from '../../../common/interfaces/order';

@Component({
  selector: 'app-order-modal-details',
  standalone: true,
  imports: [
    OnyxInformationHeadingComponent,
    OnyxInformationRowComponent,
    TranslatePipe,
    OnyxIconComponent,
    OnyxTemperaturePipe,
    JoinPipe,
    OnyxDatePipe,
    OnyxDataWidgetComponent,
    AmountCellComponent,
    OnyxNamePipe,
  ],
  templateUrl: './order-modal-details.component.html',
  styleUrl: './order-modal-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderModalDetailsComponent {
  protected readonly TOOLTIP_DELAY = ONYX_TOOLTIP_DELAY;

  protected readonly DictionaryCode = DictionaryCode;

  public order = input.required<Order>();

  protected semiTrailerSizeIcon = computed<OnyxIconName>(() => {
    const trailerSize = this.order().basicInformation.minSemiTrailerSize;
    if (trailerSize === OrderSemiTrailerSize.AUTO) return 'refresh';

    return `semi-trailer-${trailerSize}`;
  });

  protected parameters = computed(() => {
    const parameters = CommonHelper.getSortedParameters(
      this.order().parameters,
      this.translateService,
    );

    const driverRequirementsKeys = ['driverLanguages', 'hasSanitaryInspection'];
    const otherRequirementsKeys = [
      'hasSafeParking',
      'isDedicated',
      'hasEcmr',
      'isExclusive',
      'sentNotificationNumber',
      'hasPalletExchange',
    ];

    const orderRequirements = parameters.reduce(
      (result, parameter) => {
        const { key } = parameter;

        if (driverRequirementsKeys.includes(key)) {
          result.driverRequirements.push(parameter);
        } else if (otherRequirementsKeys.includes(key)) {
          result.otherRequirements.push(parameter);
        } else {
          result.vehicleRequirements.push(parameter);
        }

        return result;
      },
      {
        driverRequirements: [] as typeof parameters,
        otherRequirements: [] as typeof parameters,
        vehicleRequirements: [] as typeof parameters,
      },
    );

    return {
      ...orderRequirements,
      vehicleRequirements: [
        ...orderRequirements.vehicleRequirements,
        {
          key: 'minSemiTrailerSize',
          name: 'labels.minSemiTrailerSize',
          value: this.order().basicInformation.minSemiTrailerSize,
        },
        {
          key: 'cargoSpace',
          name: 'labels.cargoSpace',
          value: this.order().basicInformation.vehicleTypes,
        },
        {
          key: 'dischargeTypes',
          name: 'labels.dischargeType',
          value: this.order().basicInformation.dischargeTypes,
        },
      ],
    };
  });

  constructor(private translateService: TranslateService) {}
}

import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OnyxMapService {
  public readonly CENTER_POINT = new H.geo.Point(
    47.45402532725695,
    9.131194340990346,
  );
  public readonly MIN_ZOOM = 4;
  public readonly INITIAL_ZOOM = 5;
  public readonly ZOOM_STEP = 0.5;
  public readonly BEHAVIORS = [
    H.mapevents.Behavior.DBLTAPZOOM,
    H.mapevents.Behavior.DRAGGING,
    H.mapevents.Behavior.WHEELZOOM,
  ] as const;

  private standaloneMap_ = signal<any | null>(null); // FIXME: Circular error for OnyxMapComponent
  public get standaloneMap() {
    return this.standaloneMap_.asReadonly();
  }

  public openStandaloneMap(): void {
    alert('TODO(ONYX-1056): Implement standalone map');

    // const windowRef = ActionHelper.openLink(undefined, '_blank');
    // if (!windowRef) return;
  }
}

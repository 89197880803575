import { OnyxComponentInputs, OnyxTableColumn } from '@onyx/angular';
import { AddressCellComponent } from '../../../../common/components/cells/address-cell/address-cell.component';
import { BaseCellComponent } from '../../../../common/components/cells/base-cell/base-cell.component';
import { CountryCellComponent } from '../../../../common/components/cells/country-cell/country-cell.component';
import { DateCellComponent } from '../../../../common/components/cells/date-cell/date-cell.component';
import { DocumentCellComponent } from '../../../../common/components/cells/document-cell/document-cell.component';
import { NumberCellComponent } from '../../../../common/components/cells/number-cell/number-cell.component';
import { PhoneCellComponent } from '../../../../common/components/cells/phone-cell/phone-cell.component';
import { StringCellComponent } from '../../../../common/components/cells/string-cell/string-cell.component';
import { DictionaryCode } from '../../../../common/enums/dictionary-code';
import { DriverContractCellComponent } from '../cells/driver-contract-cell/driver-contract-cell.component';
import { DriverLicenseCellComponent } from '../cells/driver-license-cell/driver-license-cell.component';
import { Driver, DriverIdentityDocument } from '../interfaces/driver';
import { DriversImportData } from './drivers-import-schema';

export const DRIVERS_IMPORT_COLUMNS: OnyxTableColumn<
  DriversImportData & { index: number }
>[] = [
  {
    required: true,
    id: 'index',
    name: 'labels.row',
    width: 'fill',
    component: {
      ref: NumberCellComponent,
      inputs: (data): OnyxComponentInputs<NumberCellComponent> => ({
        value: data.item.index,
      }),
    },
  },
  {
    id: 'firstName',
    name: 'labels.firstName',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.driverData.firstName,
      }),
    },
  },
  {
    id: 'lastName',
    name: 'labels.lastName',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.driverData.lastName,
      }),
    },
  },
  {
    id: 'peselNumber',
    name: 'labels.peselNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.driverData.peselNumber,
      }),
    },
  },
  {
    id: 'birthDate',
    name: 'labels.birthDate',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.driverData.birthDate,
      }),
    },
  },
  {
    id: 'privatePhone',
    name: 'labels.privatePhone',
    width: 'fill',
    component: {
      ref: PhoneCellComponent,
      inputs: (data): OnyxComponentInputs<PhoneCellComponent> => ({
        phone: data.item.driverData.privatePhone,
      }),
    },
  },
  {
    id: 'workPhone',
    name: 'labels.workPhone',
    width: 'fill',
    component: {
      ref: PhoneCellComponent,
      inputs: (data): OnyxComponentInputs<PhoneCellComponent> => ({
        phone: data.item.driverData.workPhone,
      }),
    },
  },
  {
    id: 'privateEmail',
    name: 'labels.privateEmail',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.driverData.privateEmail,
      }),
    },
  },
  {
    id: 'nationality',
    name: 'labels.nationality',
    width: 'fill',
    component: {
      ref: CountryCellComponent,
      inputs: (data): OnyxComponentInputs<CountryCellComponent> => ({
        countryCode: data.item.driverData.nationality,
      }),
    },
  },
  {
    id: 'homeAddress',
    name: 'labels.homeAddress',
    width: 'fill',
    component: {
      ref: AddressCellComponent,
      inputs: (data): OnyxComponentInputs<AddressCellComponent> => ({
        address: data.item.driverData.homeAddress,
        format: 'short',
      }),
    },
  },
  {
    id: 'base',
    name: 'labels.base',
    width: 'fill',
    component: {
      ref: BaseCellComponent,
      inputs: (data): OnyxComponentInputs<BaseCellComponent> => ({
        base: data.item.base,
      }),
    },
  },
  {
    id: 'contract',
    name: 'labels.contract',
    width: 'fill',
    component: {
      ref: DriverContractCellComponent,
      inputs: (data): OnyxComponentInputs<DriverContractCellComponent> => ({
        employementConditions: {
          ...data.item.employmentConditions,
          netMileagePatePerKm: data.item.employmentConditions,
        } as any as Driver['employmentConditions'],
      }),
    },
  },
  {
    id: 'vatId',
    name: 'labels.vatId',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.employmentConditions.nipNumber,
      }),
    },
  },
  {
    id: 'companyName',
    name: 'labels.companyName',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.employmentConditions.companyName,
      }),
    },
  },
  {
    id: 'languages',
    name: 'labels.languages',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.skills.languages,
        dictionaryCode: DictionaryCode.LANGUAGE,
      }),
    },
  },
  {
    id: 'driverTechnicalSkills',
    name: 'labels.driverTechnicalSkills',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.skills.technicalSkills,
        dictionaryCode: DictionaryCode.DRIVER_TECHNICAL_SKILL,
      }),
    },
  },
  {
    id: 'semiTrailers',
    name: 'labels.semiTrailers',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.skills.semiTrailers,
        dictionaryCode: DictionaryCode.VEHICLE_TYPE,
      }),
    },
  },
  {
    id: 'driverExperienceLevel',
    name: 'labels.driverExperienceLevel',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.skills.experienceLevel,
        dictionaryCode: DictionaryCode.DRIVER_EXPERIENCE_LEVEL,
      }),
    },
  },
  {
    id: 'countriesWhitelist',
    name: 'forms.countriesWhitelist.heading',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.countries.whitelist,
        dictionaryCode: DictionaryCode.COUNTRY,
      }),
    },
  },
  {
    id: 'note',
    name: 'labels.note',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value: data.item.note,
      }),
    },
  },
  {
    id: 'driverLicense',
    name: 'labels.driverLicense',
    width: 'fill',
    component: {
      ref: DriverLicenseCellComponent,
      inputs: (data): OnyxComponentInputs<DriverLicenseCellComponent> => ({
        categories:
          data.item.driversLicenseAndProfessionalQualifications.categories,
      }),
    },
  },
  {
    id: 'professionalQualifications',
    name: 'labels.professionalQualifications',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.driversLicenseAndProfessionalQualifications
          .professionalQualificationsExpirationDate,
        showWarnings: true,
        prefix: 'labels.to',
      }),
    },
  },
  {
    id: 'driverLicenseNumber',
    name: 'labels.driversLicenseNumber',
    width: 'fill',
    component: {
      ref: StringCellComponent,
      inputs: (data): OnyxComponentInputs<StringCellComponent> => ({
        value:
          data.item.driversLicenseAndProfessionalQualifications
            .driversLicenseNumber,
      }),
    },
  },
  {
    id: 'driverCard',
    name: 'labels.driverCard',
    width: 'fill',
    component: {
      ref: DocumentCellComponent,
      inputs: (data): OnyxComponentInputs<DocumentCellComponent> => ({
        document: data.item.driverCard as DriverIdentityDocument | undefined,
      }),
    },
  },
  {
    id: 'passport',
    name: 'labels.passport',
    width: 'fill',
    component: {
      ref: DocumentCellComponent,
      inputs: (data): OnyxComponentInputs<DocumentCellComponent> => ({
        document: data.item.identityDocuments
          .passport as DriverIdentityDocument | null,
      }),
    },
  },
  {
    id: 'identityDocument',
    name: 'labels.identityDocument',
    width: 'fill',
    component: {
      ref: DocumentCellComponent,
      inputs: (data): OnyxComponentInputs<DocumentCellComponent> => ({
        document: data.item.identityDocuments
          .identityDocument as DriverIdentityDocument | null,
      }),
    },
  },
  {
    id: 'residenceCard',
    name: 'labels.residenceCard',
    width: 'fill',
    component: {
      ref: DocumentCellComponent,
      inputs: (data): OnyxComponentInputs<DocumentCellComponent> => ({
        document: data.item.identityDocuments
          .residenceCard as DriverIdentityDocument | null,
      }),
    },
  },
  {
    id: 'polishCard',
    name: 'labels.polishCard',
    width: 'fill',
    component: {
      ref: DocumentCellComponent,
      inputs: (data): OnyxComponentInputs<DocumentCellComponent> => ({
        document: data.item.identityDocuments
          .polishCard as DriverIdentityDocument | null,
      }),
    },
  },
  {
    id: 'clearCriminalRecordCertificate',
    name: 'labels.clearCriminalRecordCertificate',
    width: 'fill',
    component: {
      ref: DateCellComponent,
      inputs: (data): OnyxComponentInputs<DateCellComponent> => ({
        date: data.item.clearCriminalRecordCertificate.issuingDate,
        prefix: 'labels.issueDate',
      }),
    },
  },
];

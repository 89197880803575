<div class="container" [ngStyle]="{ 'gap.px': gap() }">
  <div
    class="box {{ color() }}"
    [ngClass]="{ background: hasColoredBackground(), shadow: hasShadow() }"
  >
    <ng-content select="onyx-icon"></ng-content>
    <ng-content select="[icon]"></ng-content>
  </div>

  <div class="content f-paragraph-medium-3">
    @if (label(); as label) {
      <p class="label">{{ label | translate }}</p>
    }

    <ng-content></ng-content>
  </div>
</div>
